import abcLogo from '../../assets/images/roadTransports/abc.svg';
import chiscoLogo from '../../assets/images/roadTransports/chisco.svg';
import gigLogo from '../../assets/images/roadTransports/gig.svg';
import guoLogo from '../../assets/images/roadTransports/guo.svg';
import libraLogo from '../../assets/images/roadTransports/libra.svg';

import threeSeater from '../../assets/images/bus/3-seater.svg';
import sixSeater from '../../assets/images/bus/6-seater.svg';
import fifteenSeater from '../../assets/images/bus/15-seater.svg';
import fiftyTwoSeater from '../../assets/images/bus/52-seater.png';

export const getRoadTransportLogo = (name: string) => {
  // TODO fix default
  // TODO confirm codes
  switch (name) {
    case 'ABC Transport':
      return abcLogo;
    case 'Chisco Transport':
      return chiscoLogo;
    case 'GIG Motors':
      return gigLogo;
    case 'GUO Transport':
      return guoLogo;
    case 'LIBRA Motors':
      return libraLogo;
    default:
      return abcLogo;
  }
};
export const getBusTypeImage = (seat: number) => {
  if (seat <= 3) {
    return threeSeater;
  } if (seat <= 6) {
    return sixSeater;
  } if (seat <= 15) {
    return fifteenSeater;
  }
  return fiftyTwoSeater;
};

export const calculateTotalFare = (passengers: PassengerFare[]) => {
  const totalAmount = passengers.reduce((acc, item) => (acc + item.TotalFare), 0);
  const totalCharges = passengers.reduce((acc, item) => (acc + item.PriceCalculationResult.TotalServiceCharge), 0);
  return { totalCharges, totalAmount, ticketAmount: (totalAmount - totalCharges) };
};
