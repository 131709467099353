export const getAirportOptions = (data: Airport[]) : SelectOption[] => data.map((airport) => ({
  label: airport.Name,
  value: airport.Code,
  tags: `${airport.StateCode} ${airport.StateName}`,
}));

export const generateFlightSearchInputs = (numberOfInputs: number) => {
  const flightFields = new Array(numberOfInputs).fill({
    OriginLocationCode: '',
    DestinationLocationCode: '',
    DepartureDate: '',
  });

  return flightFields.map(({
    OriginLocationCode,
    DestinationLocationCode,
    DepartureDate,
  }, key) => ({
    id: key,
    OriginLocationCode,
    DestinationLocationCode,
    DepartureDate,

  }));
};

export const generateMultiCityFlightRoutes = (routes) => routes.map(((route) => ({
  DepartureDate: route.DepartureDate.format('YYYY-MM-DD'),
  OriginLocationCode: route.OriginLocationCode,
  DestinationLocationCode: route.DestinationLocationCode,
})
));
