import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert, Button, Col, Row, Form, Modal, Spin,
} from 'antd';
import {
  FormWrapper, InfoWrapper,
  ItemBodyWrapper,
  ItemHeaderWrapper,
  ItemWrapper,
  PriceModalContentWrapper,
} from './styles';
import PassengerForm from '../../components/PassengerForm';
import {
  SpaceBetweenAlignContent,
  VerticalAlignContent,
} from '../../components/CustomAntdComponents/Utitlities';
import {
  StyledParagraph, StyledText,
  StyledTitle,
} from '../../components/CustomAntdComponents/Typography';
import FlightItinerary from '../../components/FlightItinerary';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import arrowDown from '../../assets/images/arrow-down.svg';
import { buildPassengersPayload, generateCategoryInputs } from './helper';
import { postAPI } from '../../utils/api';
import {
  // BOOK_DOMESTIC_TICKET_URL,
  // BOOK_TICKET_URL,
  CONFIRM_DOMESTIC_TICKET_URL,
  CONFIRM_TICKET_URL,
} from '../../utils/constants';
import {
  formatNumberAsCurrency,
  isMobileScreen,
} from '../../utils/helpers';
import {
  StyledButton,
} from '../../components/CustomAntdComponents/StyledButton';
import ModalContent from '../../components/ModalContent';
import { ReactComponent as PayIcon } from '../../assets/images/pay.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';
import StyledDrawer from '../../components/CustomAntdComponents/StyledDrawer';
import { useStore } from '../../store/global/appStore';
import AlertMessage from '../../components/AlertMessage';
import { flightTypes } from '../Home/constants';
import { useBookingStore } from '../../store/booking/bookingStore';
import { useI18nContext } from '../../utils/I18n';
import translations from './translations';

function FlightPassengers() {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData: {
      numberOfAdults, numberOfChildren, numberOfInfants, flightType,
    },
    selectedFlight,
  } = useFlightSearchStore();
  const navigate = useNavigate();

  const { requesting, setRequesting } = useStore();
  const { setReservation } = useBookingStore();

  const isDomestic = flightType === flightTypes[1].value;
  // Create form fields based off how many items are in the order
  const childrenCount = numberOfChildren || 0;
  const infantsCount = numberOfInfants || 0;
  const adultInputs = generateCategoryInputs(numberOfAdults);
  const childrenInputs = generateCategoryInputs(childrenCount);
  const infantInputs = generateCategoryInputs(infantsCount);

  const totalPassengers = numberOfAdults + (numberOfChildren || 0) + (numberOfInfants || 0);

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [passengerKey, setPassengerKey] = useState(1);
  // const [setBookingPayload] = useState<any>({});
  const [priceUpdatedModal, setPriceUpdateModal] = useState(false);
  const [priceUpdatedDialog, setPriceUpdateDialog] = useState(false);
  const [ticketReservation, setTicketReservation] = useState<ConfirmTicketResponse | null>(null);
  const onNextPassengerChange = () => {
    let nextKey = Number(passengerKey) + 1;
    nextKey = nextKey > totalPassengers ? 1 : nextKey;
    setPassengerKey(nextKey);
  };

  const renderHeaderContent = (category: string, passengerIndex: number, categoryIndex: number) => (
    <ItemHeaderWrapper onClick={() => setPassengerKey(passengerIndex)}>
      {
        passengerKey === passengerIndex ? (
          <StyledTitle level={5}>
            {`${t(translations).passenger} ${passengerIndex} (${category} ${categoryIndex})`}
          </StyledTitle>
        ) : (
          <SpaceBetweenAlignContent>
            <StyledParagraph size="medium">
              {`${t(translations).passenger} ${passengerIndex} (${category} ${categoryIndex})`}
            </StyledParagraph>
            <img src={arrowDown} alt="down" />
          </SpaceBetweenAlignContent>
        )
      }
    </ItemHeaderWrapper>
  );

  const buildBookingPayload = (data: any) => {
    if (data.length === undefined) {
      return data;
    }
    const [departure, arrival] = data;

    return { departure, arrival };
  };

  // This is commented because Booking is expected to be done on the App
  // const handleBookTicket = async (payload?: any) => {
  //   setErrorMessage(null);
  //   setRequesting(true);
  //
  //   const bookingURL = isDomestic ? BOOK_DOMESTIC_TICKET_URL : BOOK_TICKET_URL;
  //
  //   const bookTicket = await postAPI(bookingURL, payload || bookingPayload);
  //   setRequesting(false);
  //
  //   if (bookTicket.status === 200) {
  //     setReservation(buildBookingPayload(bookTicket.data), 'bookingPayload');
  //     navigate('/payment/flight');
  //   } else {
  //     setErrorMessage(bookTicket.data);
  //     window.scrollTo(0, 0);
  //   }
  // };

  const handleBookTicket = async (payload?: any) => {
    const bookingDetails = buildBookingPayload(payload || ticketReservation);
    let bookingData;

    if (isDomestic) {
      const { departure, arrival } = bookingDetails;

      const TicketingDetails: any = [
        {
          Price: departure.NewPrice,
          ConfirmationCode: departure.ConfirmationCode,
        },
      ];

      if (arrival) {
        TicketingDetails.push({
          Price: arrival.NewPrice,
          ConfirmationCode: arrival.ConfirmationCode,
        });
      }

      bookingData = {
        TripsType: flightType,
        TripsMode: 'flight',
        Hash: departure.Hash,
        PassengerDetails: departure.PassengerDetails,
        TicketingDetails,
      };
    } else {
      const {
        PassengerDetails, NewPrice, ConfirmationCode, Hash,
      } = bookingDetails;
      bookingData = {
        TripsType: flightType,
        TripsMode: 'flight',
        Hash,
        PassengerDetails,
        TicketingDetails: [
          {
            Price: NewPrice,
            ConfirmationCode,
          },
        ],
      };
    }

    setReservation(bookingData, 'bookingPayload');
    navigate('/payment/flight');
  };

  const handleConfirmTicket = async (payload: any) => {
    setErrorMessage(null);
    setRequesting(true);

    const confirmURL = isDomestic ? CONFIRM_DOMESTIC_TICKET_URL : CONFIRM_TICKET_URL;
    const confirmTicket = await postAPI(confirmURL, payload);
    setRequesting(false);

    if (confirmTicket.status === 200) {
      setTicketReservation(confirmTicket.data);
      if (confirmTicket.data.OldPrice === confirmTicket.data.NewPrice) {
        await handleBookTicket(confirmTicket.data);
      } else if (isMobileScreen()) {
        setRequesting(false);
        setPriceUpdateDialog(true);
      } else {
        setRequesting(false);
        setPriceUpdateModal(true);
      }
    } else {
      setErrorMessage(confirmTicket.data);
      window.scrollTo(0, 0);
    }
  };

  const handleOnFinish = (values: any) => {
    if (selectedFlight) {
      const payload = buildPassengersPayload(values, selectedFlight, isDomestic);
      handleConfirmTicket(payload);
    }
  };

  const PriceUpdateButtons = (
    <SpaceBetweenAlignContent>
      <Link to="/search" style={{ width: '100%' }}>
        <StyledButton mode="secondary" block>
          {t(translations).cancel}
        </StyledButton>
      </Link>

      <StyledButton
        type="primary"
        block
        onClick={() => {
          setPriceUpdateDialog(false);
          setPriceUpdateModal(false);
          handleBookTicket();
        }}
      >
        {t(translations).proceed}
      </StyledButton>
    </SpaceBetweenAlignContent>
  );

  const PriceUpdatedContent = ticketReservation ? (
    <PriceModalContentWrapper>
      <StyledParagraph align="center" size="middle">
        {t(translations).priceUpdate}
&nbsp;
        <StyledText color="primary">
          {`${formatNumberAsCurrency(ticketReservation.OldPrice || 0, 'NGN')}`}
        </StyledText>
        {' '}
        {t(translations).to}
        &nbsp;
        <StyledText color="primary">
          {`${formatNumberAsCurrency(ticketReservation.NewPrice || 0, 'NGN')}`}
        </StyledText>
      </StyledParagraph>
    </PriceModalContentWrapper>
  ) : <div>&nbsp;</div>;

  return (
    <>
      {
        errorMessage && <AlertMessage message={errorMessage} />
      }

      <Spin spinning={requesting}>
        <Form onFinish={handleOnFinish}>
          <Row gutter={[0, 16]}>
            <Col xs={0} lg={24}>
              <Row gutter={32}>
                <Col xs={0} lg={16}>
                  <VerticalAlignContent>
                    <StyledTitle level={3}>
                      {t(translations).passengers}
&nbsp;&nbsp;
                    </StyledTitle>
                    <InfoWrapper>
                      <InfoIcon />
                      {' '}
&nbsp;
                      <span>
                        {t(translations).provideName}
                      </span>
                    </InfoWrapper>
                  </VerticalAlignContent>
                </Col>
                <Col xs={0} lg={8}>
                  <StyledTitle level={3}>
                    {t(translations).itineraryTitle}
                  </StyledTitle>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[32, 16]}>
                <Col xs={24} lg={0}>
                  <Alert message={t(translations).provideName} showIcon />
                </Col>

                <Col xs={24} lg={16}>
                  <Row>
                    <Col xs={24} lg={24}>
                      <FormWrapper>
                        <Form.List name="adults" initialValue={adultInputs}>
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <ItemWrapper active={passengerKey === field.key + 1} key={field.key}>
                                  {renderHeaderContent('Adult', field.key + 1, field.key + 1)}
                                  <ItemBodyWrapper>
                                    <PassengerForm
                                      showNextTrigger={field.key + 1 < totalPassengers}
                                      field={field}
                                      onNextPassenger={onNextPassengerChange}
                                      passengerType="ADT"
                                    />
                                  </ItemBodyWrapper>
                                </ItemWrapper>
                              ))}
                            </>
                          )}
                        </Form.List>

                        {/* Children */}
                        <Form.List name="children" initialValue={childrenInputs}>
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <ItemWrapper active={passengerKey === numberOfAdults + field.key + 1} key={field.key}>
                                  {renderHeaderContent('Child', (numberOfAdults + field.key + 1), field.key + 1)}

                                  <ItemBodyWrapper>
                                    <PassengerForm
                                      showNextTrigger={(field.key + 1 + numberOfAdults) < totalPassengers}
                                      field={field}
                                      passengerType="CHD"
                                      onNextPassenger={onNextPassengerChange}
                                    />
                                  </ItemBodyWrapper>
                                </ItemWrapper>
                              ))}
                            </>
                          )}
                        </Form.List>

                        {/* Infant */}
                        <Form.List name="infants" initialValue={infantInputs}>
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <ItemWrapper active={passengerKey === (numberOfAdults + infantsCount + field.key + 1)} key={field.key}>
                                  {renderHeaderContent('Infant', (numberOfAdults + infantsCount + field.key + 1), field.key + 1)}
                                  <ItemBodyWrapper>
                                    <PassengerForm
                                      showNextTrigger={(field.key + 1 + childrenCount + numberOfAdults) < totalPassengers}
                                      field={field}
                                      passengerType="INF"
                                      onNextPassenger={onNextPassengerChange}
                                    />
                                  </ItemBodyWrapper>
                                </ItemWrapper>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </FormWrapper>
                    </Col>

                    <Col xs={24} lg={0}>
                      <Button type="primary" size="large" block htmlType="submit">
                        {t(translations).submit}
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col xs={0} lg={8}>
                  <FlightItinerary />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Modal
        centered
        closable={false}
        maskClosable={false}
        visible={priceUpdatedModal}
        footer={null}
        onCancel={() => setPriceUpdateModal(false)}
        width={400}
        getContainer={document.getElementById('app-container') as HTMLElement}
      >
        <ModalContent Icon={<PayIcon />} title={t(translations).priceUpdateTitle}>
          {ticketReservation && (
            <>
              {PriceUpdatedContent}
              {PriceUpdateButtons}
            </>
          )}
        </ModalContent>

      </Modal>

      <StyledDrawer
        visibility={priceUpdatedDialog}
        onClose={() => setPriceUpdateDialog(false)}
        footer={PriceUpdateButtons}
      >
        <ModalContent bordered Icon={<PayIcon />} title={t(translations).priceUpdateTitle}>
          {PriceUpdatedContent}
        </ModalContent>
      </StyledDrawer>
    </>
  );
}

export default FlightPassengers;
