import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  cursor: pointer;
  display: block;
  margin-left: 15px;
`;

export const LinkText = styled.strong`
  cursor: pointer;
  display: block;
`;
