import React from 'react';
import {
  Row, Col, Form,
} from 'antd';
import StyledSelect from '../../CustomAntdComponents/StyledSelect';
import StyledDatePicker from '../../CustomAntdComponents/StyledDatePicker';
import translations from '../../../screens/Search/translations';
import { useI18nContext } from '../../../utils/I18n';
import { getDisabledDateRange } from '../../../utils/helpers';

type Props = {

  field: any,
  airportOptions,
  validateDestination
}
export function FlighSearchForm({
  airportOptions, field,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const [form] = Form.useForm();

  // const validateDestination = (path: any, value: any, c) => {
  //   console.log({ path, field });
  //   const index = path.field.split('.')[1];
  //   console.log('Index', index);

  //   if (!value) {
  //     return Promise.reject(new Error(t(translations).validations.destination));
  //   }

  //   // if (formData.OriginLocationCode === value) {
  //   //   return Promise.reject(new Error(t(translations).validations.invalidDestination));
  //   // }

  //   return Promise.resolve();
  // };

  const validateDestination = (rule:any, value, callback) => {
    // Assuming you have access to the 'form' object.
    // Get the values of other fields using 'getFieldsValue'.
    const formValues = form.getFieldsValue();
    console.log({ formValues });

    // Assuming you have another field named 'OriginLocationCode'.
    const originLocationCodeValue = formValues.OriginLocationCode;

    if (!value) {
      callback(t(translations).validations.destination);
    } else if (value === originLocationCodeValue) {
      callback(t(translations).validations.invalidDestination);
    } else {
      callback();
    }
  };

  return (

    <Row gutter={8}>
      <Col xs={24}>
        <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...field}
          name={[field.name, 'OriginLocationCode']}
          fieldKey={[field.key, 'OriginLocationCode']}
          key={`OriginLocationCode${field.key}`}
          rules={[{ required: true, message: t(translations).validations.departure }]}
        >
          <StyledSelect options={airportOptions} placeholder={t(translations).inputs.from} showSearch />
        </Form.Item>

        <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...field}
          name={[field.name, 'DestinationLocationCode']}
          fieldKey={[field.key, 'DestinationLocationCode']}
          dependencies={[field.name, 'OriginLocationCode']}
          // rules={[{ required: true, message: t(translations).validations.destination }]}
          key={`DestinationLocationCode${field.key}`}
          // rules={[{ validator: validateDestination }]}
          rules={[
            // {
            //   required: true,
            //   // message: 'Please confirm your password!',
            // },
            ({ getFieldsValue }) => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error(t(translations).validations.destination));
                }
                const { items } = getFieldsValue(true);
                const item = items[field.name];
                if (item.OriginLocationCode === value) {
                  return Promise.reject(new Error(t(translations).validations.invalidDestination));
                }
                // return Promise.reject(new Error(t(translations).validations.destination));
                return Promise.resolve();
                // return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
        >
          <StyledSelect
            placeholder={t(translations).inputs.to}
            options={airportOptions}
            showSearch
          />
        </Form.Item>

        <Form.Item
                /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...field}
          name={[field.name, 'DepartureDate']}
          fieldKey={[field.key, 'DepartureDate']}
          rules={[{ required: true, message: t(translations).validations.departureDate }]}
          key={`DepartureDate${field.key}`}

        >
          <StyledDatePicker placeholder={t(translations).inputs.departureDate} disabledDate={getDisabledDateRange} />
        </Form.Item>

        {/* <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item> */}
      </Col>
    </Row>

  );
}
