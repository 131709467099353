import axios from 'axios';
import { BASE_API_URL } from './constants';

type APIErrorResponse = {
  Errors?: string[]
}

const getErrors = (data: any) => {
  if (data.length === undefined) {
    return data;
  }

  if (typeof data.errors === 'string' || typeof data === 'string') {
    return data;
  }
  const errors = data && data?.map((error: any) => (error.Errors.join(', ')));
  return errors.join(', ');
};

const processAPIError = (e: any) => {
  let errorMessage: any = '';
  let errorStatus = 500;

  if (axios.isAxiosError(e) && e.response && e.response.data) {
    if (e.response) {
      // Response received from server
      errorStatus = e.response.status;
      const apiRe = e.response.data as APIErrorResponse;
      if (errorStatus === 500) {
        errorMessage = 'Sorry, we are unable to complete the request. Please try again';
      } else {
        errorMessage = apiRe.Errors ? apiRe.Errors.join(', ') : getErrors(e.response.data);
      }
    } else if (e.request) {
      // No response received
      errorMessage = 'No response from server';
    } else {
      // Error in request setup
      errorMessage = 'Unable to process request';
    }
  }
  // App Error in request
  return { status: errorStatus, data: errorMessage };
};

const getRequestData = () => {
  const auth = JSON.parse(localStorage.getItem('trips_auth') || '{}');

  const Authorization = `Bearer ${auth.Token}`;
  return {
    baseUrl: BASE_API_URL, Authorization, MerchantCode: auth.MerchantCode, Hash: auth.Hash,
  };
};

export const getAPI = async (url: string) => {
  const {
    baseUrl, Authorization, MerchantCode, Hash,
  } = getRequestData();
  const resultingUrl = `${baseUrl}${url}`;

  try {
    const res = await axios.get(resultingUrl, {
      headers: {
        MerchantCode,
        MerchantHash: Hash,
        Authorization,
      },
    });

    return { status: 200, data: res.data };
  } catch (e: any) {
    return processAPIError(e);
  }
};

export const postAPI = async (url: string, payload: any) => {
  const {
    baseUrl, Authorization, MerchantCode, Hash,
  } = getRequestData();
  const resultingUrl = `${baseUrl}${url}`;

  try {
    const res = await axios.post(resultingUrl, payload, {
      headers: {
        MerchantCode,
        MerchantHash: Hash,
        Authorization,
      },
    });

    return { status: 200, data: res.data };
  } catch (e: any) {
    return processAPIError(e);
  }
};

export const getAPICustom = async (url: string, payload: any, header: any) => {
  const resultingUrl = `${BASE_API_URL}${url}`;

  try {
    const res = await axios.get(resultingUrl, {
      headers: header,
    });

    return { status: 200, data: res.data };
  } catch (e: any) {
    return processAPIError(e);
  }
};
