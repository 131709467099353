export default {
  en: {
    searchButton: 'Search Trip',
    addNewButton: 'Add New Trip',
    oneWay: 'One Way',
    roundTrip: 'Round Trip',
    multiCity: 'Multi City',
    economy: 'Economy',
    firstClass: 'First Class',
    businessClass: 'Business',
    inputs: {
      from: 'From',
      to: 'To',
      departureDate: 'Departure Date',
      arrivalDate: 'Return Date',
      sameAirlineText: 'Return with same airline',
    },
    validations: {
      departure: 'Please select departure',
      destination: 'Please select destination',
      departureDate: 'Please select departure date',
      destinationDate: 'Please select return date',
      invalidDestinationDate: 'Return date is before departure date',
      invalidDestination: 'Destination should be different from departure',
    },
  },
  fr: {
    searchButton: 'Rechercher un voyage',
    addNewButton: 'Ajouter un nouveau voyage',
    oneWay: 'Aller simple',
    roundTrip: 'Aller-retour',
    multiCity: 'Multi City',
    economy: 'Économie',
    firstClass: 'Première classe',
    businessClass: 'Affaires',
    inputs: {
      from: 'De',
      to: 'À',
      departureDate: 'Date de départ',
      arrivalDate: 'Date d\'arrivée',
      sameAirlineText: 'Retour avec la même compagnie aérienne',
    },
    validations: {
      departure: 'Veuillez sélectionner le départ',
      destination: 'Veuillez choisir la destination',
      departureDate: 'Veuillez choisir la date de départ',
      destinationDate: 'Veuillez sélectionner la date de retour',
      invalidDestinationDate: 'La date de retour est antérieure à la date de départ',
      invalidDestination: 'La destination doit être différente du départ',
    },
  },
  sp: {
    searchButton: 'Búsqueda del viaje',
    addNewButton: 'Agregar nuevo viaje',
    oneWay: 'Sólo ida',
    roundTrip: 'Viaje de ida y vuelta',
    multiCity: 'multi ciudad',
    economy: 'Economía',
    firstClass: 'Primera clase',
    businessClass: 'Negocios',
    inputs: {
      from: 'Desde',
      to: 'Para',
      departureDate: 'Fecha de salida',
      arrivalDate: 'Fecha de llegada',
      sameAirlineText: 'Regreso con la misma aerolínea',
    },
    validations: {
      departure: 'Por favor, seleccione la salida',
      destination: 'Seleccione el destino',
      departureDate: 'Seleccione la fecha de salida',
      destinationDate: 'Seleccione la fecha de regreso',
      invalidDestinationDate: 'La fecha de regreso es anterior a la fecha de salida',
      invalidDestination: 'El destino debe ser diferente de la salida',
    },

  },
  sw: {
    searchButton: 'Tafuta Safari',
    addNewButton: 'Ongeza Safari Mpya',
    oneWay: 'Njia moja',
    roundTrip: 'Safari ya Kurudi',
    multiCity: 'Mji wa Multi',
    economy: 'Uchumi',
    firstClass: 'Darasa la Kwanza',
    businessClass: 'Biashara',
    inputs: {
      from: 'Kutoka',
      to: 'Kwa',
      departureDate: 'Tarehe ya kuondoka',
      arrivalDate: 'Tarehe ya Kuwasili',
      sameAirlineText: 'Rudi na shirika moja la ndege',
    },
    validations: {
      departure: 'Tafadhali chagua kuondoka',
      destination: 'Tafadhali chagua unakoenda',
      departureDate: 'Tafadhali chagua tarehe ya kuondoka',
      destinationDate: 'Tafadhali chagua tarehe ya kurudi',
      invalidDestinationDate: 'Tarehe ya kurudi ni kabla ya tarehe ya kuondoka',
      invalidDestination: 'Unakoenda kunapaswa kuwa tofauti na kuondoka',
    },
  },
};
