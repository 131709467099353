import styled, { css } from 'styled-components';
import { FONT_SIZE, GREY_10, PRIMARY } from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const HeaderDivider = styled.div`
  border: 1px solid #EEEEEE;
  margin-bottom: 25px;
`;

export const Wrapper = styled.div`
  margin-top: 15px;

  ${device.large} {
    margin-top: 0;
  }
  .ant-select {
    width: 100%;
  }
`;

export const AirlineCheckboxWrapper = styled.div`
  label.ant-checkbox-wrapper {
    color: #92949E;
  }
`;

export const PillItem = styled.span<{ active?: boolean }>`
  display: inline-block;
  padding: 5px 30px;
  border: 1px solid #EBE9FF;
  margin-left: 5px;
  margin-top: 20px;
  color: #92949E;
  border-radius: 5px;
  font-size: calc(10px *  ${FONT_SIZE});
  cursor: pointer;
  
  ${({ active }) => active && css`
    background: ${PRIMARY};
    color: #fff;
  `};
`;

export const RangeSelectorWrapper = styled.div`
  display: none;
  .ant-slider-mark {
    .ant-slider-mark-text {
      font-weight: bold;
      font-size: calc(10px *  ${FONT_SIZE});
      line-height: 12px;
      color: #92949E;
      
      transform: translateX(0) !important;
      
      &:last-of-type {
        transform: translateX(-100%) !important;
      }
    }
  }
  
  .ant-slider-handle {
    border: solid 2px ${PRIMARY};
  }
  
  .ant-slider-track {
    background: ${PRIMARY};
  }
`;

export const PillWrapper = styled.div`
  column-gap: 10px;
  margin: 5px 0 0;
  background: #fff;
  padding: 0;
  border-radius: 5px;
  display: inline-flex;
`;

export const PillItemWrapper = styled.div<{ disabled?: boolean, active?: boolean}>`
  border-radius: 5px;
  text-align: center;
  transition: all 0.2s ease-in;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 15px 20px;
  flex: 1;
  background: #fff;
  border: solid 1px ${GREY_10};
  

  ${({ active }) => active && css`
    background: ${PRIMARY};
    color: #fff;
  `}
`;
