const translations = {
  en: {
    title: 'How would you like to Travel?',
    salutation: 'Hello',
    tripFlight: 'Flight',
    tripRoad: 'Road',
    tripWater: 'Water',
    tripRail: 'Rail',
    tripType: 'Trip type',
    internationalTrip: 'International',
    domesticTrip: 'Domestic',
  },
  fr: {
    title: 'Comment aimeriez-vous voyager ?',
    salutation: 'Bonjour',
    tripFlight: 'Vol',
    tripRoad: 'Route',
    tripWater: 'Eau',
    tripRail: 'Rail',
    tripType: 'Type de voyage',
    internationalTrip: 'International',
    domesticTrip: 'National',
  },
  sp: {
    title: 'Cómo le gustaría viajar?',
    salutation: 'Hola',
    tripFlight: 'Vuelo',
    tripRoad: 'Carretera',
    tripWater: 'Agua',
    tripRail: 'Ferrocarril',
    tripType: 'Tipo de viaje',
    internationalTrip: 'Internacional',
    domesticTrip: 'Doméstico',
  },
  sw: {
    title: 'Je, ungependa kusafiri vipi?',
    salutation: 'Habari',
    tripFlight: 'Ndege',
    tripRoad: 'Barabara',
    tripWater: 'Maji',
    tripRail: 'Reli',
    tripType: 'Aina ya safari',
    internationalTrip: 'Kimataifa',
    domesticTrip: 'Ndani',
  },
};

export default translations;
