import React, { useEffect, useState } from 'react';
import {
  Row, Col, Typography,
} from 'antd';
import { HeaderDivider, Wrapper } from './styles';
import AirlinesCheckbox from './AirlinesCheckbox';
import PriceRange from './PriceRange';
import {
  FormWrapper,
  TitleWrapper,
} from '../../screens/SearchListings/styles';
import StopOvers from './StopOvers';
import { StyledHR } from '../CustomAntdComponents/Utitlities';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import { priceOptions, stopOverOptions } from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';

const { Paragraph } = Typography;

type Props = {
  isDomestic: boolean;
  handleAirlineFiltering?: (arg0: boolean, arg: string, arg3?: boolean) => void,
  handleStopoverFiltering: (arg0: number | null) => void,
  handleSortByPrice: (arg0: string | null) => void,
  domesticFlights?: FlightAggregateResultItem,
  internationalFlights?: InternationalFlightResultItem
}

function ListingFilter({
  domesticFlights, internationalFlights, handleAirlineFiltering,
  isDomestic, handleStopoverFiltering, handleSortByPrice,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const [airlines, setAirlines] = useState<Airline[]>([]);

  useEffect(() => {
    let uniqueAirlines: Airline[] = [];
    if (!isDomestic && internationalFlights) {
      const al: Airline[] = internationalFlights.ResultList.map((d) => {
        const firstHit = d.FlightDetails[0];
        return {
          code: firstHit.OperatingAirlineCode,
          name: firstHit.OperatingAirlineName,
        };
      });
      uniqueAirlines = al.filter((a, i) => al.findIndex((s) => a.code === s.code) === i);
    }
    if (isDomestic && domesticFlights) {
      const al: Airline[] = domesticFlights.ResultList.map((d) => {
        const firstHit = d.ResultList[0].FlightDetails[0];
        return {
          code: firstHit.OperatingAirlineCode,
          name: firstHit.OperatingAirlineName,
        };
      });
      uniqueAirlines = al.filter((a, i) => al.findIndex((s) => a.code === s.code) === i);
    }

    setAirlines([...uniqueAirlines]);
  }, [internationalFlights, domesticFlights, isDomestic]);

  const handleAirlineSelect = (value: string) => {
    if (handleAirlineFiltering) { handleAirlineFiltering(Boolean(value), value, true); }
  };

  const handlePriceSelect = (value: string) => {
    handleSortByPrice(value);
  };

  const airlineOptions = airlines.map((airline) => ({
    label: airline.name,
    value: airline.code,
  }));

  return (
    <Wrapper>

      <TitleWrapper>
        <Paragraph strong>
          {t(translations).filterTitle}
        </Paragraph>
      </TitleWrapper>

      <Row>
        <Col xs={0} lg={24}>
          <HeaderDivider />
        </Col>
      </Row>

      <FormWrapper>
        <Row>
          <Col xs={24} lg={0}>
            <Row gutter={16}>
              <Col span={isDomestic ? 12 : 8}>
                <StyledSelect size="large" placeholder={t(translations).price} onChange={handlePriceSelect} options={priceOptions} />
              </Col>
              <Col span={isDomestic ? 12 : 8}>
                <StyledSelect
                  size="large"
                  placeholder={t(translations).airline}
                  onChange={handleAirlineSelect}
                  options={[{ label: t(translations).allAirlines, value: 'all' }, ...airlineOptions]}
                />
              </Col>
              <Col span={8}>
                {
                  !isDomestic && (
                  <StyledSelect
                    size="large"
                    placeholder={t(translations).stops}
                    onChange={handleStopoverFiltering}
                    options={stopOverOptions}
                  />
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </FormWrapper>

      <Row>
        <Col xs={0} lg={24}>

          <PriceRange handleSortByPrice={handleSortByPrice} />

          <StyledHR size="large" />
          {!isDomestic && (
          <>
            <StopOvers handleStopoverFiltering={handleStopoverFiltering} />
            <StyledHR size="large" />
          </>
          )}

          <AirlinesCheckbox
            handleAirlineFiltering={handleAirlineFiltering}
            airlines={airlines}
          />
        </Col>
      </Row>

    </Wrapper>

  );
}

ListingFilter.defaultProps = {
  domesticFlights: null,
  internationalFlights: null,
  handleAirlineFiltering: undefined,
};
export default ListingFilter;
