import styled, { css } from 'styled-components';
import {
  GREY_10, GREY_5, PRIMARY,
} from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const Wrapper = styled.div`
  padding: 0 20px 20px;
`;

export const ItemHeaderWrapper = styled.div`
  background: ${GREY_5};
  border: 1px solid ${PRIMARY};
  padding: 18px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const ItemBodyWrapper = styled.div`
  padding-top: 7px;
  overflow: hidden;
  height: 0;
  //max-height: 0;
  transition: all 0.2s ease-in-out;
`;

export const ItemWrapper = styled.div<{ active: boolean }>`
  ${({ active }) => active && css`
    padding-bottom: 20px;
    
    ${ItemHeaderWrapper} {
      background: none;
      border: none;
      margin-bottom: 0;
      padding-left: 0;
      cursor: auto;
    }
    
    ${ItemBodyWrapper} {
      height: auto;
      //max-height: 350px;
    }
  `}
`;

export const FormWrapper = styled.div`
  @media ${device.large} {
    background: #fff;
    padding: 30px;
    border-radius: 5px;


    .as-label {
      background: #fff !important;
    }
    
    .ant-picker,
    .ant-select-selector,
    .ant-input {
      background: ${GREY_10} !important;
    }
  }
`;

export const PriceModalContentWrapper = styled.div`
  width: 80%;
  margin: 0 auto 30px;
`;

export const InfoWrapper = styled.div`
  background: ${GREY_5};
  padding: 8px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;
