export const getTerminalName = (data: RoadRoute[], id?: number): string => {
  if (id === undefined) {
    return '';
  }

  const terminal = data.find((r) => r.TerminalId === id);
  return terminal ? terminal.TerminalName : String(id);
};

export const getAirportStateName = (data: Airport[], code?: string): string => {
  if (code === undefined) {
    return '';
  }

  const airport = data.find((r) => r.Code === code);
  return airport ? airport.StateName : code;
};
