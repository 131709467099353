/* eslint-disable no-tabs */
import React from 'react';
import {
  Button, Form, Row, Col, Checkbox,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  FlexColumnItem,
} from '../styles';
import PassengerCountSelector from '../../PassegerSelector';
import { generateFlightSearchInputs } from '../helper';
import translations from '../../../screens/Search/translations';
import { useI18nContext } from '../../../utils/I18n';
// import { TextWrapper } from '../../AvailableFlights/styles';
import { StyledParagraph } from '../../CustomAntdComponents/Typography';
import { FlighSearchForm } from './MulticityFlightSearchForm';
import { useFlightSearchStore } from '../../../store/flightSearch/flightSearchStore';

export function MulticityFlightSearchForm(props) {
  const {
    airportOptions,
    validateDestination,
    MultiCityMaxRoute,
  } = props;

  const {
    formData,
  } = useFlightSearchStore();
  const {
    state: { t },
  } = useI18nContext();

  const initialValue = formData?.multiCity?.length > 0
    ? formData?.multiCity?.map((item) => ({ ...item, DepartureDate: moment(item.DepartureDate) })) : generateFlightSearchInputs(2);

  return (
    <Col
      xs={0}
      lg={11}
      style={{ display: 'flex' }}
    >
      <FlexColumnItem>
        <Row gutter={24}>
          <Form.List name="items" initialValue={initialValue}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (

                  <Col xs={0} lg={24} key={`itemWrapper${field.key}`}>
                    {/* active={activeIndex === field.key + 1} key={field.key */}
                    {/* {renderHeaderContent('Adult', field.key + 1, field.key + 1)} */}
                    <Row justify="space-between" style={{ marginBottom: '12px' }}>
                      <Col>
                        <StyledParagraph size="medium">
                          {`Trip ${field.name + 1}`}
                        </StyledParagraph>
                      </Col>
                      {field.name > 1 && (
                        <Col>
                          {' '}
                          <CloseOutlined onClick={() => remove(field.name)} key={`outline${field.key}`} />
                        </Col>
                      ) }

                    </Row>

                    <FlighSearchForm
                      airportOptions={airportOptions}
                      validateDestination={validateDestination}
                      field={field}
                      key={field.key}
                    />

                  </Col>

                ))}

                <Col
                  xs={0}
                  lg={24}
                  style={{ marginTop: '20px' }}
                >
                  <Form.Item>
                    <Button
                      style={{ marginBottom: '15px' }}
                      onClick={() => add()}
                      type="primary"
                      htmlType="button"
                      size="large"
                      disabled={fields.length >= MultiCityMaxRoute}
                      ghost
                      block
                    >
                      +
                      {' '}
                      {t(translations).addNewButton}
                    </Button>

                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
        </Row>

      </FlexColumnItem>
    </Col>
  );
}

export function MulticityFlightSearchFormMobile(props) {
  const {
    airportOptions,
    isDomestic,
    sameAirline,
    isRoundTrip,
    onFormInputChange,
    validateDestination,
    MultiCityMaxRoute,
  } = props;
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData,
  } = useFlightSearchStore();
  // const handleAddNewTrip = () => '';
  // const [activeIndex, setActiveIndex] = useState(1);

  const initialValue = formData?.multiCity?.length > 0
    ? formData?.multiCity?.map((item) => ({ ...item, DepartureDate: moment(item.DepartureDate) })) : generateFlightSearchInputs(2);
  return (
    <Row gutter={[0, 16]}>
      <Form.List name="items" initialValue={initialValue}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Col xs={24} lg={0} key={`itemWrapper${field.key}`}>
                {/* active={activeIndex === field.key + 1} key={field.key */}
                {/* {renderHeaderContent('Adult', field.key + 1, field.key + 1)} */}
                <Row justify="space-between" key={field.key} style={{ marginBottom: '12px' }}>
                  <Col key={field.key}>
                    <StyledParagraph size="medium" key={`para${field.key}`}>
                      {`Trip ${field.name + 1}`}
                    </StyledParagraph>
                  </Col>
                  {field.name > 1 && (
                  <Col>
                    {' '}
                    <CloseOutlined onClick={() => remove(field.name)} key={`outline${field.key}`} />
                  </Col>
                  ) }
                </Row>

                <FlighSearchForm
                  airportOptions={airportOptions}
                  validateDestination={validateDestination}
                  field={field}
                />

              </Col>
            ))}

            {/* passenger count start */}
            <Col
              xs={24}
              lg={0}
            >

              <PassengerCountSelector />

              {isRoundTrip && isDomestic && (
              <Checkbox
                key="airline"
                checked={Boolean(sameAirline)}
                onChange={(e) => onFormInputChange(e.target.checked, 'sameAirline')}
              >
                {t(translations).inputs.sameAirlineText}
              </Checkbox>
              )}
            </Col>

            <Col
              xs={24}
              lg={0}
              style={{ marginTop: '20px' }}
            >
              <Form.Item>
                <Button
                  style={{ marginBottom: '15px' }}
                  onClick={() => add()}
                  type="primary"
                  htmlType="button"
                  size="large"
                  disabled={fields.length >= MultiCityMaxRoute}
                  ghost
                  block
                >
                  +
                  {' '}
                  {t(translations).addNewButton}
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  {t(translations).searchButton}
                </Button>
              </Form.Item>
            </Col>
          </>
        )}
      </Form.List>
    </Row>
  );
}
