import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import {
  ContentWrapper, IndicatorContent,
  IndicatorWrapper, PadContentWrapper,
  PadWrapper, SeatWrapper,
  Wrapper,
} from './styles';
import { StyledParagraph } from '../CustomAntdComponents/Typography';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import { groupBy, pluralizeText } from '../../utils/helpers';
import steering from '../../assets/images/steering.svg';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';

function SeatSelection() {
  const {
    state: { t },
  } = useI18nContext();
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = useState(false);
  const [busSize, setBusSize] = useState('');
  const [column, setColumn] = useState(1);
  const [marginalizedSeats, setMarginalizedSeats] = useState<number[]>([]);
  const [doubleMarginalizedSeats, setDoubleMarginalizedSeats] = useState<number[]>([]);
  const [selectedSeats, setSelectedSeats] = useState<AvailableSeat[]>([]);
  const [sortedMapSeats, setSortedMapSeats] = useState<AvailableSeat[][]>([]);
  const [sortedSeats, setSortedSeats] = useState<AvailableSeat[]>([]);
  const { formData: { numberOfAdults, numberOfChildren, numberOfInfants }, selectedBus, updateFormData } = useRoadSearchStore();
  const totalPassengers = numberOfAdults + numberOfChildren + numberOfInfants;

  const getSeatStatus = (seat: AvailableSeat) => {
    const findSeat = selectedSeats.find((s) => s.SeatNumber === seat.SeatNumber);
    if (!findSeat) {
      return seat.IsAvailable ? 'available' : 'booked';
    }

    return 'selected';
  };
  const getIsDriverStatus = (seat: AvailableSeat) => seat.Row === 1 && seat.Column === 1;

  const handleSeatSelection = (seat: AvailableSeat) => {
    const findSeat = selectedSeats.find((s) => s.SeatNumber === seat.SeatNumber);

    if (!findSeat && seat.IsAvailable && !isCompleted) {
      setSelectedSeats([...selectedSeats, { ...seat }]);
    } else {
      const newSelections = selectedSeats.filter((s) => s.SeatNumber !== seat.SeatNumber);
      setSelectedSeats([...newSelections]);
    }
  };

  const handleProceed = () => {
    updateFormData({ selectedSeats });
    navigate('/passenger-details/road');
  };

  const getSeatWidth = useCallback(() => {
    const numberOfSeats = selectedBus?.Capacity || 0;
    setColumn(selectedBus?.Column || 1);

    let size: string;

    if (numberOfSeats >= 53) {
      size = 'large';
    } else if (numberOfSeats >= 12 && numberOfSeats <= 15) {
      size = 'medium';
    } else if (numberOfSeats >= 8 && numberOfSeats <= 12) {
      size = 'medium-s';
    } else {
      size = 'small';
    }
    setBusSize(size);
    const allSort = [];
  }, [selectedBus]);

  // const displaySeatPosition = (sortSeats) => {
  //   sortSeats.forEach((rows:any, rowIndex) => {
  //     // eslint-disable-next-line prefer-const
  //     let mapSortedSeats = [];
  //     // eslint-disable-next-line no-plusplus
  //     for (let colIndex = 1; colIndex < column; colIndex++) {
  //       const element = rows[colIndex];
  //       console.log('tree', element);
  //       if (element?.Column) {
  //         mapSortedSeats.push(element);
  //       } else {
  //         mapSortedSeats.push({
  //           IsAvailable: false,
  //           SeatNumber: 0,
  //           Price: 0,
  //           Row: rowIndex + 1,
  //           Column: colIndex + 1,
  //         });
  //       }
  //     }
  //     console.log({ mapSortedSeats });
  //     return mapSortedSeats;
  //   });
  // };
  // eslint-disable-next-line prefer-const
  let allSort = [];

  const displaySeatPosition = (sortSeats) => {
    sortSeats.forEach((rows:any, rowIndex) => {
      // eslint-disable-next-line prefer-const
      let mapSortedSeats = [];
      // eslint-disable-next-line no-plusplus
      for (let colIndex = 1; colIndex <= column; colIndex++) {
        // eslint-disable-next-line no-loop-func
        const checkRow = rows?.find((item :AvailableSeat) => item.Column === colIndex);
        if (checkRow) {
          // eslint-disable-next-line no-loop-func

          mapSortedSeats.push(checkRow);
        } else {
          mapSortedSeats.push({
            IsAvailable: false,
            SeatNumber: 0,
            Price: 0,
            Row: rowIndex + 1,
            Column: colIndex,
          });
        }
      }
      // console.log('mappedSorted', mapSortedSeats);
      allSort.push(mapSortedSeats);
      // console.log('allSorts', allSort);
    });
    return allSort;
  };

  useEffect(() => {
    setIsCompleted(selectedSeats.length === totalPassengers);
  }, [selectedSeats, totalPassengers]);

  useEffect(() => {
    if (selectedBus && selectedBus.AvailableSeats) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const seats = selectedBus.AvailableSeats.sort((a, b) => a.Row - b.Row || a.SeatNumber - b.SeatNumber);
      const groupedValue = groupBy(selectedBus.AvailableSeats, (seat: AvailableSeat) => seat.Row);
      const groupedSeats = Array.from(groupedValue, ([_, value]) => (
        value));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSortedSeats(seats as unknown as AvailableSeat[]);
      getSeatWidth();
      const checkMappedSeat = displaySeatPosition(groupedSeats);
      // return order[a.code] - order[b.code] || a.counter - b.counter;
      setSortedMapSeats(checkMappedSeat);
      // setSortedSeats(displaySeatPosition(groupedSeats))
    }
  }, [selectedBus, busSize]);

  return (
    <Wrapper>
      <Card>
        <ContentWrapper size={busSize}>
          <IndicatorWrapper>
            <IndicatorContent>
              <SeatWrapper />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).available}
              </StyledParagraph>
            </IndicatorContent>
            <IndicatorContent>
              <SeatWrapper status="booked" />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).booked}
              </StyledParagraph>
            </IndicatorContent>
            <IndicatorContent>
              <SeatWrapper status="blocked" />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).blocked}
              </StyledParagraph>
            </IndicatorContent>
            <IndicatorContent>
              <SeatWrapper status="selected" />
              <StyledParagraph type="secondary" size="small" align="center">
                {t(translations).selected}
              </StyledParagraph>
            </IndicatorContent>
          </IndicatorWrapper>

          <StyledParagraph align="center" padded="true">
            {`${t(translations).seatSelectionText} ${pluralizeText(
              totalPassengers,
              t(translations).passenger,
              t(translations).passengers,
            )}`}
          </StyledParagraph>

          {/* <PadWrapper column={column}>
            {sortedMapSeats.map((seatArr) => seatArr.map((seat, index) => (
              <SeatWrapper
                key={seat.SeatNumber}
                status={getSeatStatus(seat)}
                onClick={() => handleSeatSelection(seat)}
                isEmpty={seat.SeatNumber === 0}
                isDriver={getIsDriverStatus(seat)}
              >
                {getIsDriverStatus(seat) ? <img src={steering} alt="" /> : seat.SeatNumber}
              </SeatWrapper>
            )))}
          </PadWrapper> */}

          <PadContentWrapper>
            {sortedMapSeats.map((seatArr) => (
              <PadWrapper column={column}>
                {seatArr.map((seat) => (
                  <SeatWrapper
                    key={seat.SeatNumber}
                    status={getSeatStatus(seat)}
                    onClick={() => handleSeatSelection(seat)}
                    isEmpty={seat.SeatNumber === 0 && !getIsDriverStatus(seat)}
                    isDriver={getIsDriverStatus(seat)}
                  >
                    {getIsDriverStatus(seat) ? <img src={steering} alt="" /> : seat.SeatNumber}
                  </SeatWrapper>
                ))}

              </PadWrapper>
            ))}
          </PadContentWrapper>
          {/* <PadWrapper column={column}>
            {sortedMapSeats.map((seatArr) => seatArr.map((seat) => (
              <SeatWrapper
                key={seat.SeatNumber}
                status={getSeatStatus(seat)}
                onClick={() => handleSeatSelection(seat)}
                isEmpty={seat.SeatNumber === 0 && !getIsDriverStatus(seat)}
                isDriver={getIsDriverStatus(seat)}
              >
                {getIsDriverStatus(seat) ? <img src={steering} alt="" /> : seat.SeatNumber}
              </SeatWrapper>
            )))}
          </PadWrapper> */}

          <StyledButton type="primary" block size="large" disabled={!isCompleted} onClick={handleProceed}>
            {t(translations).done}
          </StyledButton>
        </ContentWrapper>
      </Card>
    </Wrapper>
  );
}

export default SeatSelection;
