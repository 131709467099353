import React, { useState } from 'react';
import {
  Avatar, Typography, Row, Col, Modal,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  AvatarWrapper, Content, FormWrapper, UserDetails, Wrapper,
} from './styles';
import sunlightIcon from '../../assets/images/sunlight.svg';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import SearchBooking from '../SearchBooking';
import { getUser } from '../../utils/helpers';
import { LANGUAGES_OPTIONS } from '../../utils/constants';
import { I18nContextType, useI18nContext } from '../../utils/I18n';

const { Title } = Typography;
type Props = {
  hideContent?: boolean
}
function Header({ hideContent }: Props) {
  const {
    dispatch,
    state: { langCode },
  }: I18nContextType = useI18nContext();

  const navigate = useNavigate();
  const [bookingModal, setBookingModal] = useState(false);
  const [lang, setLang] = useState<string | number>(langCode);
  const user = getUser();

  const handleLangChange = (value: string | number) => {
    setLang(value);

    dispatch({
      type: 'SET_LANGUAGE',
      payload: {
        langCode: value,
      },
    });
  };

  return (
    <Wrapper>
      <div className="container">
        <Content>
          <Row>
            <Col xs={24} lg={0}>
              <UserDetails>
                <AvatarWrapper>
                  <Avatar size={26} icon={<UserOutlined />} style={{ background: '#fff' }} />
                </AvatarWrapper>

                <Title level={4}>{`Hello ${user?.CustomerName || ''},`}</Title>
                <img src={sunlightIcon} alt="day-icon" />
              </UserDetails>
            </Col>
          </Row>

          <Row>
            <Col xs={0} lg={24}>
              {!hideContent && (
              <StyledButton size="large" mode="primary" padded="large" onClick={() => setBookingModal(true)}>
                Manage Booking
              </StyledButton>
              ) }
            </Col>
          </Row>

          <StyledSelect
            value={lang}
            options={LANGUAGES_OPTIONS}
            onChange={handleLangChange}
          />
        </Content>

        <Modal
          centered
          visible={bookingModal}
          footer={null}
          onCancel={() => setBookingModal(false)}
          width={400}
          getContainer={document.getElementById('app-container') as HTMLElement}
        >
          <Title level={4}>Manage Booking</Title>
          <FormWrapper>
            <SearchBooking filled handleNavigate={(tripType) => navigate(`/my-booking/${tripType}`)} />
          </FormWrapper>
        </Modal>
      </div>
    </Wrapper>
  );
}

Header.defaultProps = {
  hideContent: true,
};
export default Header;
