import moment, { Moment } from 'moment';
import { SUPPORTED_LANGUAGES } from './constants';

export const pluralizeText = (total: number, singularText: string, pluralText: string): string => (total === 1
  ? `1 ${singularText}`
  : `${total} ${pluralText}`);

export const formatNumberAsCurrency = (value: number, currency?: string) => value.toLocaleString('en-US', {
  style: 'currency',
  currency: currency || 'NGN',
});

export const getThemeName = () => {
  const auth = localStorage.getItem('trips_auth');
  if (auth) {
    const obj: UserAuth = JSON.parse(auth);
    return obj.ExtraData?.PrimaryColor;
  }

  return '';
};

export const getUser = () => {
  const user = localStorage.getItem('trips_user');

  if (user) {
    const obj: User = JSON.parse(user);

    return obj;
  }

  return null;
};

export const getExtraData = () => {
  const config = localStorage.getItem('trips_auth');

  if (config) {
    const obj = JSON.parse(config);

    return obj && obj.ExtraData;
  }

  return null;
};

export const getFontSize = (size?: string) => {
  let fontSize = 1;
  switch (size) {
    case 'SMALL':
      fontSize = 0.8;
      break;
    case 'NORMAL':
      fontSize = 1;
      break;
    case 'MEDIUM':
      fontSize = 1.3;
      break;
    case 'LARGE':
      fontSize = 1.5;
      break;
    case 'EXTRA_LARGE':
      fontSize = 2;
      break;
    default:
      fontSize = 1;
  }

  return fontSize;
};

export const postMessage = (result: any) => {
  const stringifyObj = JSON.stringify(result);

  if (window.ReactNativeWebView) {
    // post message to react native
    window.ReactNativeWebView.postMessage(stringifyObj);
  } else if (window?.webkit?.messageHandlers) {
    // post message to Ionic
    window.webkit.messageHandlers.cordova_iab.postMessage(
      stringifyObj,
    );
  } else {
    // post message via Iframe
    // eslint-disable-next-line no-console
    console.log('postMsg:', stringifyObj);
    window.parent.postMessage(stringifyObj, '*');
  }
};
export const isMobileScreen = () => window.outerWidth < 992;

export const getBrowserLanguage = (): LanguageCode => {
  const langCode = window.localStorage.getItem('langCode') as LanguageCode;
  return SUPPORTED_LANGUAGES.includes(langCode) ? langCode : 'en';
};

export const getDisabledDateRange = (refDate: Moment, dateLimit = moment().startOf('day')) => refDate && refDate <= dateLimit;
export const getStartOfDay = (refDate?: Moment) => (refDate ? refDate.startOf('day') : moment().startOf('day'));

export const groupBy = (list: AvailableSeat[], keyGetter: any) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
