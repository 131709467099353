import React from 'react';
import {
  Card, Row, Col, Space, Divider, Button, Empty,
} from 'antd';
import moment from 'moment';
import { useBookingStore } from '../../store/booking/bookingStore';
import {
  Badge, DetailItem, DetailItemIcon, DetailItemsWrapper, StepItemContent,
  StepItemWrapper,
  StepProgressLine, StepsWrapper,
} from '../TripSummary/styles';
import { ReactComponent as FlightIcon } from '../../assets/images/flight.svg';
import {
  GiveMeSpace,
  SpaceBetweenAlignContent,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { getAirlineLogo } from '../AvailableFlights/helper';
import {
  StyledParagraph,
  StyledText, StyledTitle,
} from '../CustomAntdComponents/Typography';
import { HighlightDetailItem } from '../FlightItinerary/styles';
import { ContentWrapper } from './styles';
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-directions.svg';
import { ReactComponent as PassengerIcon } from '../../assets/images/passenger-color.svg';
import { pluralizeText } from '../../utils/helpers';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import {
  DetailEntryWrapper,
  DetailTitleWrapper,
  FlightDetailsWrapper,
} from '../AvailableFlights/styles';

function ManageFlightBooking() {
  const { flightReservation } = useBookingStore();
  const renderSummary = () => {
    if (!flightReservation) {
      return <div>&nbsp;</div>;
    }

    const lastFlightSetIndex = flightReservation.FlightSets.length - 1;
    const lastFlightEntryIndex = flightReservation.FlightSets[lastFlightSetIndex].FlightEntries.length - 1;
    const lastFlightEntry = flightReservation.FlightSets[lastFlightSetIndex].FlightEntries[lastFlightEntryIndex];
    const departureItinerary = flightReservation.FlightSets[0];
    const returnItinerary = flightReservation.FlightSets[1];
    const startFlightEntry = departureItinerary.FlightEntries[0];
    const numberOfAdults = flightReservation.AirTravellers.filter((pax) => pax.PassengerTypeCode === 'ADT').length;
    const numberOfChildren = flightReservation.AirTravellers.filter((pax) => pax.PassengerTypeCode === 'CHD').length;
    const numberOfInfants = flightReservation.AirTravellers.filter((pax) => pax.PassengerTypeCode === 'INF').length;

    const renderEntryDetails = (entry: FlightEntry) => (
      <Col xs={24} lg={12} key={entry.FlightNumber}>
        <Card size="small">
          <Space direction="vertical" size="large" style={{ marginTop: '0' }}>
            <StyledParagraph>
              {moment(entry.DepartureDate).format('ddd MMM DD')}
              &nbsp;&nbsp;
              <StyledText strong>{moment(entry.DepartureDate).format('hh:mm a')}</StyledText>
            </StyledParagraph>

            <div>
              <VerticalAlignContent>
                <StyledParagraph>
                  Layover:
                </StyledParagraph>
                <ClockIcon />
                <StyledText strong color="primary">
                  {moment(entry.ArrivalDate).diff(moment(entry.DepartureDate), 'hours')}
                  {' '}
                  hours
                </StyledText>
                <StyledText strong color="primary">{entry.TotalDuration}</StyledText>
                <br />
              </VerticalAlignContent>
              <StyledParagraph size="large">
                {entry.DepartureAirportName}
              </StyledParagraph>
            </div>

            <VerticalAlignContent>
              <StyledParagraph>
                {entry.DepartureAirportCode}
                &nbsp;
              </StyledParagraph>
              <FlightIcon />
              <StyledParagraph>
                &nbsp;
                {entry.ArrivalAirportCode}
              </StyledParagraph>
            </VerticalAlignContent>
          </Space>
        </Card>
      </Col>
    );

    return (
      <>
        <Row gutter={[32, 8]}>
          <Col span={24}>
            <HighlightDetailItem>
              <SpaceBetweenAlignContent>
                <StyledText size="small" color="secondary">
                  {`FLIGHT PNR: ${flightReservation.BookingReferenceId}`}
                </StyledText>
                <StyledText strong>
                  NGN 00,000.00
                </StyledText>
              </SpaceBetweenAlignContent>
            </HighlightDetailItem>
          </Col>
          <Col lg={12} xs={24}>
            <Row gutter={[0, 8]} justify="space-between" align="middle">
              <Col span={24}>
                <Space direction="vertical" size="middle">
                  <VerticalAlignContent>
                    <img src={getAirlineLogo(startFlightEntry.OperatingAirlineCode)} alt="" />
                    <StyledText color="secondary" size="small">
                      {startFlightEntry.OperatingAirlineName}
                    </StyledText>
                  </VerticalAlignContent>
                </Space>
              </Col>
              <Col span={24}>
                <StepsWrapper>
                  <StepProgressLine />
                  <StepItemWrapper>
                    <StepItemContent>
                      <FlightIcon />
                    </StepItemContent>
                  </StepItemWrapper>

                  <StepProgressLine />

                </StepsWrapper>
              </Col>
              <Col span={9}>
                <StyledTitle level={2}>
                  {startFlightEntry.DepartureAirportCode}
                </StyledTitle>
                <StyledParagraph>
                  {/* TODO Missing data */}
                  Airport, State
                </StyledParagraph>
              </Col>
              <Col span={6}>
                <Badge>
                  <StyledParagraph style={{ textAlign: 'center' }}>
                    {startFlightEntry.FlightClass}
                  </StyledParagraph>
                </Badge>
              </Col>
              <Col span={9}>
                <StyledTitle level={2} align="right">
                  {lastFlightEntry.ArrivalAirportCode}
                </StyledTitle>
                <StyledParagraph align="right">
                  {/* TODO Missing data */}
                  Airport, State
                </StyledParagraph>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={12}>
            <Row gutter={[0, 8]}>
              <Col span={12}>
                <DetailItemsWrapper>
                  <DetailItem>
                    <DetailItemIcon>
                      <ArrowIcon />
                    </DetailItemIcon>
                    <StyledParagraph strong>
                      {moment(startFlightEntry.DepartureDate).format('ddd, D MMM') }
                    </StyledParagraph>
                  </DetailItem>
                </DetailItemsWrapper>
              </Col>

              <Col span={12}>
                <DetailItemsWrapper>
                  <VerticalAlignContent>
                    <DetailItemIcon>
                      <PassengerIcon />
                    </DetailItemIcon>
                    <StyledParagraph align="right">
                      <span>
                        {pluralizeText(numberOfAdults, 'Adult', 'Adults')}
                  &nbsp;&nbsp;
                      </span>

                      {Boolean(numberOfChildren) && (
                        <span>
                            {pluralizeText(numberOfChildren, 'Child', 'Children')}
                          &nbsp;&nbsp;
                        </span>
                      )}

                      {Boolean(numberOfInfants) && (
                        <span>
                            {pluralizeText(numberOfInfants, 'Infant', 'Infants')}
                          &nbsp;&nbsp;
                        </span>
                      )}
                    </StyledParagraph>
                  </VerticalAlignContent>
                </DetailItemsWrapper>
              </Col>

              <Col span={24}>
                <HighlightDetailItem>
                  <SpaceBetweenAlignContent>
                    <StyledParagraph>
                      {moment(startFlightEntry.DepartureDate).format('ll')}
                    </StyledParagraph>
                    <StyledText strong>
                      {moment(startFlightEntry.DepartureDate).format('hh:mm a')}
                    </StyledText>
                  </SpaceBetweenAlignContent>
                </HighlightDetailItem>
              </Col>

              <Col span={24}>
                <VerticalAlignContent>
                  <StyledText size="small">
                    {pluralizeText(flightReservation.FlightSets[0].FlightEntries.length, 'Stop', 'Stops')}
                  </StyledText>
                  <VerticalAlignContent>
                    <ClockIcon />
                    <StyledText size="small">
                      {startFlightEntry.Duration}
                    </StyledText>
                  </VerticalAlignContent>
                </VerticalAlignContent>
              </Col>
            </Row>
          </Col>
        </Row>

        <GiveMeSpace />
        <Row gutter={16}>
          <Col xs={24} lg={returnItinerary ? 12 : 24}>
            <FlightDetailsWrapper>
              <DetailTitleWrapper>
                <StyledParagraph>Departure</StyledParagraph>
              </DetailTitleWrapper>
              <DetailEntryWrapper>
                <Row gutter={16}>
                  {departureItinerary.FlightEntries.map((entry: FlightEntry) => (
                    renderEntryDetails(entry)
                  ))}
                </Row>
              </DetailEntryWrapper>
            </FlightDetailsWrapper>
          </Col>

          {
            returnItinerary && (
            <Col xs={24} lg={12}>
              <FlightDetailsWrapper>
                <DetailTitleWrapper>
                  <StyledParagraph>Return</StyledParagraph>
                </DetailTitleWrapper>
                <DetailEntryWrapper>
                  <Row gutter={16}>
                    {returnItinerary.FlightEntries.map((entry: FlightEntry) => (
                      renderEntryDetails(entry)
                    ))}
                  </Row>
                </DetailEntryWrapper>
              </FlightDetailsWrapper>
            </Col>
            )
          }
        </Row>
      </>
    );
  };

  return flightReservation ? (
    <Card>
      <ContentWrapper>
        {renderSummary()}

        <Divider orientationMargin={4} />

        <Row>
          <Col xs={{ span: 12, offset: 12 }} lg={{ span: 4, offset: 20 }}>
            <Button type="primary" block>
              Modify
            </Button>
          </Col>
        </Row>
      </ContentWrapper>

    </Card>
  ) : (
    <Card>
      <Empty />
    </Card>
  );
}

export default ManageFlightBooking;
