import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { GREY, PRIMARY, FONT_SIZE } from '../../utils/colors';

const { Paragraph, Title, Text } = Typography;

type StyledParagraphProp = {
  align?: string,
  size?: string,
  color?: string,
  padded?: string,
}

const getSizePixel = (size: string) => {
  let result = '';
  switch (size) {
    case 'small':
      result = '10px';
      break;
    case 'medium':
      result = '12px';
      break;
    case 'extra':
      result = '20px';
      break;
    default:
      result = '16px';
  }

  return result;
};

export const StyledParagraph = styled(Paragraph)<StyledParagraphProp>`
  margin: 0 !important;
  text-align: ${({ align }) => align || 'left'};
  
  ${({ size }) => size && css`
    font-size: calc(${getSizePixel(size)} * ${FONT_SIZE});
  `}
  
  ${({ color }) => color && css`
    color: ${color === 'primary' ? PRIMARY : GREY};
  `}
  
  ${({ padded }) => padded && css`
    margin: 10px 0 !important;
  `}
`;

type StyledTitleProp = {
  align?: string,
  color?: string,
  size?:string
}

export const StyledTitle = styled(Title)<StyledTitleProp>`
  text-align: ${({ align }) => align || 'left'};
  margin: 0 !important;

  ${({ size }) => size && css`
    font-size: calc(${getSizePixel(size)} * ${FONT_SIZE});
  `}
  ${({ color }) => color && css`
    color: ${color === 'primary' ? PRIMARY : GREY}!important;
  `}
`;

type StyledTextProp = {
  size?: string,
  color?: string,
}
export const StyledText = styled(Text)<StyledTextProp>`
  margin: 0 !important;
  
  ${({ size }) => size && css`
    font-size: calc(${getSizePixel(size)} * ${FONT_SIZE});
  `}
  
  ${({ color }) => color && css`
    color: ${color === 'primary' ? PRIMARY : GREY}!important;
  `}
`;
