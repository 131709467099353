import { Moment } from 'moment';
import {
  FlightPassenger, FlightRoute,
  FlightSearchPayload,
  FlightSearchStore,
} from '../../store/flightSearch/types';

type PaxCounter = {
  numberOfAdults: number;
  numberOfChildren?: number;
  numberOfInfants?: number;
}

type FlightRouteArg = {
  DepartureDate?: Moment,
  ReturnDate?: Moment,
  OriginLocationCode?: string,
  DestinationLocationCode?: string,
  tripType: string
  multiCity?:FlightRoute[]
}
const generateFlightPassengersCount = ({
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
}: PaxCounter) :FlightPassenger[] => {
  const payload = [
    {
      Code: 'ADT',
      Quantity: numberOfAdults,
    },
  ];

  if (numberOfChildren) {
    payload.push({
      Code: 'CHD',
      Quantity: numberOfChildren,
    });
  }

  if (numberOfInfants) {
    payload.push({
      Code: 'INF',
      Quantity: numberOfInfants,
    });
  }

  return payload;
};

const generateFlightRoutes = (arg: FlightRouteArg) :FlightRoute[] => {
  const departureDate = arg.DepartureDate ? arg.DepartureDate.format('YYYY-MM-DD') : '';
  const returnDate = arg.ReturnDate ? arg.ReturnDate.format('YYYY-MM-DD') : '';
  let payload;

  if (arg.tripType === 'multi_city' && arg?.multiCity?.length > 0) {
    payload = arg.multiCity;
  } else {
    payload = [
      {
        DepartureDate: departureDate,
        OriginLocationCode: arg.OriginLocationCode || '',
        DestinationLocationCode: arg.DestinationLocationCode || '',
      },
    ];

    if (arg.tripType === 'round_trip') {
      payload.push({
        DepartureDate: returnDate,
        OriginLocationCode: arg.DestinationLocationCode || '',
        DestinationLocationCode: arg.OriginLocationCode || '',
      });
    }
  }

  return payload;
};

export const buildFlightSearchPayload = (data: FlightSearchStore): FlightSearchPayload => {
  const {
    Currency,
    From,
    pageSize,
    OrderbyFlightTime,
    OrderbyPrice,
    EnsureAgentUnicityForRoutes,
    FlightClass,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    DepartureDate,
    ReturnDate,
    DestinationLocationCode,
    OriginLocationCode,
    tripType,
    multiCity,
  } = data;

  const payload = {
    FlightRoutes: generateFlightRoutes({
      DepartureDate,
      ReturnDate,
      DestinationLocationCode,
      OriginLocationCode,
      tripType,
      multiCity,
    }),
    FlightClasses: [{
      Name: FlightClass,
    }],
    FlightPassengers: generateFlightPassengersCount({ numberOfAdults, numberOfChildren, numberOfInfants }),
    Currency,
    EnsureAgentUnicityForRoutes,
    OrderbyFlightTime,
    OrderbyPrice,
    pageSize,
    From,
  };

  return payload;
};
