import React from 'react';
import {
  Col, Row,
} from 'antd';
import { useParams } from 'react-router-dom';
import {
  Wrapper,
} from './styles';
import HeaderNavigation from '../../components/HeaderNavigation';
import Header from '../../components/Header';
import FlightPassengers from './FlightPassengers';
import RoadPassengers from './RoadPassengers';
import WaterPassengers from './WaterPassengers';
import TrainPassengers from './TrainPassengers';

function PassengerDetails() {
  const { tripType } = useParams();
  const renderTripForm = () => {
    switch (tripType) {
      case 'flight':
        return <FlightPassengers />;
      case 'road':
        return <RoadPassengers />;
      case 'water':
        return <WaterPassengers />;
      case 'rail':
        return <TrainPassengers />;
      default:
        return <div>&nbsp;</div>;
    }
  };

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>
      <div className="container">
        <Wrapper>
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name="Passenger Details" />
            </Col>
            <Col xs={0} lg={24}>
              <HeaderNavigation />
            </Col>
          </Row>

          {renderTripForm()}
        </Wrapper>
      </div>
    </>
  );
}

export default PassengerDetails;
