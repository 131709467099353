import React from 'react';
import {
  Typography, Empty,
} from 'antd';
import {
  FlightCountText,
  Wrapper,
} from './styles';
import SkeletonLayout from './SkeletonLayout';
import FlightTripDetails from './FlightTripDetails';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';

const { Title } = Typography;

type Props = {
  results?: InternationalFlightResultItem,
  error?: string | null,
  loading?: boolean,
  onBookingRedirect: (arg: AirlineResultItem[]) => void
}
function AvailableInternationalFlights({
  loading, results, error, onBookingRedirect,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const resultLists: AirlineResultItem[] = results ? results.ResultList : [];
  const flightCount = () => {
    if (resultLists.length > 0) {
      if (resultLists.length === 1) {
        return `${resultLists?.length} Flight`;
      }
      return `${resultLists?.length} Flights`;
    }
    return null;
  };

  return (
    <Wrapper>
      <Title level={5}>
        {t(translations).listingsTitle}
        <FlightCountText>
          {flightCount()}
        </FlightCountText>
        {' '}

      </Title>

      {!loading && error && <Empty description={error} /> }
      {!loading && !error && resultLists.length === 0 && <Empty description={t(translations).emptyMessage} /> }

      {loading ? (
        <>
          <SkeletonLayout />
          <SkeletonLayout />
        </>
      ) : resultLists.map((result) => (
        <FlightTripDetails
          key={result.Properties.RecommendationID}
          result={result}
          onBookingRedirect={onBookingRedirect}
        />
      ))}
    </Wrapper>

  );
}

AvailableInternationalFlights.defaultProps = {
  loading: false,
  error: undefined,
  results: {
    TotalCount: 0,
    ResultList: [],
  },
};
export default AvailableInternationalFlights;
