/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Typography, Button,
} from 'antd';
import moment, { Moment } from 'moment';
import {
  Badge,
  BadgeWrapper,
  DetailItem,
  DetailItemIcon,
  DetailItemsWrapper,
  DetailsWrapper,
  HighlightDetailItem,
  LayoverDurationWrapper,
  LayoverItemWrapper,
  RenderStopOverWrapper,
  StepItemContent,
  StepItemWrapper,
  StepProgressLine,
  StepsWrapper,
  Wrapper,
} from './styles';
import { ReactComponent as PassengerIcon } from '../../assets/images/passenger-color.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-directions.svg';
import { ReactComponent as FlightIcon } from '../../assets/images/flight.svg';

import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { pluralizeText } from '../../utils/helpers';
import { useStore } from '../../store/global/appStore';
import { getAirportStateName } from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';
import globalTranslations from '../../utils/globalTranslation';
import { StyledParagraph, StyledText } from '../CustomAntdComponents/Typography';
import { SpaceBetweenAlignContent, VerticalAlignContent } from '../CustomAntdComponents/Utitlities';
import { getAirlineLogo } from '../AvailableFlights/helper';

const { Title, Paragraph } = Typography;
type Props = {
  showChangeButton?: boolean,
  returning?: boolean
  itinerary?: boolean
  OriginLocationCode: string
  DestinationLocationCode: string
  DepartureDate: Moment | string
  ReturnDate?: Moment | string
  flightDetails?:FlightDetails
}

function TripSummaryMoreDetails({
  showChangeButton, returning, itinerary, OriginLocationCode, DestinationLocationCode, DepartureDate, ReturnDate, flightDetails,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData,
  } = useFlightSearchStore();

  const { domesticAirports, internationalAirports } = useStore();

  const isRoundTrip = formData.tripType === 'round_trip';
  const airports = formData.flightType === 'domestic' ? domesticAirports : internationalAirports;
  const routesWithLayoverCount = flightDetails.FlightEntries.filter((item) => item.HasStopOver);

  const renderFlightTime = () => {
    if (itinerary) {
      return moment(returning ? ReturnDate : DepartureDate).format('ll');
    }

    if (isRoundTrip) {
      return `${moment(DepartureDate).format('ll')} - ${moment(ReturnDate).format('ll')}`;
    }

    return `${moment(DepartureDate).format('ll')}`;
  };

  const renderStopOver = () => {
    // console.log('flightDetails', flightDetails);
    const isEntriesContainsLayover = flightDetails.FlightEntries.filter((item) => item.HasStopOver).map((item) => (
      <LayoverItemWrapper>
        {/* <p style={{ marginBottom: '0px' }}>Layover(s)</p> */}
        <p className="dept-airpotname">
          <span>
            {item.DepartureAirportName.substring(0, 8)}
            {/* {item.DepartureAirportName} */}
            {' '}
            &#x2022;
          </span>
          {' '}
          <span>{item.StopOverDuartion}</span>

        </p>

      </LayoverItemWrapper>
    ));
    return isEntriesContainsLayover.length > 0 ? isEntriesContainsLayover : null;
  };

  const displayStopCount = () => {
    const stopText = flightDetails.StopOvers > 1 ? 'stops' : 'stop';
    return flightDetails.StopOvers > 0 ? `(${flightDetails.StopOvers} ${stopText})` : '(Nonstop)';
  };
  return (
    <Wrapper>
      <Row align="middle">
        <Col lg={showChangeButton ? 12 : 24} xs={24}>
          <Row justify="space-between" align="middle">
            <Col style={{ marginBottom: '15px' }}>
              <VerticalAlignContent>
                <img src={getAirlineLogo(flightDetails.OperatingAirlineCode)} alt="" style={{ width: '20%' }} />
                <StyledText color="secondary" size="medium">
                  {flightDetails.OperatingAirlineName}
                </StyledText>
              </VerticalAlignContent>

            </Col>
            <Col>
              <BadgeWrapper>
                <Badge>
                  <Paragraph style={{ textAlign: 'center' }}>
                    {formData.FlightClass}
                  </Paragraph>
                </Badge>
              </BadgeWrapper>
            </Col>
          </Row>

        </Col>
        <Col lg={showChangeButton ? 12 : 24} xs={24}>
          <LayoverDurationWrapper>
            <span style={{ marginBottom: '5px' }}>
              {flightDetails.Duration}
              {' '}
              {displayStopCount()}
            </span>

          </LayoverDurationWrapper>
          <StepsWrapper>
            <StepProgressLine />
            <StepItemWrapper>
              <StepItemContent>
                <FlightIcon />
              </StepItemContent>
            </StepItemWrapper>

            <StepProgressLine />

          </StepsWrapper>
          <Row justify="space-between" align="top" wrap={false}>
            <>
              <Col>
                <Paragraph strong style={{ fontSize: '20px' }}>
                  {OriginLocationCode}
                </Paragraph>
                <Paragraph>
                  {getAirportStateName(airports, OriginLocationCode)}
                </Paragraph>
                <Paragraph strong style={{ fontSize: '15px' }}>
                  {moment(DepartureDate).format('hh:mm a')}
                </Paragraph>
              </Col>

              <Col>
                <RenderStopOverWrapper>
                  { flightDetails.StopOvers > 0 && <p style={{ marginBottom: '0px' }}>Layover(s)</p> }

                  {renderStopOver()}

                </RenderStopOverWrapper>

              </Col>
              <Col>
                <Paragraph strong style={{ textAlign: 'right', fontSize: '20px' }}>
                  {DestinationLocationCode}
                </Paragraph>
                <Paragraph style={{ textAlign: 'right' }}>
                  {getAirportStateName(airports, DestinationLocationCode)}
                </Paragraph>
                <Paragraph strong style={{ textAlign: 'right', fontSize: '15px' }}>
                  {moment(ReturnDate).format('hh:mm a')}
                </Paragraph>
              </Col>
            </>

          </Row>

        </Col>
        <Col lg={showChangeButton ? 8 : 24} xs={24}>
          <DetailsWrapper>
            <HighlightDetailItem>
              <SpaceBetweenAlignContent>
                <StyledText size="small">
                  {moment(flightDetails.DepartureDate).format('ddd, DD MMM')}
                </StyledText>
                <StyledText size="small" strong>
                  {moment(flightDetails.DepartureDate).format('hh:mm a')}
                </StyledText>
              </SpaceBetweenAlignContent>
            </HighlightDetailItem>

          </DetailsWrapper>
        </Col>

        {/* <Col lg={showChangeButton ? 8 : 24} xs={24}>
          <Row gutter={[8, 0]}>
            <Col lg={showChangeButton ? 24 : 12} xs={12}>
              <DetailItemsWrapper>
                <DetailItem>
                  <DetailItemIcon>
                    <ArrowIcon />
                  </DetailItemIcon>

                  <Paragraph strong>
                    {renderFlightTime()}
                  </Paragraph>
                </DetailItem>
              </DetailItemsWrapper>

            </Col>
            <Col lg={showChangeButton ? 24 : 12} xs={12}>
              <DetailItemsWrapper>
                <DetailItem>
                  <DetailItemIcon>
                    <PassengerIcon />
                  </DetailItemIcon>
                  <Paragraph style={{ textAlign: 'right' }} strong>
                    <span>
                      {pluralizeText(
                        formData.numberOfAdults,
                        t(globalTranslations).passengerSelector.adult,
                        t(globalTranslations).passengerSelector.adults,
                      )}
                      &nbsp;&nbsp;
                    </span>

                    {Boolean(formData.numberOfChildren) && (
                    <span>
                      {pluralizeText(
                        formData.numberOfChildren,
                        t(globalTranslations).passengerSelector.child,
                        t(globalTranslations).passengerSelector.children,
                      )}
                      &nbsp;&nbsp;
                    </span>
                    )}

                    {Boolean(formData.numberOfInfants) && (
                    <span>
                      {pluralizeText(
                        formData.numberOfInfants,
                        t(globalTranslations).passengerSelector.infant,
                        t(globalTranslations).passengerSelector.infants,
                      )}
                      &nbsp;&nbsp;
                    </span>
                    )}
                  </Paragraph>
                </DetailItem>
              </DetailItemsWrapper>
            </Col>
          </Row>
        </Col> */}

        {/* <Col lg={showChangeButton ? 4 : 0} xs={0}>
          <div className="align-right">
            <Link to="/search/flight">
              <Button type="primary">
                {t(translations).changeButton}
              </Button>
            </Link>
          </div>
        </Col> */}
      </Row>
    </Wrapper>
  );
}

TripSummaryMoreDetails.defaultProps = {
  showChangeButton: true,
  returning: false,
  itinerary: false,
};

export default TripSummaryMoreDetails;
