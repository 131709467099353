import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
} from 'antd';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';

import {
  NavIconWrapper, TextContent, Wrapper,
} from './styles';

const { Text } = Typography;

type Props = {
  name?: string
  navigateHandler?: () => void
}
function HeaderNavigation({ name, navigateHandler }: Props) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (navigateHandler) {
      navigateHandler();
    } else {
      navigate(-1);
    }
  };
  return (
    <Wrapper>
      <NavIconWrapper onClick={handleNavigate}>
        <ArrowLeftIcon />
      </NavIconWrapper>

      {name && (
      <TextContent>
        <Text strong>{name}</Text>
      </TextContent>
      )}
    </Wrapper>
  );
}

HeaderNavigation.defaultProps = {
  name: undefined,
  navigateHandler: undefined,
};

export default HeaderNavigation;
