import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Col, Row, Form, Modal, Spin,
} from 'antd';
import {
  FormWrapper,
  ItemBodyWrapper,
  ItemHeaderWrapper,
  ItemWrapper,
  PriceModalContentWrapper,
} from './styles';
import {
  SpaceBetweenAlignContent,
  VerticalAlignContent,
} from '../../components/CustomAntdComponents/Utitlities';
import {
  StyledParagraph, StyledText,
  StyledTitle,
} from '../../components/CustomAntdComponents/Typography';
import arrowDown from '../../assets/images/arrow-down.svg';
import {
  buildWaterPayload,
  generateRoadCategoryInputs,
} from './helper';
import {
  formatNumberAsCurrency,
  isMobileScreen,
} from '../../utils/helpers';
import {
  StyledButton,
} from '../../components/CustomAntdComponents/StyledButton';
import ModalContent from '../../components/ModalContent';
import { ReactComponent as PayIcon } from '../../assets/images/pay.svg';
import StyledDrawer from '../../components/CustomAntdComponents/StyledDrawer';
import { useStore } from '../../store/global/appStore';
import AlertMessage from '../../components/AlertMessage';
import RoadPassengerForm
  from '../../components/PassengerForm/RoadPassengerForm';
import { useBookingStore } from '../../store/booking/bookingStore';
import TrainItinerary from '../../components/TrainItinerary';
import { confirmTicketResponse } from '../SearchWaterListings/mock';
import { useRailSearchStore } from '../../store/railSearch/railSearchStore';
import translations from './translations';
import { useI18nContext } from '../../utils/I18n';

function TrainPassengers() {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData,
    selectedTrain,
    selectedResult,
  } = useRailSearchStore();
  const navigate = useNavigate();

  const { requesting, setRequesting } = useStore();
  const { setReservation } = useBookingStore();

  // Create form fields based off how many items are in the order
  const adultInputs = generateRoadCategoryInputs(formData.numberOfAdults);
  const childrenInputs = generateRoadCategoryInputs(formData.numberOfChildren);
  const infantInputs = generateRoadCategoryInputs(formData.numberOfInfants);

  const totalPassengers = formData.numberOfAdults + formData.numberOfChildren + formData.numberOfInfants;

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [passengerKey, setPassengerKey] = useState(1);
  const [priceUpdatedModal, setPriceUpdateModal] = useState(false);
  const [priceUpdatedDialog, setPriceUpdateDialog] = useState(false);
  const [ticketReservation, setTicketReservation] = useState<ConfirmTicketResponse | null>(null);
  const onNextPassengerChange = () => {
    let nextKey = Number(passengerKey) + 1;
    nextKey = nextKey > totalPassengers ? 1 : nextKey;
    setPassengerKey(nextKey);
  };

  const renderHeaderContent = (category: string, passengerIndex: number, categoryIndex: number) => (
    <ItemHeaderWrapper onClick={() => setPassengerKey(passengerIndex)}>
      {
        passengerKey === passengerIndex ? (
          <StyledTitle level={5}>
            {`${t(translations).passenger} ${passengerIndex} (${category} ${categoryIndex})`}
          </StyledTitle>
        ) : (
          <SpaceBetweenAlignContent>
            <StyledParagraph size="medium">
              {`${t(translations).passenger} ${passengerIndex} (${category} ${categoryIndex})`}
            </StyledParagraph>
            <img src={arrowDown} alt="down" />
          </SpaceBetweenAlignContent>
        )
      }
    </ItemHeaderWrapper>
  );

  // This is commented out because Booking is expected to be done on the App
  // const handleBookTicket = async (payload?: any) => {
  //   setErrorMessage(null);
  //   setRequesting(true);
  //
  //   const requestPayload = payload || bookingPayload;
  //   const bookTicket = await postAPI(ROAD_BOOKING_URL, requestPayload);
  //   setRequesting(false);
  //
  //   if (bookTicket.status === 200) {
  //     setReservation(bookTicket.data, 'bookingPayload');
  //     navigate('/payment/road');
  //   } else {
  //     setErrorMessage(bookTicket.data);
  //     window.scrollTo(0, 0);
  //   }
  // };

  const handleBookTicket = async (payload?: any) => {
    setReservation(payload || ticketReservation, 'bookingPayload');
    navigate('/payment/rail');
  };

  const handleConfirmTicket = async (payload: any) => {
    setErrorMessage(null);
    setRequesting(true);

    // const confirmTicket = await postAPI(ROAD_CONFIRM_TICKET_URL, payload);
    const confirmTicket = confirmTicketResponse;
    setRequesting(false);

    if (confirmTicket.status === 200) {
      setTicketReservation(confirmTicket.data);
      if (confirmTicket.data.OldPrice === confirmTicket.data.NewPrice) {
        await handleBookTicket(payload);
      } else if (isMobileScreen()) {
        setRequesting(false);
        setPriceUpdateDialog(true);
      } else {
        setRequesting(false);
        setPriceUpdateModal(true);
      }
    } else {
      // setErrorMessage(confirmTicket.data);
      setErrorMessage('Error encountered');
      window.scrollTo(0, 0);
    }
  };

  const handleOnFinish = (values: any) => {
    if (selectedTrain && selectedResult) {
      const payload = buildWaterPayload(values, {
        SessionId: selectedResult.TripsSessionId,
        RecommendationID: selectedTrain.RecommendationId,
        CombinationID: selectedResult.CombinationId,
        GdsId: selectedResult.GdsId,
        AgentId: selectedResult.AgentId,
      }, formData);

      handleConfirmTicket(payload);
    }
  };

  const PriceUpdateButtons = (
    <SpaceBetweenAlignContent>
      <Link to="/search" style={{ width: '100%' }}>
        <StyledButton mode="secondary" block>
          {t(translations).cancel}
        </StyledButton>
      </Link>

      <StyledButton
        type="primary"
        block
        onClick={() => {
          setPriceUpdateDialog(false);
          setPriceUpdateModal(false);
          handleBookTicket();
        }}
      >
        {t(translations).proceed}
      </StyledButton>
    </SpaceBetweenAlignContent>
  );

  const PriceUpdatedContent = ticketReservation ? (
    <PriceModalContentWrapper>
      <StyledParagraph align="center" size="middle">
        {t(translations).priceUpdate}
        {' '}
        <StyledText color="primary">
          {`${formatNumberAsCurrency(ticketReservation.OldPrice || 0, 'NGN')}`}
        </StyledText>
        {' '}
        to&nbsp;
        <StyledText color="primary">
          {`${formatNumberAsCurrency(ticketReservation.NewPrice || 0, 'NGN')}`}
        </StyledText>
      </StyledParagraph>
    </PriceModalContentWrapper>
  ) : <div>&nbsp;</div>;

  return (
    <>
      { errorMessage && <AlertMessage message={errorMessage} /> }

      <Spin spinning={requesting}>
        <Form onFinish={handleOnFinish}>
          <Row gutter={[0, 16]}>
            <Col xs={0} lg={24}>
              <Row gutter={32}>
                <Col xs={0} lg={16}>
                  <VerticalAlignContent>
                    <StyledTitle level={3}>
                      {t(translations).passengers}
                      &nbsp;&nbsp;
                    </StyledTitle>
                  </VerticalAlignContent>
                </Col>
                <Col xs={0} lg={8}>
                  <StyledTitle level={3}>
                    {t(translations).itineraryTitle}
                  </StyledTitle>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[32, 16]}>
                <Col xs={24} lg={16}>
                  <Row>
                    <Col xs={24} lg={24}>
                      <FormWrapper>
                        <Form.List name="adults" initialValue={adultInputs}>
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <ItemWrapper active={passengerKey === field.key + 1} key={field.key}>
                                  {renderHeaderContent('Adult', field.key + 1, field.key + 1)}
                                  <ItemBodyWrapper>
                                    <RoadPassengerForm
                                      showNextTrigger={field.key + 1 < totalPassengers}
                                      field={field}
                                      passengerType="ADT"
                                      onNextPassenger={onNextPassengerChange}
                                    />
                                  </ItemBodyWrapper>
                                </ItemWrapper>
                              ))}
                            </>
                          )}
                        </Form.List>

                        {/* Children */}
                        <Form.List name="children" initialValue={childrenInputs}>
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <ItemWrapper active={passengerKey === formData.numberOfAdults + field.key + 1} key={field.key}>
                                  {renderHeaderContent('Child', (formData.numberOfAdults + field.key + 1), field.key + 1)}

                                  <ItemBodyWrapper>
                                    <RoadPassengerForm
                                      showNextTrigger={(field.key + 1 + formData.numberOfAdults) < totalPassengers}
                                      field={field}
                                      passengerType="CHD"
                                      onNextPassenger={onNextPassengerChange}
                                    />
                                  </ItemBodyWrapper>
                                </ItemWrapper>
                              ))}
                            </>
                          )}
                        </Form.List>

                        {/* Infant */}
                        <Form.List name="infants" initialValue={infantInputs}>
                          {(fields) => (
                            <>
                              {fields.map((field) => (
                                <ItemWrapper
                                  active={passengerKey === (formData.numberOfAdults + formData.numberOfChildren + field.key + 1)}
                                  key={field.key}
                                >
                                  {renderHeaderContent(
                                    'Infant',
                                    (formData.numberOfAdults + formData.numberOfChildren + field.key + 1),
                                    field.key + 1,
                                  )}

                                  <ItemBodyWrapper>
                                    <RoadPassengerForm
                                      showNextTrigger={(field.key + 1 + formData.numberOfAdults + formData.numberOfChildren) < totalPassengers}
                                      field={field}
                                      passengerType="INF"
                                      onNextPassenger={onNextPassengerChange}
                                    />
                                  </ItemBodyWrapper>
                                </ItemWrapper>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </FormWrapper>
                    </Col>

                    <Col xs={24} lg={0}>
                      <Button type="primary" size="large" block htmlType="submit">
                        {t(translations).submit}
                      </Button>
                    </Col>
                  </Row>
                </Col>

                <Col xs={0} lg={8}>
                  <TrainItinerary />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Modal
        centered
        closable={false}
        maskClosable={false}
        visible={priceUpdatedModal}
        footer={null}
        onCancel={() => setPriceUpdateModal(false)}
        width={400}
        getContainer={document.getElementById('app-container') as HTMLElement}
      >
        <ModalContent Icon={<PayIcon />} title={t(translations).priceUpdateTitle}>
          {ticketReservation && (
            <>
              {PriceUpdatedContent}
              {PriceUpdateButtons}
            </>
          )}
        </ModalContent>

      </Modal>

      <StyledDrawer
        visibility={priceUpdatedDialog}
        onClose={() => setPriceUpdateDialog(false)}
        footer={PriceUpdateButtons}
      >
        <ModalContent bordered Icon={<PayIcon />} title={t(translations).priceUpdateTitle}>
          {PriceUpdatedContent}
        </ModalContent>
      </StyledDrawer>
    </>
  );
}

export default TrainPassengers;
