import styled, { css } from 'styled-components';
import device from '../../utils/mediaQuery';
import { PRIMARY } from '../../utils/colors';

export const ContentWrapper = styled.div`
  padding: 0 20px;

  @media ${device.large} {
    padding: 0 0 20px;
  }
`;

export const PillWrapper = styled.div<{ filled?: boolean}>`
  display: flex;
  column-gap: 10px;
  margin: 20px 0;

  ${({ filled }) => filled && css`
    background: #fff;
    padding: 9px;
    border-radius: 5px;
    display: inline-flex;
  `}
`;

export const PillItemWrapper = styled.div<{ active?: boolean, filled?: boolean }>`
  padding: 15px 20px;
  border-radius: 5px;
  text-align: center;
  transition: all 0.2s ease-in;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;
  
  ${({ filled }) => filled && css`
    padding: 15px;
    flex: 1;
    background: #fff;
  `}

  ${({ active }) => active && css`
    background: ${PRIMARY};
    color: #fff;
  `}
`;

export const TripModeWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  a {
    color: inherit;
  }
  
  ${PillItemWrapper} {
    @media ${device.large} {
      padding: 15px 60px;
    }
  }
`;
