import React from 'react';
import {
  Col, Row, Skeleton, Space,
} from 'antd';
import {
  ColumnItem,
  RowWrapper,
  SeeMoreWrapper,
  StyledDivider,
} from './styles';
import { CentralizedContent } from '../CustomAntdComponents/Utitlities';

function SkeletonLayout() {
  return (
    <RowWrapper>
      <Row gutter={4}>
        <Col xs={24} lg={8} style={{ display: 'flex' }}>
          <ColumnItem index={1}>
            <Row align="middle">
              <Col xs={0} lg={24}>
                <Space direction="vertical">
                  <Skeleton.Image style={{ height: '50px' }} />
                  <Skeleton active paragraph={{ rows: 0 }} />
                </Space>
              </Col>
              <Col xs={12} lg={0}>
                <div>&nbsp;</div>
              </Col>
            </Row>
          </ColumnItem>
        </Col>
        <Col xs={24} lg={8} style={{ display: 'flex' }}>
          <ColumnItem index={2}>
            <Skeleton active paragraph={{ rows: 2 }} />
          </ColumnItem>
        </Col>
        <Col xs={24} lg={8} style={{ display: 'flex' }}>
          <ColumnItem index={3}>
            <Row>
              {/* >= LG devices */}
              <Col xs={0} lg={24}>
                <Space direction="vertical" size="small">
                  <CentralizedContent>
                    <Skeleton.Button active shape="square" block />
                    <Skeleton active paragraph={{ rows: 0 }} />
                  </CentralizedContent>
                  <Skeleton active paragraph={{ rows: 0 }} />
                </Space>
              </Col>

              <Col xs={24} lg={0}>

                <SeeMoreWrapper>
                  <Skeleton.Button active shape="square" block />
                </SeeMoreWrapper>

                <StyledDivider />
              </Col>
            </Row>
          </ColumnItem>
        </Col>
      </Row>
    </RowWrapper>
  );
}

export default SkeletonLayout;
