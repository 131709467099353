/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Card, Space, Button, Typography,
} from 'antd';
import moment from 'moment';
import TripSummary from '../TripSummary';
import { StyledParagraph, StyledText } from '../CustomAntdComponents/Typography';
import {
  DetailsWrapper, HeaderTileWrapper, HighlightDetailItem, TitleWrapper,
} from './styles';
import { SpaceBetweenAlignContent, VerticalAlignContent } from '../CustomAntdComponents/Utitlities';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import { useFlightSearchStore } from '../../store/flightSearch/flightSearchStore';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { calculateFlightTotalFare, getAirlineLogo } from '../AvailableFlights/helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';
import globalTranslations from '../../utils/globalTranslation';
import TripSummaryMoreDetails from '../TripSummary/TripSummaryMoreDetails';
import { TripCountWrapper } from '../AvailableFlights/styles';
import { DetailItem, DetailItemIcon, DetailItemsWrapper } from '../TripSummary/styles';
import { ReactComponent as PassengerIcon } from '../../assets/images/passenger-color.svg';

type Props = {
  showSubmitButton?: boolean;
  showTotalAmount?: boolean
};
const { Title, Paragraph } = Typography;
function FlightItinerary({ showSubmitButton, showTotalAmount }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    selectedFlight,
    formData: {
      tripType,
      flightType,
    },
  } = useFlightSearchStore();

  const isRoundTrip = tripType === 'round_trip';
  const isDomestic = flightType === 'domestic';
  const isMultiCity = tripType === 'multi_city';
  const repeatedArray = Array.from({ length: 3 }, () => selectedFlight[0].FlightDetails[0]);
  // const departureItinerary = !isMultiCity ? repeatedArray : [selectedFlight[0].FlightDetails[0]];
  // uncomment later2
  const departureItinerary = isMultiCity ? selectedFlight[0].FlightDetails : [selectedFlight[0].FlightDetails[0]];

  // const returnItinerary = [selectedFlight[0].FlightDetails[1]];
  const { totalTicketAmount } = calculateFlightTotalFare(selectedFlight, isDomestic);
  const {
    formData,
  } = useFlightSearchStore();

  const renderFlightDetails = (
    flightDetails: FlightDetails,
    ticketAmount: number,
    currency: string,
    returning = false,
    index = 0,
  ) => (
    <>
      <Space
        style={{ width: '100%' }}
        direction="vertical"
        size="middle"
        key={`flightitinery${index}`}
      >
        <Card size="small" style={{ marginBottom: '10px' }}>
          {isMultiCity && (
            <TripCountWrapper>
              {' '}
              Trip
              {' '}
              {index + 1}
            </TripCountWrapper>
          )}
          <TripSummaryMoreDetails
            showChangeButton={false}
            returning={returning}
            itinerary
            DestinationLocationCode={flightDetails.ArrivalAirportCode}
            OriginLocationCode={flightDetails.DepartureAirportCode}
            DepartureDate={flightDetails.DepartureDate}
            ReturnDate={flightDetails.ArrivalDate}
            flightDetails={flightDetails}
          />
        </Card>
        {/* {isMultiCity && multiCity?.length > 0 ? multiCity.map((item, index) => (
            <TripSummaryMoreDetails
              showChangeButton={false}
              returning={returning}
              itinerary
              DestinationLocationCode={item.DestinationLocationCode}
              OriginLocationCode={item.OriginLocationCode}
              DepartureDate={item.DepartureDate}
              key={`TripItinerydetails${index}`}
              flightDetails={flightDetails}
            />
          ))

            : (
              <TripSummaryMoreDetails
                showChangeButton={false}
                returning={returning}
                itinerary
                DestinationLocationCode={flightDetails.ArrivalAirportCode}
                OriginLocationCode={flightDetails.DepartureAirportCode}
                DepartureDate={flightDetails.DepartureDate}
                ReturnDate={flightDetails.ArrivalDate}
                flightDetails={flightDetails}
              />
            ) } */}
      </Space>

      {/* <DetailsWrapper>
          <HighlightDetailItem>
            <SpaceBetweenAlignContent>
              <StyledText size="small">
                {moment(startFlightEntry.DepartureDate).format('ddd, DD MMM')}
              </StyledText>
              <StyledText size="small" strong>
                {moment(startFlightEntry.DepartureDate).format('hh:mm a')}
              </StyledText>
            </SpaceBetweenAlignContent>
          </HighlightDetailItem>

          <VerticalAlignContent>
            <StyledText size="small">
              {
                flightDetails.StopOvers > 0
                && pluralizeText(flightDetails.StopOvers, t(translations).stop, t(translations).stops)
              }
            </StyledText>
            <VerticalAlignContent>
              <ClockIcon />
              <StyledText size="small">
                {flightDetails.Duration}
              </StyledText>
            </VerticalAlignContent>
          </VerticalAlignContent>
        </DetailsWrapper> */}

      {/* TODO Determine for International  */}
      {/* {isDomestic && (
        <SpaceBetweenAlignContent>
          <StyledText size="small">{t(translations).ticketFare}</StyledText>
          <StyledText
            size="medium"
            strong
          >
            {formatNumberAsCurrency(ticketAmount, currency)}
          </StyledText>
        </SpaceBetweenAlignContent>
      )} */}
    </>
  );

  const processDomesticReturn = () => selectedFlight
    && renderFlightDetails(
      selectedFlight[1].FlightDetails[0],
      selectedFlight[1].TotalFare - selectedFlight[1].TotalServiceCharge,
      selectedFlight[1].Currency,
      true,
    );

  const processInterationalReturn = () => {
    const flightDetail = selectedFlight ? selectedFlight[0].FlightDetails[1] : null;
    return (
      flightDetail
      && selectedFlight
      && renderFlightDetails(
        flightDetail,
        selectedFlight[0].TotalFare - selectedFlight[0].TotalServiceCharge,
        selectedFlight[0].Currency,
        true,
      )
    );
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ width: '100%' }}
    >
      <>

        {!showSubmitButton && (
        <HeaderTileWrapper>

          <StyledParagraph>
            {' '}
            {t(translations).totalPassenger}
          </StyledParagraph>

          <DetailItemsWrapper>
            <DetailItem>
              <DetailItemIcon>
                <PassengerIcon />
              </DetailItemIcon>
              <Paragraph style={{ textAlign: 'right', marginBottom: '0px' }} strong>
                <span>
                  {pluralizeText(
                    formData.numberOfAdults,
                    t(globalTranslations).passengerSelector.adult,
                    t(globalTranslations).passengerSelector.adults,
                  )}
                      &nbsp;&nbsp;
                </span>

                {Boolean(formData.numberOfChildren) && (
                <span>
                  {pluralizeText(
                    formData.numberOfChildren,
                    t(globalTranslations).passengerSelector.child,
                    t(globalTranslations).passengerSelector.children,
                  )}
                      &nbsp;&nbsp;
                </span>
                )}

                {Boolean(formData.numberOfInfants) && (
                <span>
                  {pluralizeText(
                    formData.numberOfInfants,
                    t(globalTranslations).passengerSelector.infant,
                    t(globalTranslations).passengerSelector.infants,
                  )}
                      &nbsp;&nbsp;
                </span>
                )}
              </Paragraph>
            </DetailItem>
          </DetailItemsWrapper>
        </HeaderTileWrapper>

        ) }

        {/* <Card size="default"> */}

        <TitleWrapper>
          <StyledParagraph
            strong
            color="secondary"
          >
            {t(translations).departure}
          </StyledParagraph>
        </TitleWrapper>
        {selectedFlight
          && departureItinerary
          && departureItinerary?.map((flightDetails: FlightDetails, index) => renderFlightDetails(
            flightDetails,
            selectedFlight[0].TotalFare - selectedFlight[0].TotalServiceCharge,
            selectedFlight[0].Currency,
            false,
            index,
          ))}
        {/* {
          selectedFlight && renderFlightDetails(
            selectedFlight[0].FlightDetails[0],
            (selectedFlight[0].TotalFare - selectedFlight[0].TotalServiceCharge),
            selectedFlight[0].Currency,
          )
        } */}
        {/* </Card> */}
      </>

      {isRoundTrip && selectedFlight && (
        <>
          <TitleWrapper>
            <StyledParagraph
              strong
              color="secondary"
            >
              {t(translations).return}
            </StyledParagraph>
          </TitleWrapper>

          {isDomestic && processDomesticReturn()}
          {!isDomestic && processInterationalReturn()}
        </>
      )}

      {selectedFlight && showTotalAmount && (
        <Card size="default">
          <HighlightDetailItem>
            <SpaceBetweenAlignContent>
              <StyledText
                size="small"
                color="secondary"
              >
                {t(translations).totalAmount}
              </StyledText>
              <StyledText strong>
                {formatNumberAsCurrency(totalTicketAmount || 0, selectedFlight[0]?.Currency)}
              </StyledText>
            </SpaceBetweenAlignContent>
          </HighlightDetailItem>
          {showSubmitButton && (
            <Button
              type="primary"
              htmlType="submit"
              block
            >
              {t(translations).bookFlight}
            </Button>
          )}
        </Card>
      )}
    </Space>
  );
}

FlightItinerary.defaultProps = {
  showSubmitButton: true,
  showTotalAmount: true,
};
export default FlightItinerary;
