import React from 'react';
import { Card, Typography, Space } from 'antd';
import { ItemsWrapper, Wrapper } from './styles';
import { StyledParagraph } from '../CustomAntdComponents/Typography';
import { formatNumberAsCurrency } from '../../utils/helpers';
import { GiveMeSpace } from '../CustomAntdComponents/Utitlities';
import { useWaterSearchStore } from '../../store/waterSearch/waterSearchStore';
import { calculateTotalFare } from '../AvailableBoats/helper';
import translations from './translations';
import { useI18nContext } from '../../utils/I18n';

const { Paragraph } = Typography;

function WaterPaymentSummary() {
  const {
    state: { t },
  } = useI18nContext();
  const {
    selectedResult,
    selectedBoat,
  } = useWaterSearchStore();

  const totalFare = calculateTotalFare(selectedBoat ? selectedBoat.PassengerFares : []);

  return (
    <Wrapper>
      <Space direction="vertical" size="small">
        <Paragraph strong>{t(translations).summary}</Paragraph>
        <GiveMeSpace size="small" />
        <Card>
          <ItemsWrapper>
            <StyledParagraph color="secondary" size="small" strong>
              {t(translations).ticketAmount}
            </StyledParagraph>

            <StyledParagraph strong>
              {formatNumberAsCurrency(totalFare.ticketAmount, selectedResult?.Currency)}
            </StyledParagraph>
          </ItemsWrapper>

          <ItemsWrapper>
            <StyledParagraph color="secondary" size="small" strong>
              {t(translations).convenience}
            </StyledParagraph>

            <StyledParagraph strong>
              {formatNumberAsCurrency(totalFare.totalCharges, selectedResult?.Currency)}
            </StyledParagraph>
          </ItemsWrapper>
        </Card>

        <Card size="small">
          <ItemsWrapper>
            <StyledParagraph color="primary" size="small" strong>
              {t(translations).totalAmount}
            </StyledParagraph>

            <StyledParagraph strong size="medium">
              {formatNumberAsCurrency(totalFare.totalAmount, selectedResult?.Currency)}
            </StyledParagraph>
          </ItemsWrapper>
        </Card>
      </Space>
    </Wrapper>
  );
}

export default WaterPaymentSummary;
