import styled, { css } from 'styled-components';
import { PRIMARY } from '../../../utils/colors';

export const Wrapper = styled.section<{ height?: number }>`
    svg {
        path {
            fill: ${PRIMARY};
        }
    }
    
  .trips-drawer {
    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }

    .ant-drawer-close {
      margin-right: 0;
    }

   .ant-drawer-content-wrapper {
      height: auto !important;
   }
      .ant-drawer-body {
          padding: 0 20px 10px;
      }
      
    ${({ height }) => height && css`
      .ant-drawer-content-wrapper {
        max-height: ${height ? `${height}px` : 'fit-content'}
      }
    `};
  }
`;
