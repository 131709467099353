import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import {
  Row, Col, Space, Button, Card,
} from 'antd';
import moment from 'moment';
import {
  ColumnItem,
  HeaderAirlineDetails,
  TextWrapper,
  RowWrapper,
  FlightDetailsWrapper,
  DetailTitleWrapper,
  DetailEntryWrapper,
  TripCountWrapper,
} from './styles';
import {
  StyledParagraph, StyledText,
} from '../CustomAntdComponents/Typography';

import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import { ReactComponent as FlightIcon } from '../../assets/images/flight.svg';
import {
  CentralizedContent,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { getAirlineLogo } from './helper';
import {
  formatNumberAsCurrency,
  pluralizeText,
} from '../../utils/helpers';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';
import TripSummaryMoreDetails from '../TripSummary/TripSummaryMoreDetails';

type Props = {
  result: AirlineResultItem,
  onBookingRedirect: (arg: AirlineResultItem[]) => void
}
function FlightTripDetails({
  result, onBookingRedirect,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const [viewDetails, setViewDetails] = useState(false);
  const flight = result.FlightDetails[0];
  const returnFlight = result.FlightDetails[1];
  const {
    formData: { flightType, tripType },
  } = useFlightSearchStore();
  const isRoundTrip = tripType === 'round_trip';
  const isDomestic = flightType === 'domestic';
  const isMultiCity = tripType === 'multi_city';
  const departureItinerary = isMultiCity ? result.FlightDetails : [result.FlightDetails[0]];
  const returnItinerary = [result.FlightDetails[1]];

  //   const renderEntryDetails = (entry: FlightEntry) => (
  //     <Col lg={8} key={entry.FlightNumber}>
  //       <Card size="small">
  //         <Space direction="vertical" size="large" style={{ marginTop: '0' }}>
  //           <StyledParagraph>
  //             {moment(entry.DepartureDate).format('ddd MMM DD')}
  //             &nbsp;&nbsp;
  //             <StyledText strong>{moment(entry.DepartureDate).format('hh:mm a')}</StyledText>
  //           </StyledParagraph>

  //           <div>
  //             <VerticalAlignContent>
  //               <StyledParagraph>
  //                 {t(translations).layover}
  //                 :
  //               </StyledParagraph>
  //               <ClockIcon />
  //               <StyledText strong color="primary">{entry.TotalDuration}</StyledText>
  //               <br />
  //             </VerticalAlignContent>
  //             <StyledParagraph size="large">
  //               {entry.DepartureAirportName}
  //             </StyledParagraph>
  //           </div>

  //           <VerticalAlignContent>
  //             <StyledParagraph>
  //               {entry.DepartureAirportCode}
  // &nbsp;
  //             </StyledParagraph>
  //             <FlightIcon />
  //             <StyledParagraph>
  //               &nbsp;
  //               {entry.ArrivalAirportCode}
  //             </StyledParagraph>
  //           </VerticalAlignContent>
  //         </Space>
  //       </Card>
  //     </Col>
  //   );

  const renderEntryDetails = (flightDetails: FlightDetails, index) => (
    <Col lg={12} key={`${flightDetails.ArrivalAirportCode}${index}`} style={{ marginBottom: '10px' }}>
      <Card size="small">

        {isMultiCity && (
        <TripCountWrapper>
          {' '}
          Trip
          {' '}
          {index + 1}
        </TripCountWrapper>
        )}
        <TripSummaryMoreDetails
          showChangeButton={false}
          itinerary
          DestinationLocationCode={flightDetails.ArrivalAirportCode}
          OriginLocationCode={flightDetails.DepartureAirportCode}
          DepartureDate={flightDetails.DepartureDate}
          ReturnDate={flightDetails.ArrivalDate}
          flightDetails={flightDetails}
        />

      </Card>
    </Col>
  );
  const displatFlightRouteIcons = (flightDetail:FlightDetails) => (
    <Row align="middle" justify="center" gutter={4} style={{ padding: '15px  10px' }}>

      <Col>
        <StyledParagraph>
          {flight.DepartureAirportCode}
        </StyledParagraph>
      </Col>
      <Col>
        <FlightIcon />
      </Col>
      <Col>
        <StyledParagraph>
          {flightDetail.ArrivalAirportCode}
        </StyledParagraph>
      </Col>
    </Row>
  );

  const ticketAmount = isDomestic ? result.TotalFare - result.TotalServiceCharge : result.TotalFare;
  const totalFlightDuration = result?.TotalFlightDuration;
  const isLayOverExist = result.FlightDetails.filter((item: FlightDetails) => item.StopOvers > 0);

  return (
    <RowWrapper key={result.Properties.RecommendationID}>
      <Row gutter={4}>
        <Col xs={24} lg={6} style={{ display: 'flex' }}>
          <ColumnItem index={1}>
            <Row justify="space-between">
              <Col xs={12} lg={24}>
                <HeaderAirlineDetails>
                  <img src={getAirlineLogo(flight.OperatingAirlineCode)} alt={flight.OperatingAirlineCode} />
                  <StyledParagraph size="small">
                    {flight.OperatingAirlineName}
                  </StyledParagraph>
                </HeaderAirlineDetails>
              </Col>
              {!isMultiCity
              && (
              <Col lg={0}>
                {displatFlightRouteIcons(result.FlightDetails[0])}
              </Col>
              )}
            </Row>
          </ColumnItem>
        </Col>
        <Col xs={24} lg={12} style={{ display: 'flex' }}>
          <ColumnItem index={2}>
            <Row align="middle">
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {t(translations).departure}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph align="right" size="small">
                    {moment(flight.DepartureDate).format('Do MMMM, h:mma')}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {t(translations).arrival}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph align="right" size="small">
                    {moment(flight.ArrivalDate).format('Do MMMM, h:mma')}
                  </StyledParagraph>
                </TextWrapper>
              </Col>

              {/* <Col lg={12} xs={0}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {pluralizeText(flight.StopOvers, t(translations).stop, t(translations).stops)}
                  </StyledParagraph>
                </TextWrapper>
              </Col> */}
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {t(translations).duration}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph size="small" align="right">
                    <CentralizedContent style={{ justifyContent: 'flex-end' }}>
                      <ClockIcon />
                      &nbsp;
                      {totalFlightDuration}
                    </CentralizedContent>
                  </StyledParagraph>
                </TextWrapper>
              </Col>

              <Col span={12}>
                <TextWrapper>
                  <StyledText type="secondary" color="primary">
                    {/* {pluralizeText(flight.StopOvers, t(translations).stop, t(translations).stops)} */}
                    {isLayOverExist.length > 0 ? 'With stops' : 'Direct'}
                  </StyledText>
                </TextWrapper>
              </Col>

            </Row>
          </ColumnItem>
        </Col>
        <Col xs={24} lg={6} style={{ display: 'flex' }}>
          <ColumnItem index={3}>
            <Row>
              {/* >= LG devices */}
              <Col xs={0} lg={24}>
                <Space direction="vertical" size="small">
                  <StyledParagraph strong align="center">
                    {formatNumberAsCurrency(ticketAmount, result.Currency)}
                  </StyledParagraph>
                  <CentralizedContent>
                    {
                      // (!viewDetails && (flight.StopOvers > 0 || (returnFlight && returnFlight.StopOvers > 0)))
                      //   ? (
                      //     <Button type="primary" onClick={() => setViewDetails(true)}>
                      //       {t(translations).view}
                      //     </Button>
                      //   )
                      //   : (
                      //     <Button type="primary" onClick={() => onBookingRedirect([result])}>
                      //       {t(translations).bookFlight}
                      //     </Button>
                      //   )
                      !viewDetails
                        ? (
                          <Button type="primary" onClick={() => setViewDetails(true)}>
                            {t(translations).view}
                          </Button>
                        )
                        : (
                          <Button type="primary" onClick={() => onBookingRedirect([result])}>
                            {t(translations).bookFlight}
                          </Button>
                        )
                    }

                  </CentralizedContent>
                </Space>
              </Col>

              {/* <Col xs={12} lg={0}>
                <TextWrapper>
                  <StyledParagraph strong size="small">
                    <VerticalAlignContent>
                      <ClockIcon />
                      &nbsp;
                      {flight.Duration}
                    </VerticalAlignContent>
                  </StyledParagraph>
                </TextWrapper>
              </Col> */}
              <Col xs={24} lg={0}>
                <TextWrapper style={{ marginBottom: '20px' }}>
                  <StyledParagraph strong align="right">
                    {formatNumberAsCurrency(ticketAmount, result.Currency)}
                  </StyledParagraph>
                </TextWrapper>
              </Col>

              <Col xs={24} lg={0}>

                {/* {
                      !viewDetails && isLayOverExist.length > 0
                        ? (
                          <Button type="primary" onClick={() => setViewDetails(true)} block style={{ marginBottom: '10px' }}>
                            {t(translations).view}
                          </Button>
                        )
                        : (
                          <Button type="primary" onClick={() => onBookingRedirect([result])} block style={{ marginBottom: '10px' }}>
                            {t(translations).bookFlight}
                          </Button>
                        )
                    } */}

                <Button type="primary" onClick={() => onBookingRedirect([result])} block style={{ marginBottom: '10px' }}>
                  {t(translations).view}
                </Button>
              </Col>
            </Row>
          </ColumnItem>
        </Col>
      </Row>

      { viewDetails && (
      <FlightDetailsWrapper>
        <DetailTitleWrapper>
          <Row justify="space-between">
            <Col>
              <StyledParagraph>
                {t(translations).departure}
              </StyledParagraph>

            </Col>

            <Col>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setViewDetails(false);
                }}
              />
            </Col>
          </Row>
        </DetailTitleWrapper>

        <DetailEntryWrapper>
          <Row justify="space-between" gutter={8}>
            {departureItinerary.map((flightDetails: FlightDetails, index) => (
              renderEntryDetails(flightDetails, index)
            ))}
          </Row>
        </DetailEntryWrapper>

        { isRoundTrip && returnItinerary && (
        <>
          <DetailTitleWrapper>
            <StyledParagraph>
              {t(translations).return}
            </StyledParagraph>
          </DetailTitleWrapper>
          <DetailEntryWrapper>
            <Row gutter={16}>
              {returnItinerary.map((flightDetails : FlightDetails, index) => (
                renderEntryDetails(flightDetails, index)
              ))}

            </Row>
          </DetailEntryWrapper>
        </>
        )}

      </FlightDetailsWrapper>
      )}

    </RowWrapper>
  );
}

export default FlightTripDetails;
