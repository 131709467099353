import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import { FONT_SIZE, LIGHT_PRIMARY, PRIMARY } from '../../utils/colors';

export const Wrapper = styled.section`
  background: linear-gradient(279.27deg, ${PRIMARY} 1.45%, ${LIGHT_PRIMARY} 87.41%);
  padding: 15px;
  min-height: 68px;
  
  h4 {
    color: #fff;
  }


  @media ${device.large} {
    min-height: 78px;
    background: #fff;
  }
`;

export const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px #EF8833;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.large} {
    justify-content: flex-end;
    column-gap: 20px;
  }
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  
  h4 {
    margin: 0 10px;
  }
`;

export const FormWrapper = styled.div`
  margin-top: 40px;
`;
