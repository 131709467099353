export default {
  en: {
    title: 'Road Listings',
    listingsTitle: 'Available Buses',
    changeButton: 'Change',
    filterTitle: 'Filter',
    allTransports: 'All Transports',
    transport: 'Transport',
    transports: 'Transports',
    stops: 'Stops',
    stop: 'Stop',
    price: 'Price',
    listings: 'listings',
    departure: 'Departure',
    arrival: 'Arrival',
    return: 'Return',
    book: 'Book Ride',
    cheapest: 'Cheapest',
    expensive: 'Expensive',
    view: 'View Details',
    layover: 'Layover',
    emptyMessage: 'No bus matches your search',
    startingPrice: 'Starting Price',
    ride: 'Ride',
    rides: 'Rides',
    rideListing: 'Ride Listing',
    ridesListing: 'Rides Listings',
    seeMore: 'See more',
    seater: 'seater',
    seatAvailable: 'seat available',
    seatsAvailable: 'seats available',
    fareRule: {
      title: 'Terms and Condition',
      text: 'To continue. You have to agree with these statutes',
      acceptButton: 'I Accept',
    },
    totalAmount: 'Total Amount',
    ticketFare: 'Ticket Fare',
    serviceFee: 'Service Fee',
    returnFare: 'Return Fare',
    departureFare: 'Departure Fare',
    billingBreakdown: 'Billing Breakdown',
  },
  fr: {
    title: 'Liste des routes',
    listingsTitle: 'Autobus disponibles',
    changeButton: 'Changer',
    filterTitle: 'Filtrer',
    allTransports: 'Tous les transports',
    transport: 'Transport',
    transports: 'Transports',
    stops: 'Arrêts',
    stop: 'Arrêt',
    price: 'Prix',
    listings: 'listings',
    departure: 'Départ',
    arrival: 'Arrivée',
    return: 'Retour',
    book: 'Carrousel de livres',
    cheapest: 'Le moins cher',
    expensive: 'Plus cher',
    view: 'Voir les détails',
    layover: 'Escale',
    emptyMessage: 'Aucun vol ne correspond à votre recherche',
    startingPrice: 'Prix de départ',
    ride: 'Trajet',
    rides: 'Trajets',
    rideListing: 'Liste des manèges',
    ridesListing: 'Listes de manèges',
    seeMore: 'Voir plus',
    seater: 'siège',
    seatAvailable: 'asiento disponible',
    seatsAvailable: 'asientos disponibles',
    fareRule: {
      title: 'Conditions générales',
      text: 'Pour continuer. Vous devez accepter ces statuts',
      acceptButton: 'J\'accepte',
    },
    totalAmount: 'Montant total',
    ticketFare: 'Tarif du billet',
    serviceFee: 'Frais de service',
    returnFare: 'Tarif de retour',
    departureFare: 'Tarif de départ',
    billingBreakdown: 'Ventilation de la facturation',
  },
  sp: {
    title: 'Listados de carreteras',
    listingsTitle: 'Vuelo disponible',
    changeButton: 'CambiarCambiar',
    filterTitle: 'Filtrar',
    allTransports: 'Todos los transportes',
    transport: 'Transporte',
    transports: 'Transportes',
    stops: 'Paradas',
    stop: 'Parada',
    price: 'Precio',
    listings: 'listados',
    departure: 'Salida',
    arrival: 'Llegada',
    return: 'Regreso',
    book: 'Paseo del libro',
    cheapest: 'Más barato',
    expensive: 'Más caro',
    view: 'Ver detalles',
    layover: 'Escala',
    emptyMessage: 'Ningún vuelo coincide con su búsqueda',
    startingPrice: 'Precio inicial',
    ride: 'Ir a',
    rides: 'Viajes',
    rideListing: 'Listado de paseos',
    ridesListing: 'Listado de paseos',
    seeMore: 'Ver más',
    seater: 'asiento',
    seatAvailable: 'siège disponible',
    seatsAvailable: 'sièges disponible',
    fareRule: {
      title: 'Términos y condiciones',
      text: 'Para continuar. Tienes que estar de acuerdo con estos estatutos',
      acceptButton: 'Acepto',
    },
    totalAmount: 'Importe total',
    ticketFare: 'Tarifa del billete',
    serviceFee: 'Tarifa de servicio',
    returnFare: 'Tarifa de ida y vuelta',
    departureFare: 'Tarifa de salida',
    billingBreakdown: 'Desglose de la facturación',
  },
  sw: {
    title: 'Orodha za Barabara',
    listingsTitle: 'Mabasi Yanayopatikana',
    changeButton: 'Badilika',
    filterTitle: 'Chuja',
    allTransports: 'Usafiri Wote',
    transport: 'Usafiri',
    transports: 'Usafirishaji',
    stops: 'Inasimama',
    stop: 'Acha',
    price: 'Bei',
    listings: 'orodha',
    departure: 'Kuondoka',
    arrival: 'Kuwasili',
    return: 'Rudi',
    book: 'Book Ride',
    cheapest: 'Nafuu zaidi',
    expensive: 'Ghali',
    view: 'Tazama Maelezo',
    layover: 'Layover',
    emptyMessage: 'Hakuna basi linalolingana na utafutaji wako',
    startingPrice: 'Bei ya Kuanzia',
    ride: 'Panda',
    rides: 'Wapanda',
    rideListing: 'Orodha ya wapanda farasi',
    ridesListing: 'Orodha ya wapanda farasi',
    seeMore: 'Tazama zaidi',
    seater: 'kiti',
    seatAvailable: 'kiti kinapatikana',
    seatsAvailable: 'viti vinavyopatikana',
    fareRule: {
      title: 'Masharti na Masharti',
      text: 'Ili kuendelea. Lazima ukubaliane na sheria hizi',
      acceptButton: 'Nakubali',
    },
    totalAmount: 'Jumla',
    ticketFare: 'Nauli ya Tiketi',
    serviceFee: 'Ada ya Huduma',
    returnFare: 'Nauli ya Kurudi',
    departureFare: 'Nauli ya Kuondoka',
    billingBreakdown: 'Uchanganuzi wa Malipo',
  },
};
