import React from 'react';
import {
  Typography, Row, Col, Form,
} from 'antd';
import {
  SelectLabelWrapper,
  SelectWrapper,
  TitleWrapper,
  Wrapper,
} from '../PassegerSelector/styles';
import passengerIcon from '../../assets/images/passenger.svg';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import {
  getAdultsOptions, getChildrenOptions, getInfantOptions,
  // getChildrenOptions,
} from '../PassegerSelector/helper';
import { StyledText } from '../CustomAntdComponents/Typography';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import globalTranslations from '../../utils/globalTranslation';
import { useI18nContext } from '../../utils/I18n';

type Props = {
  showTitle?: boolean
}
const { Text } = Typography;

function PassengerCountSelector({ showTitle }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData: {
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
    }, updateFormData,
  } = useRoadSearchStore();

  const onFormInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const adultOptions = getAdultsOptions(t(globalTranslations).passengerSelector.adults, t(globalTranslations).passengerSelector.adult);
  const childrenOptions = getChildrenOptions(t(globalTranslations).passengerSelector.children, t(globalTranslations).passengerSelector.child);
  const infantOptions = getInfantOptions(t(globalTranslations).passengerSelector.infants, t(globalTranslations).passengerSelector.infant);

  return (
    <Wrapper>
      {showTitle && (
      <TitleWrapper>
        <img src={passengerIcon} alt="passenger" />
        <Text strong>
          &nbsp;&nbsp;
          {t(globalTranslations).passengerSelector.title}
        </Text>
      </TitleWrapper>
      )}

      <Row>
        <Col xs={24} lg={0}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="numberOfAdults"
                rules={[
                  {
                    required: true,
                    message: t(globalTranslations).passengerSelector.adultValidationText,
                  },
                ]}
              >
                <Row>
                  <Col xs={16} md={18}>
                    <SelectLabelWrapper>
                      <StyledText type="secondary">
                        {t(globalTranslations).passengerSelector.adults}
                      </StyledText>
                    </SelectLabelWrapper>
                  </Col>
                  <Col xs={8} md={6}>
                    <SelectWrapper>
                      <StyledSelect
                        placeholder={t(globalTranslations).passengerSelector.adults}
                        value={numberOfAdults}
                        options={adultOptions}
                        onChange={(value) => onFormInputChange(value, 'numberOfAdults')}
                      />
                    </SelectWrapper>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item>
                <Row>
                  <Col xs={16} md={18}>
                    <SelectLabelWrapper>
                      <StyledText type="secondary">
                        { t(globalTranslations).passengerSelector.children}
                      </StyledText>
                    </SelectLabelWrapper>
                  </Col>
                  <Col xs={8} md={6}>
                    <SelectWrapper>
                      <StyledSelect
                        placeholder={t(globalTranslations).passengerSelector.children}
                        value={numberOfChildren}
                        options={childrenOptions}
                        onChange={(value) => onFormInputChange(value, 'numberOfChildren')}
                      />
                    </SelectWrapper>
                  </Col>
                </Row>

              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item>
                <Row>
                  <Col xs={16} md={18}>
                    <SelectLabelWrapper>
                      <StyledText type="secondary">
                        { t(globalTranslations).passengerSelector.infants}
                      </StyledText>
                    </SelectLabelWrapper>
                  </Col>
                  <Col xs={8} md={6}>
                    <SelectWrapper>
                      <StyledSelect
                        placeholder={t(globalTranslations).passengerSelector.infants}
                        value={numberOfInfants}
                        options={infantOptions}
                        onChange={(value) => onFormInputChange(value, 'numberOfInfants')}
                      />
                    </SelectWrapper>
                  </Col>
                </Row>

              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={0} lg={24}>
          <Form.Item
            name="numberOfAdults"
            rules={[
              {
                required: true,
                message: t(globalTranslations).passengerSelector.adultValidationText,
              },
            ]}
          >
            <StyledSelect
              placeholder={t(globalTranslations).passengerSelector.adults}
              value={numberOfAdults}
              options={adultOptions}
              onChange={(value) => onFormInputChange(value, 'numberOfAdults')}
            />
          </Form.Item>

          <Form.Item>
            <StyledSelect
              placeholder={t(globalTranslations).passengerSelector.children}
              value={numberOfChildren}
              options={childrenOptions}
              onChange={(value) => onFormInputChange(value, 'numberOfChildren')}
            />
          </Form.Item>

          <Form.Item>
            <StyledSelect
              placeholder={t(globalTranslations).passengerSelector.infants}
              value={numberOfInfants}
              options={infantOptions}
              onChange={(value) => onFormInputChange(value, 'numberOfInfants')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>

  );
}

PassengerCountSelector.defaultProps = {
  showTitle: true,
};
export default PassengerCountSelector;
