import styled from 'styled-components';
import device from '../../utils/mediaQuery';

export const Wrapper = styled.div`
  padding: 0 20px;
`;

export const ContentWrapper = styled.div`
  @media ${device.large} {
    padding: 15px;
    background: #fff;
  }
`;

export const ActionWrapper = styled.div`
  button {
    margin: 30px 0 15px;
  }
`;

export const Space = styled.div`
  height: 25px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
`;
