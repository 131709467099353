import React, { useState } from 'react';
import { Typography } from 'antd';
import { PillItemWrapper, PillWrapper } from './styles';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';

const { Paragraph } = Typography;
const stops = [{
  id: 0,
  name: '0',
  value: 0,
}, {
  id: 1,
  name: '1',
  value: 1,
},
{
  id: 2,
  name: '2',
  value: 2,
}, {
  id: 3,
  name: '3',
  value: 3,
}];

type Props = {
  handleStopoverFiltering: (arg0: number | null) => void,
}
function StopOvers({ handleStopoverFiltering }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    selectedResult,
  } = useRoadSearchStore();
  const [stopType, setStopType] = useState<number | null>(null);

  const handleSetStopOver = (value: number | null) => {
    if (!selectedResult) {
      setStopType(value);
      handleStopoverFiltering(value);
    }
  };

  return (
    <div>
      <Paragraph strong>
        {t(translations).stops}
      </Paragraph>
      <PillWrapper>
        {stops.map((option) => (
          <PillItemWrapper
            disabled={Boolean(selectedResult)}
            active={stopType === option.value}
            onClick={() => handleSetStopOver(option.value)}
            key={option.id}
          >
            {option.name}
          </PillItemWrapper>
        ))}
      </PillWrapper>
    </div>
  );
}

export default StopOvers;
