import React from 'react';
import {
  Row, Col, Space,
} from 'antd';
import moment from 'moment';
import {
  ColumnItem,
  TextWrapper,
  Wrapper,
  RowWrapper,
  HeaderBusDetails,
} from './styles';
import {
  StyledParagraph, StyledText, StyledTitle,
} from '../CustomAntdComponents/Typography';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import {
  CentralizedContent, GiveMeSpace, SpaceBetweenAlignContent,
} from '../CustomAntdComponents/Utitlities';
import {
  getBusTypeImage,
  getRoadTransportLogo,
} from './helper';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchRoadListings/translations';

type Props = {
  listings: BusResultItem,
  onBookingRedirect: (arg: AvailableBus) => void
}
function OperatorBus({ listings, onBookingRedirect }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  return (
    <Wrapper>
      <Space direction="vertical" size="large">
        <StyledTitle level={4}>
          {t(translations).title}
          &nbsp;
        </StyledTitle>
        <Space size="large">
          <img src={getRoadTransportLogo(listings.AgentName)} alt="bus" height={60} />
          <StyledText color="secondary" strong>
            {`${pluralizeText(
              listings.AvailableBuses.length,
              t(translations).rideListing,
              t(translations).ridesListing,
            )}`}
          </StyledText>
        </Space>
      </Space>

      {listings.AvailableBuses.map((bus) => {
        const numberOfSeats = bus.AvailableSeats.length;
        const numberOfAvailableSeats = bus.AvailableSeats.filter((seat) => seat.IsAvailable).length;

        return (
          <RowWrapper key={bus.RecommendationId}>
            <Row gutter={4}>
              <Col xs={24} lg={8} style={{ display: 'flex' }}>
                <ColumnItem index={1}>
                  <Row align="middle" gutter={16}>
                    <Col xs={8} lg={24}>
                      <HeaderBusDetails>
                        <img src={getBusTypeImage(numberOfSeats)} alt="bus" />
                      </HeaderBusDetails>
                    </Col>
                    <Col xs={16} lg={0}>
                      <Space direction="vertical">
                        <StyledTitle level={3}>
                          {bus.BusType}
                          {' '}
                            &nbsp;
                          {`(${numberOfSeats} seater)`}
                        </StyledTitle>
                        <SpaceBetweenAlignContent>
                          <StyledParagraph type="secondary" strong size="large">
                            {formatNumberAsCurrency(listings.StartingPrice, listings.Currency)}
                          </StyledParagraph>

                          <CentralizedContent>
                            <ClockIcon />
                              &nbsp;
                            <StyledText strong>
                              {bus.Duration}
                            </StyledText>
                          </CentralizedContent>
                        </SpaceBetweenAlignContent>
                      </Space>
                      <GiveMeSpace />
                    </Col>
                  </Row>
                </ColumnItem>
              </Col>
              <Col xs={24} lg={8} style={{ display: 'flex' }}>
                <ColumnItem index={2}>
                  <Row align="middle">
                    <Col xs={0} lg={24}>
                      <StyledTitle level={4}>
                        {bus.BusType}
                        {' '}
&nbsp;
                        {`(${numberOfSeats} ${t(translations).seater})`}
                      </StyledTitle>
                      <GiveMeSpace size="medium" />
                    </Col>
                    <Col span={8}>
                      <TextWrapper>
                        <StyledParagraph type="secondary">
                          {t(translations).departure}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col span={8}>
                      <TextWrapper>
                        <StyledParagraph type="secondary" strong>
                          {
                            bus.BoardingDetails && `${bus.BoardingDetails.LandMark}, ${bus.BoardingDetails.Name}`
                          }
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col span={8}>
                      <TextWrapper>
                        <StyledParagraph align="right" size="small">
                          {moment(bus.EstimatedDepartureDate).format('hh:mm a')}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col span={8}>
                      <TextWrapper>
                        <StyledParagraph type="secondary">
                          {
                            (bus.DropOffDetails || bus.EstimatedArrivalDate) && t(translations).arrival
                          }
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col span={8}>
                      <TextWrapper>
                        <StyledParagraph type="secondary" strong>
                          {
                            bus.DropOffDetails && `${bus.DropOffDetails.LandMark}, ${bus.DropOffDetails.Name}`
                          }
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col span={8}>
                      <TextWrapper>
                        <StyledParagraph align="right" size="small">
                          {
                            bus.EstimatedArrivalDate && moment(bus.EstimatedArrivalDate).format('hh:mm a')
                          }
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>

                    <Col lg={12} xs={0}>
                      <TextWrapper>
                        <StyledParagraph color="primary">
                          {
                            pluralizeText(
                              numberOfAvailableSeats,
                              t(translations).seatAvailable,
                              t(translations).seatsAvailable,
                            )
                          }
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col lg={12} xs={0}>
                      <TextWrapper>
                        {
                          bus.Duration
                          && (
                          <StyledParagraph size="small" align="right">
                            <CentralizedContent style={{ justifyContent: 'flex-end' }}>
                              <ClockIcon />
                              &nbsp;
                              <StyledText strong>
                                {bus.Duration}
                              </StyledText>
                            </CentralizedContent>
                          </StyledParagraph>
                          )
                        }
                      </TextWrapper>
                    </Col>
                  </Row>
                </ColumnItem>
              </Col>
              <Col xs={24} lg={8} style={{ display: 'flex' }}>
                <ColumnItem index={3}>
                  <Row>
                    {/* >= LG devices */}
                    <Col xs={0} lg={24}>
                      <Space direction="vertical" size="small">
                        <StyledTitle level={4} align="center">
                          {formatNumberAsCurrency(listings.StartingPrice, listings.Currency)}
                        </StyledTitle>
                        <CentralizedContent>
                          <StyledButton padded="large" type="primary" onClick={() => onBookingRedirect(bus)}>
                            {t(translations).book}
                          </StyledButton>
                        </CentralizedContent>
                      </Space>
                    </Col>

                    <Col xs={24} lg={0}>
                      <GiveMeSpace size="small" />
                      <StyledButton size="large" type="primary" onClick={() => onBookingRedirect(bus)} block style={{ marginBottom: '10px' }}>
                        {t(translations).book}
                      </StyledButton>
                    </Col>
                  </Row>
                </ColumnItem>
              </Col>
            </Row>
          </RowWrapper>
        );
      })}
    </Wrapper>
  );
}

export default OperatorBus;
