/* eslint-disable @typescript-eslint/ban-ts-comment */
// API Routes
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// const staging = 'https://bsptrips.staging.vggdev.com/api/';
const test = 'https://bsptrips.test.vggdev.com/api/';

// @ts-ignore
export const BASE_API_URL = process.env.NODE_ENV === 'development' ? test : `${window.env?.baseApiUrl}/api/`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// console.log('window:', window.env);

export const VALIDATE_TOKEN_API_URL = 'account/validateToken';

// API Paths
export const SEARCH_DOMESTIC_FLIGHTS_URL = 'Flight/Domestic/SearchFlight';
export const SEARCH_INTERNATIONAL_FLIGHTS_URL = 'Flight/SearchFlight';
export const GET_DOMESTIC_AIRPORTS_API_URL = 'airport?countryCode=NG';
export const GET_INTERNATIONAL_AIRPORTS_API_URL = 'airport?isInternational=true';
export const CONFIRM_DOMESTIC_TICKET_URL = 'Flight/Domestic/ConfirmTicketPrice';
export const CONFIRM_TICKET_URL = 'Flight/ConfirmTicketPrice';
export const BOOK_DOMESTIC_TICKET_URL = 'Flight/Domestic/BookFlight';
export const BOOK_TICKET_URL = 'Flight/BookFlight';
export const SEARCH_RESERVATION_URL = 'Flight/MyReservation';
export const GET_FLIGHT_FARE_RULES = 'Flight/FlightRules';

// Bus Trips
export const GET_SUPPORTED_ROAD_ROUTES = 'Bus/GetSupportedRoutes';
export const SEARCH_BUS_ROUTES = 'Bus/SearchBusverbose';
export const ROAD_BOOKING_URL = 'Bus/BookBus';
export const ROAD_CONFIRM_TICKET_URL = 'Bus/ConfirmTicketPrice';
export const GET_ROAD_FARE_RULES = 'Bus/TravelRules';
export const SEARCH_ROAD_RESERVATION_URL = 'Bus/MyReservation';

export const APP_NAME = 'Bsp Trips';

export const SUPPORTED_LANGUAGES = ['sw', 'en', 'fr', 'sp'];
export const LANGUAGES_OPTIONS = [{ label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'Spanish', value: 'sp' },
  { label: 'Swahili', value: 'sw' },
];

export const EXTERNAL_PAYMENT_CHANNEL = 1;
export const TRIPS_PAYMENT_CHANNEL = 2;
