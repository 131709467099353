export const numberOfFlightText = (total: number) => (total === 1 ? 'flight' : 'flights');

export const getAirlineLogo = (code: string) => `https://images.kiwi.com/airlines/64x64/${code}.png`;

export const calculateFlightTotalFare = (selectedFlight: AirlineResultItem[] | null, isDomestic: boolean) => {
  if (!selectedFlight) {
    return { totalCharges: 0, totalTicketAmount: 0, totalAmount: 0 };
  }
  const totalAmount = selectedFlight.reduce((acc, item) => (acc + item.TotalFare), 0);
  const totalCharges = selectedFlight.reduce((acc, item) => (acc + item.TotalServiceCharge), 0);

  const totalTicketAmount = isDomestic ? totalAmount - totalCharges : totalAmount;
  return { totalCharges, totalTicketAmount, totalAmount };
};
