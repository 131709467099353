import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Col, Row,
} from 'antd';
import {
  NavImageIcon,
  NavWrapper,
  Wrapper,
} from './styles';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import Header from '../../components/Header';
import FlightReceipt from '../../components/ReceiptCard/FlightReceipt';
import RoadReceipt from '../../components/ReceiptCard/RoadReceipt';
import WaterReceipt from '../../components/ReceiptCard/WaterReceipt';
import TrainReceipt from '../../components/ReceiptCard/TrainReceipt';

function PaymentReceipt() {
  const { tripType } = useParams();

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>

      <div className="container">
        <Wrapper>
          <NavWrapper>
            <Link to="/">
              <NavImageIcon>
                <CloseIcon />
              </NavImageIcon>
            </Link>
          </NavWrapper>

          { tripType === 'flight' && <FlightReceipt />}
          { tripType === 'road' && <RoadReceipt />}
          { tripType === 'water' && <WaterReceipt />}
          { tripType === 'rail' && <TrainReceipt />}
        </Wrapper>
      </div>
    </>
  );
}

export default PaymentReceipt;
