import React, { useRef, useState } from 'react';
import {
  DatePicker,
} from 'antd';
import type { DatePickerProps } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';

import { SizeType } from 'antd/es/config-provider/SizeContext';
import type { Moment } from 'moment';
import { Wrapper } from './styles';
import calendarIcon from '../../../assets/images/calendar-grey.svg';

type Props = {
  placeholder?: string,
  size?: SizeType,
  value?: Moment,
  disabledDate?: RangePickerProps['disabledDate'],
  onChange?: (dateString: Moment | null) => void

}

function StyledDatePicker({
  size, placeholder, value, onChange, disabledDate,
}: Props) {
  const popoverContainerRef = useRef(null);

  const [focus, setFocus] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Moment | null>();

  const hasValue = focus || (selectedValue) || value;
  const labelClass = hasValue ? 'label as-label' : 'label as-placeholder';

  const suffixIcon = () => (<img src={calendarIcon} alt="calendar" />);

  const handleDateChange: DatePickerProps['onChange'] = (date) => {
    setSelectedValue(date);
    if (onChange) onChange(date);
  };

  const getContainer = () => popoverContainerRef.current as unknown as HTMLElement;

  return (
    <Wrapper
      ref={popoverContainerRef}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker
        size={size}
        placeholder=""
        suffixIcon={suffixIcon()}
        disabledDate={disabledDate}
        value={value}
        onChange={handleDateChange}
        inputReadOnly
        getPopupContainer={getContainer}
      />
      <span className={labelClass}>
        {hasValue ? placeholder : placeholder}
      </span>
    </Wrapper>
  );
}

StyledDatePicker.defaultProps = {
  placeholder: '',
  size: 'large',
  value: undefined,
  disabledDate: undefined,
  onChange: undefined,
};
export default StyledDatePicker;
