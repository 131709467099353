import React from 'react';
import {
  Row, Col, Space, Button,
} from 'antd';
import moment from 'moment';
import {
  ColumnItem,
  TextWrapper,
  Wrapper,
  RowWrapper,
  AirlineDataWrapper,
  HeaderAirlineDetails,
} from './styles';
import {
  StyledParagraph, StyledText, StyledTitle,
} from '../CustomAntdComponents/Typography';

import clockIcon from '../../assets/images/clock.svg';
import {
  CentralizedContent,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { getAirlineLogo, numberOfFlightText } from './helper';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';

type Props = {
  listings: FlightResultItem,
  onBookingRedirect: (arg: AirlineResultItem[]) => void
}
function AirlineFlights({ listings, onBookingRedirect }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const flightOptions = listings.ResultList;
  const airline = listings.ResultList[0].FlightDetails[0];
  return (
    <Wrapper>
      <VerticalAlignContent>
        <StyledTitle level={5}>
          {t(translations).filterTitle}
          &nbsp;
        </StyledTitle>
        <StyledText size="small" color="secondary" strong>
          &nbsp;
          {`${listings.TotalCount} ${numberOfFlightText(listings.TotalCount)} ${t(translations).listings}`}
        </StyledText>
      </VerticalAlignContent>

      <Row>
        <Col xs={0} lg={24}>
          <AirlineDataWrapper>
            <VerticalAlignContent>
              <img src={getAirlineLogo(airline.OperatingAirlineCode)} alt="" />
              &nbsp;&nbsp;
              <StyledText>
                {airline.OperatingAirlineName}
              </StyledText>
            </VerticalAlignContent>
          </AirlineDataWrapper>
        </Col>
      </Row>

      {flightOptions.map((airlineOption) => {
        // const airlineOption = airlineOption_.ResultList[0]; // departures
        const flight = airlineOption.FlightDetails[0];
        return (
          <RowWrapper key={airline.OperatingAirlineCode}>
            <Row gutter={4}>
              {/* Small devices */}
              <Col xs={24} lg={0}>
                <ColumnItem index={1}>
                  <Row align="middle">
                    <Col xs={12} lg={24}>
                      <HeaderAirlineDetails>
                        <img src={getAirlineLogo(airline.OperatingAirlineCode)} alt="" />
                        <StyledParagraph size="small">
                          {flight.OperatingAirlineName}
                        </StyledParagraph>
                      </HeaderAirlineDetails>
                    </Col>
                  </Row>
                </ColumnItem>
              </Col>

              <Col xs={0} lg={12} style={{ display: 'flex' }}>
                <ColumnItem index={1}>
                  <Row gutter={[0, 16]}>
                    <Col xs={0} lg={24}>
                      <CentralizedContent>
                        <StyledParagraph align="right" strong size="small">
                          {moment(flight.DepartureDate).format('hh:mm a')}
                        </StyledParagraph>
                      &nbsp;&nbsp; -  &nbsp;&nbsp;
                        <StyledParagraph align="right" strong size="small">
                          {moment(flight.ArrivalDate).format('hh:mm a')}
                        </StyledParagraph>
                      &nbsp;&nbsp;  &nbsp;&nbsp;
                        <VerticalAlignContent>
                          <img src={clockIcon} alt="" />
                        &nbsp;
                          {' '}
                          <StyledParagraph align="right" strong color="primary" size="small">
                            {flight.Duration}
                          </StyledParagraph>
                        </VerticalAlignContent>
                      </CentralizedContent>
                    </Col>
                    <Col xs={0} lg={24}>
                      <StyledParagraph align="center" strong color="secondary" size="small">
                        {pluralizeText(flight.StopOvers, 'Stop', 'Stops')}
                      </StyledParagraph>
                    </Col>
                  </Row>
                </ColumnItem>
              </Col>

              <Col xs={24} lg={0}>
                <ColumnItem index={1}>
                  <Row align="middle">
                    <Col xs={12} lg={0}>
                      <TextWrapper>
                        <StyledParagraph type="secondary">
                          {t(translations).departure}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col xs={12} lg={0}>
                      <TextWrapper>
                        <StyledParagraph align="right" size="small">
                          {moment(flight.DepartureDate).format('hh:mm a')}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col xs={12} lg={0}>
                      <TextWrapper>
                        <StyledParagraph type="secondary">
                          {t(translations).arrival}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col xs={12} lg={0}>
                      <TextWrapper>
                        <StyledParagraph align="right" size="small">
                          {moment(flight.ArrivalDate).format('hh:mm a')}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>

                    <Col lg={12} xs={0}>
                      <TextWrapper>
                        <StyledParagraph type="secondary">
                          {pluralizeText(flight.StopOvers, 'Stop', 'Stops')}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                    <Col lg={12} xs={0}>
                      <TextWrapper>
                        <StyledParagraph size="small" align="right">
                          <CentralizedContent style={{ justifyContent: 'flex-end' }}>
                            <img src={clockIcon} alt="" />
                          &nbsp;
                            {' '}
                            {flight.Duration}
                          </CentralizedContent>
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>
                  </Row>
                </ColumnItem>
              </Col>

              <Col xs={24} lg={12} style={{ display: 'flex' }}>
                <ColumnItem index={3}>
                  <Row>
                    {/* >= LG devices */}
                    <Col xs={0} lg={24}>
                      <Space direction="vertical" size="small">
                        <StyledParagraph strong align="center">
                          {formatNumberAsCurrency(airlineOption.TotalFare, airlineOption.Currency)}
                        </StyledParagraph>
                        <CentralizedContent>
                          <Button type="primary" onClick={() => onBookingRedirect([airlineOption])}>
                            {t(translations).bookFlight}
                          </Button>
                        </CentralizedContent>
                      </Space>
                    </Col>

                    <Col xs={12} lg={0}>
                      <TextWrapper>
                        <StyledParagraph strong size="small">
                          <VerticalAlignContent>
                            <img src={clockIcon} alt="" />
                          &nbsp;
                            {' '}
                            {flight.Duration}
                          </VerticalAlignContent>
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>

                    <Col xs={12} lg={0}>
                      <TextWrapper style={{ marginBottom: '20px' }}>
                        <StyledParagraph strong align="right">
                          {formatNumberAsCurrency(airlineOption.TotalFare, airlineOption.Currency)}
                        </StyledParagraph>
                      </TextWrapper>
                    </Col>

                    <Col xs={24} lg={0}>
                      <div style={{ marginBottom: '20px' }}>
                        <Button type="primary" onClick={() => onBookingRedirect([airlineOption])}>
                          {t(translations).bookFlight}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </ColumnItem>
              </Col>
            </Row>
          </RowWrapper>
        );
      })}
    </Wrapper>

  );
}

export default AirlineFlights;
