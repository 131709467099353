import React from 'react';
import {
  Button, Form, Row, Col, Checkbox,
} from 'antd';
import {
  FlexColumnItem, FormCard, FormDivider, FormWrapper,
} from './styles';
import PassengerCountSelector from '../PassegerSelector';
import SeatType from './SeatType';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import StyledDatePicker from '../CustomAntdComponents/StyledDatePicker';
import translations from '../../screens/Search/translations';
import { useI18nContext } from '../../utils/I18n';
import { getDisabledDateRange } from '../../utils/helpers';
import { MulticityFlightSearchForm } from './MultiCity';

function FlightSearchWebView(props) {
  const {
    flightModes,
    onFinish,
    onFormInputChange,
    isMultiCity,
    isRoundTrip,
    airportOptions,
    checkReturningDate,
    disabledReturnDate,
    validateDestination,
    onDateInputChange,
    formData,
    MultiCityMaxRoute,
  } = props;
  const {
    state: { t },
  } = useI18nContext();

  const {
    FlightClass,
    DepartureDate,
    ReturnDate,
    tripType,
    flightType,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    sameAirline,
  } = formData;

  const formInitialValues = {
    FlightClass,
    DepartureDate,
    ReturnDate,
    tripType,
    flightType,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    sameAirline,
  };

  const [form] = Form.useForm();

  //   const onFinish = (values) => {
  //     console.log('Received values of form:', values);
  //     const multiCityData = generateMultiCityFlightRoutes(values);

  //     updateStoreData({ multiCityData });
  //     navigate('/search-listings');
  //   };

  //   const isDomestic = flightType === flightTypes[1].value;

  //   const airportOptions = getAirportOptions(isDomestic ? domesticAirports : internationalAirports);
  //   const isRoundTrip = tripType === 'round_trip';
  //   const isMultiCity = tripType === 'multi_city';

  //   const getInternationalAirports = useCallback(async () => {
  //     const airportsApi = await getAPI(GET_INTERNATIONAL_AIRPORTS_API_URL);
  //     setAirports(airportsApi.data, 'internationalAirports');
  //   }, [setAirports]);

  //   const getDomesticAirports = useCallback(async () => {
  //     const airportsApi = await getAPI(GET_DOMESTIC_AIRPORTS_API_URL);
  //     setAirports(airportsApi.data, 'domesticAirports');
  //   }, [setAirports]);

  //   useEffect(() => {
  //     if ((isDomestic && domesticAirports.length === 0)) {
  //       getDomesticAirports();
  //     }

  //     if ((!isDomestic && internationalAirports.length === 0)) {
  //       getInternationalAirports();
  //     }
  //   }, [domesticAirports, internationalAirports, isDomestic, getDomesticAirports, getInternationalAirports]);

  //   const onFormInputChange = (value: any, name: string) => {
  //     updateFormData({ [name]: value });
  //   };

  //   const onDateInputChange = (value: any, name: string) => {
  //     updateFormData({ [name]: value });
  //   };

  //   const checkReturningDate = (_: any, value: any) => {
  //     if (!isRoundTrip || !DepartureDate) {
  //       return Promise.resolve();
  //     }

  //     if (!value) {
  //       return Promise.reject(new Error(t(translations).validations.destinationDate));
  //     }

  //     if (value.isBefore(DepartureDate)) {
  //       return Promise.reject(new Error(t(translations).validations.invalidDestinationDate));
  //     }
  //     return Promise.resolve();
  //   };

  //   const validateDestination = (_: any, value: any) => {
  //     if (!value) {
  //       return Promise.reject(new Error(t(translations).validations.destination));
  //     }

  //     if (OriginLocationCode === value) {
  //       return Promise.reject(new Error(t(translations).validations.invalidDestination));
  //     }

  //     return Promise.resolve();
  //   };

  //   const formInitialValues = {
  //     FlightClass,
  //     DepartureDate,
  //     ReturnDate,
  //     tripType,
  //     flightType,
  //     numberOfAdults,
  //     DestinationLocationCode,
  //     OriginLocationCode,
  //     numberOfInfants,
  //     numberOfChildren,
  //     sameAirline,
  //   };

  //   const disabledReturnDate: RangePickerProps['disabledDate'] = (current) => getDisabledDateRange(current, getStartOfDay(DepartureDate));

  //   useEffect(() => {
  //     setCheckReturnDate(isRoundTrip);
  //   }, [isRoundTrip, setCheckReturnDate]);

  //   useEffect(() => {
  //     form.validateFields(['ReturnDate']);
  //   }, [checkReturnDate, form]);

  return (
    <div>
      <Form
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        initialValues={formInitialValues}
        form={form}
        onFinish={onFinish}
        size="large"
      >
        <FormWrapper>
          {/* will display only smaller screen */}
          {/* <Row>
            <Col xs={24} lg={0}>
              <FlightModes flightMode={tripType} flightType={flightType} onTripTypeChange={(value) => onFormInputChange(value, 'tripType')} />
              <SeatType
                itemFilled
                flightClassOption={FlightClass}
                setFlightClassOption={(value) => onFormInputChange(value, 'FlightClass')}
              />
            </Col>
          </Row> */}

          {/* will display only larger screen */}
          <Row>
            <Col
              xs={0}
              lg={24}
            >
              <FormCard>
                <Row gutter={[24, 24]}>
                  <Col lg={11}>
                    <Row gutter={[0, 32]}>
                      <Col span={24}>
                        <SeatType
                          options={flightModes}
                          flightClassOption={tripType}
                          setFlightClassOption={(value) => onFormInputChange(value, 'tripType')}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={2}>&nbsp;</Col>

                  <Col lg={11}>
                    <Row gutter={[0, 32]}>
                      <Col span={24}>
                        <SeatType
                          itemFilled
                          flightClassOption={FlightClass}
                          setFlightClassOption={(value) => onFormInputChange(value, 'FlightClass')}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {isMultiCity ? (
                    <MulticityFlightSearchForm
                      airportOptions={airportOptions}
                      checkReturningDate={checkReturningDate}
                      disabledReturnDate={disabledReturnDate}
                      isRoundTrip={isRoundTrip}
                      ReturnDate={ReturnDate}
                      DepartureDate={DepartureDate}
                      onFormInputChange={onFormInputChange}
                      validateDestination={validateDestination}
                      sameAirline={sameAirline}
                      onDateInputChange={onDateInputChange}
                      MultiCityMaxRoute={MultiCityMaxRoute}
                    />
                  ) : (
                    <Col
                      xs={0}
                      lg={11}
                      style={{ display: 'flex' }}
                    >
                      <FlexColumnItem>
                        <Row gutter={24}>
                          <Col
                            xs={0}
                            lg={24}
                          >
                            <Form.Item
                              name="OriginLocationCode"
                              rules={[{ required: true, message: t(translations).validations.departure }]}
                            >
                              <StyledSelect
                                placeholder={t(translations).inputs.from}
                                options={airportOptions}
                                onChange={(value) => onFormInputChange(value, 'OriginLocationCode')}
                                showSearch
                              />
                            </Form.Item>
                            <Form.Item
                              name="DestinationLocationCode"
                              rules={[
                                {
                                  validator: validateDestination,
                                },
                              ]}
                            >
                              <StyledSelect
                                placeholder={t(translations).inputs.to}
                                options={airportOptions}
                                onChange={(value) => onFormInputChange(value, 'DestinationLocationCode')}
                                showSearch
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={isRoundTrip ? 12 : 24}>
                            <Form.Item
                              name="DepartureDate"
                              rules={[{ required: true, message: t(translations).validations.departureDate }]}
                            >
                              <StyledDatePicker
                                placeholder={t(translations).inputs.departureDate}
                                value={DepartureDate}
                                disabledDate={getDisabledDateRange}
                                onChange={(value) => onDateInputChange(value, 'DepartureDate')}
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={isRoundTrip ? 12 : 0}>
                            <Form.Item
                              name="ReturnDate"
                              rules={[
                                {
                                  validator: checkReturningDate,
                                },
                              ]}
                            >
                              <StyledDatePicker
                                placeholder={t(translations).inputs.arrivalDate}
                                value={ReturnDate}
                                disabledDate={disabledReturnDate}
                                onChange={(value) => onDateInputChange(value, 'ReturnDate')}
                              />
                            </Form.Item>
                          </Col>

                          {isRoundTrip && (
                          <Col lg={24}>
                            <Checkbox
                              key="airline"
                              checked={Boolean(sameAirline)}
                              onChange={(e) => onFormInputChange(e.target.checked, 'sameAirline')}
                            >
                              {t(translations).inputs.sameAirlineText}
                            </Checkbox>
                          </Col>
                          )}
                        </Row>
                      </FlexColumnItem>
                    </Col>
                  )}

                  <Col
                    lg={2}
                    style={{ display: 'flex' }}
                  >
                    <FlexColumnItem>
                      <FormDivider> &nbsp; </FormDivider>
                    </FlexColumnItem>
                  </Col>

                  <Col
                    lg={11}
                    style={{ display: 'flex' }}
                  >
                    <FlexColumnItem>
                      <PassengerCountSelector showTitle={false} />
                    </FlexColumnItem>
                  </Col>
                  <Col
                    lg={8}
                    offset={16}
                    style={{ marginTop: isMultiCity ? '-100px' : 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                    >
                      {t(translations).searchButton}
                    </Button>
                  </Col>
                </Row>
              </FormCard>
            </Col>
          </Row>

          {/* Small device Form */}
          {/* {isMultiCity ? null : (
            <Row gutter={[0, 16]}>
              <Col xs={24} lg={0}>
                <Row gutter={8}>
                  <Col xs={24} lg={0}>
                    <Form.Item
                      name="OriginLocationCode"
                      rules={[{ required: true, message: t(translations).validations.departure }]}
                    >
                      <StyledSelect
                        placeholder={t(translations).inputs.from}
                        showSearch
                        options={airportOptions}
                        onChange={(value) => onFormInputChange(value, 'OriginLocationCode')}
                      />
                    </Form.Item>

                    <Form.Item
                      name="DestinationLocationCode"
                      rules={[
                        {
                          validator: validateDestination,
                        },
                      ]}
                    >
                      <StyledSelect
                        placeholder={t(translations).inputs.to}
                        showSearch
                        options={airportOptions}
                        onChange={(value) => onFormInputChange(value, 'DestinationLocationCode')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={isRoundTrip ? 12 : 24}>
                    <Form.Item
                      name="DepartureDate"
                      rules={[{ required: true, message: t(translations).validations.departureDate }]}
                    >
                      <StyledDatePicker
                        placeholder={t(translations).inputs.departureDate}
                        value={DepartureDate}
                        disabledDate={getDisabledDateRange}
                        onChange={(value) => onFormInputChange(value, 'DepartureDate')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={isRoundTrip ? 12 : 0}>
                    <Form.Item
                      name="ReturnDate"
                      rules={[
                        {
                          validator: checkReturningDate,
                        },
                      ]}
                    >
                      <StyledDatePicker
                        placeholder={t(translations).inputs.arrivalDate}
                        value={ReturnDate}
                        disabledDate={disabledReturnDate}
                        onChange={(value) => onFormInputChange(value, 'ReturnDate')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={0}>
                <PassengerCountSelector />
                {
                (isRoundTrip && isDomestic) && (
                <Checkbox
                  key="airline"
                  checked={Boolean(sameAirline)}
                  onChange={(e) => onFormInputChange(e.target.checked, 'sameAirline')}
                >
                  {t(translations).inputs.sameAirlineText}
                </Checkbox>
                )
              }
              </Col>

              <Col xs={24} lg={0}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block>
                    {t(translations).searchButton}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )} */}
        </FormWrapper>
      </Form>
    </div>
  );
}

export default FlightSearchWebView;
