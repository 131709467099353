import moment from 'moment';
import {
  PassengerCategoryItems,
  PassengerFormItem,
  RecommendationType, RoadPassengerCategoryItems, RoadPassengerFormItem,
} from './types';
import { RoadSearchStore } from '../../store/roadSearch/types';

export const generateCategoryInputs = (numberOfInputs: number) => {
  const passengerCategory = new Array(numberOfInputs).fill({
    title: '',
    gender: '',
    city: '',
    country: 'ng',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: '',
    passportNumber: '',
    address: '',
    passportExpiration: '',
    issuanceCountry: '',
  });

  return passengerCategory.map(({
    title,
    gender,
    city,
    country,
    firstName,
    lastName,
    email,
    dob,
    phoneNumber,
    passportNumber,
    address,
    passportExpiration,
    issuanceCountry,
  }, key) => ({
    id: key,
    title,
    gender,
    city,
    country,
    firstName,
    lastName,
    email,
    dob,
    phoneNumber,
    passportNumber,
    address,
    passportExpiration,
    issuanceCountry,
  }));
};
export const generateRoadCategoryInputs = (numberOfInputs: number) => {
  const passengerCategory = new Array(numberOfInputs).fill({
    namePrefix: '',
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    nextOfKinName: '',
    nextOfKinEmail: '',
    nextOfKinPhoneNumber: '',
  });

  return passengerCategory.map(({
    namePrefix,
    gender,
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    nextOfKinName,
    nextOfKinPhoneNumber,
  }, key) => ({
    id: key,
    namePrefix,
    gender,
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    nextOfKinName,
    nextOfKinPhoneNumber,
  }));
};

const getGender = (title: string) => {
  switch (title) {
    case 'Mr':
      return 'male';
    default:
      return 'female';
  }
};
export const buildPassengerItemPayload = (data: PassengerFormItem, pType: string) => ({
  NamePrefix: data.title,
  PassengerTypeCode: pType,
  FirstName: data.firstName,
  LastName: data.lastName,
  Gender: getGender(data.title),
  Email: data.email,
  Telephone: data.phoneNumber,
  BirthDate: moment(data.dob).format('YYYY-MM-DD'),
  Address: {
    ContactName: data.firstName,
    ContactEmail: data.email,
    AddressLine1: data.address,
    City: data.city,
    CountryCode: data.country,
  },
  Documents: [{
    DocType: 'DOCS',
    InnerDocType: 'Passport',
    DocID: data.passportNumber,
    IssueCountryCode: data.issuanceCountry,
    IssueLocation: data.issuanceCountry,
    BirthCountryCode: data.country,
    ExpiryDate: data.passportExpiration ? moment(data.passportExpiration).format('YYYY-MM-DD') : undefined,
    BirthDate: moment(data.dob).format('YYYY-MM-DD'),
  }],
  //  Unknown
  NumberOfBaggages: 0,
  NumberOfBaggages1: 0,
  HandLuggages: 0,
  HandLuggages1: 0,
});

export const buildPassengersPayload = (data: PassengerCategoryItems, selectedFlight: AirlineResultItem[], isDomestic: boolean) => {
  const adults = data.adults.map((adult) => buildPassengerItemPayload(adult, 'ADT'));
  const children = data.children.map((adult) => buildPassengerItemPayload(adult, 'CHD'));
  const infants = data.infants.map((adult) => buildPassengerItemPayload(adult, 'INF'));

  const travelers = [...adults, ...children, ...infants];
  const primaryContact = data.adults[0];

  const recommendationData = isDomestic ? {
    SelectedFlights: selectedFlight.map((sf, index) => ({
      RecommendationID: sf.Properties.RecommendationID,
      CombinationID: sf.Properties.CombinationID,
      GdsId: sf.GDSId,
      AgentId: sf.AgentId,
      FlightRouteIndex: index,
    })),
    SessionId: selectedFlight[0].Properties.TripsSessionId,
  } : {
    SessionId: selectedFlight[0].Properties.TripsSessionId,
    RecommendationID: selectedFlight[0].Properties.RecommendationID,
    CombinationID: selectedFlight[0].Properties.CombinationID,
    GdsId: selectedFlight[0].GDSId,
    AgentId: selectedFlight[0].AgentId,
  };
  return {
    AirTravellers: travelers,
    BillingAddress: {
      ContactName: `${primaryContact.firstName} ${primaryContact.lastName}`,
      ContactEmail: primaryContact.email,
      ContactMobileNo: primaryContact.phoneNumber,
      AddressLine1: primaryContact.address,
      City: primaryContact.city,
      CountryCode: primaryContact.country,
    },
    ...recommendationData,
  };
};

export const buildRoadPassengerItemPayload = (data: RoadPassengerFormItem, pType: string) => ({
  PassengerTypeCode: pType,
  FirstName: data.firstName,
  LastName: data.lastName,
  SeatNumber: data.seatNumber,
  NamePrefix: data.namePrefix,
  Email: data.email,
  BirthDate: moment(data.dob).format('YYYY-MM-DD'),
});

export const buildRoadPayload = (data: RoadPassengerCategoryItems, recommendationData: RecommendationType, formData: RoadSearchStore) => {
  const adults = data.adults.map((adult) => buildRoadPassengerItemPayload(adult, 'ADT'));
  const children = data.children ? data.children.map((child) => buildRoadPassengerItemPayload(child, 'CHD')) : [];
  const infants = data.infants ? data.infants.map((infant) => buildRoadPassengerItemPayload(infant, 'INF')) : [];
  // add seat number
  const travelers = [...adults, ...children, ...infants].map((traveler, index) => ({
    ...traveler,
    SeatNumber: formData.selectedSeats ? formData.selectedSeats[index].SeatNumber : '',
  }));
  const primaryContact = data.adults[0];

  return {
    Travellers: travelers,
    NumberOfSeats: travelers.length,
    IsRoundTrip: formData.TripType === 'round_trip',
    BillingAddress: {
      ContactName: `${primaryContact.firstName} ${primaryContact.lastName}`,
      ContactEmail: primaryContact.email,
      ContactPhoneNumber: primaryContact.phoneNumber,
      AddressLine1: primaryContact.address,
      NextOfKinDetails: {
        Name: primaryContact.nextOfKinName,
        Email: primaryContact.nextOfKinEmail,
        PhoneNumber: primaryContact.nextOfKinPhoneNumber,
      },
    },
    ...{
      SessionId: recommendationData.SessionId,
      RecommendationID: recommendationData.RecommendationID,
      CombinationID: recommendationData.CombinationID,
    },
  };
};

export const buildWaterPayload = (data: RoadPassengerCategoryItems, recommendationData: RecommendationType, formData: RoadSearchStore) => {
  const adults = data.adults.map((adult) => buildRoadPassengerItemPayload(adult, 'ADT'));
  const children = data.children ? data.children.map((child) => buildRoadPassengerItemPayload(child, 'CHD')) : [];
  const infants = data.infants ? data.infants.map((infant) => buildRoadPassengerItemPayload(infant, 'INF')) : [];
  // add seat number
  const travelers = [...adults, ...children, ...infants].map((traveler) => ({
    ...traveler,
  }));
  const primaryContact = data.adults[0];

  return {
    Travellers: travelers,
    NumberOfSeats: travelers.length,
    IsRoundTrip: formData.TripType === 'round_trip',
    BillingAddress: {
      ContactName: `${primaryContact.firstName} ${primaryContact.lastName}`,
      ContactEmail: primaryContact.email,
      ContactPhoneNumber: primaryContact.phoneNumber,
      AddressLine1: primaryContact.address,
      NextOfKinDetails: {
        Name: primaryContact.nextOfKinName,
        Email: primaryContact.nextOfKinEmail,
        PhoneNumber: primaryContact.nextOfKinPhoneNumber,
      },
    },
    ...{
      SessionId: recommendationData.SessionId,
      RecommendationID: recommendationData.RecommendationID,
      CombinationID: recommendationData.CombinationID,
    },
  };
};
