import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  margin: 50px 0px 30px;
`;

export const TextContent = styled.div`
  text-align: center;
  flex: 1;
  text-transform: capitalize;
`;

export const NavIconWrapper = styled.div`
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
`;
export const NavImageIcon = styled.img`
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
`;
