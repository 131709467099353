import React from 'react';
import {
  Row, Col, Empty, Card,
} from 'antd';
import { useNavigate, useParams, Link } from 'react-router-dom';
import HeaderNavigation from '../../components/HeaderNavigation';
import FlightSearch from '../../components/FlightSearch';
import RoadSearch from '../../components/RoadSearch';
import WaterSearch from '../../components/WaterSearch';
import {
  ContentWrapper,
  PillItemWrapper,
  PillWrapper,
  TripModeWrapper,
} from './styles';
import Header from '../../components/Header';
import { getTripModes } from '../Home/constants';
import RailSearch from '../../components/RailSearch';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../utils/globalTranslation';

function Search() {
  const {
    state: { t },
  } = useI18nContext();
  const navigate = useNavigate();
  const { tripType } = useParams();
  const tripModes = getTripModes();
  const handleNavigation = () => {
    navigate('/home');
  };

  const renderTripForm = () => {
    switch (tripType) {
      case 'road':
        return <RoadSearch />;
      case 'flight':
        return <FlightSearch />;
      case 'water':
        return <WaterSearch />;
      case 'rail':
        return <RailSearch />;
      default:
        return <Card><Empty description="This feature is not available yet! " /></Card>;
    }
  };

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>
      <div className="container">
        <ContentWrapper>
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name={`${t(translations).search} ${tripType}`} navigateHandler={handleNavigation} />
            </Col>
            <Col xs={0} lg={24}>
              <HeaderNavigation navigateHandler={handleNavigation} />
            </Col>
          </Row>

          <Row>
            <Col xs={0} lg={24}>
              <TripModeWrapper>
                <PillWrapper filled>
                  { tripModes?.length > 0 && tripModes?.map((trip) => (
                    <Link to={`/search/${trip.name}`} key={trip.name}>
                      <PillItemWrapper key={trip.id} active={tripType === trip.name}>
                        {t(translations)[trip.name]}
                      </PillItemWrapper>
                    </Link>
                  ))}
                </PillWrapper>
              </TripModeWrapper>
            </Col>
          </Row>

          {renderTripForm()}
        </ContentWrapper>
      </div>
    </>

  );
}

export default Search;
