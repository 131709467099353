import React from 'react';
import {
  Checkbox, Space, Typography,
} from 'antd';
import { AirlineCheckboxWrapper } from './styles';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';

const { Paragraph } = Typography;

type Props = {
 airlines: Airline[]
  handleAirlineFiltering?: (arg0: boolean, arg: string) => void,
}

function AirlinesCheckbox({ airlines, handleAirlineFiltering }: Props) {
  const {
    state: { t },
  } = useI18nContext();
  return (
    <AirlineCheckboxWrapper>
      <Paragraph strong>
        {t(translations).airline}
      </Paragraph>
      <Space direction="vertical" size="middle">
        {airlines.map((airline) => (
          <Checkbox
            key={airline.code}
            onChange={(e) => {
              if (handleAirlineFiltering) {
                handleAirlineFiltering(e.target.checked, airline.code);
              }
            }}
          >
            {airline.name}
          </Checkbox>
        ))}
      </Space>
    </AirlineCheckboxWrapper>
  );
}

AirlinesCheckbox.defaultProps = {
  handleAirlineFiltering: undefined,
};

export default AirlinesCheckbox;
