const size = {
  extraSmall: '576px',
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px',
  extraLargePlus: '1600px',
};

export default {
  extraSmall: `(max-width: ${size.extraSmall})`,
  small: `(min-width: ${size.small})`,
  medium: `(min-width: ${size.medium})`,
  large: `(min-width: ${size.large})`,
  extraLarge: `(min-width: ${size.extraLarge})`,
  extraLargePlus: `(min-width: ${size.extraLargePlus})`,
};
