import styled from 'styled-components';
import { GREY_10 } from '../../utils/colors';

export const TitleWrapper = styled.div`
  padding-bottom: 5px;
  margin-bottom: 4px;
  border-bottom: solid 1px #eeeeee;
`;

export const DetailsWrapper = styled.div`
   
  margin: 15px 0px;
   
  
`;

export const HighlightDetailItem = styled.div`
  border-radius: 5px;
  background: ${GREY_10};
  padding: 10px;
  margin-bottom: 7px;
`;

export const HeaderTileWrapper = styled.div`
  padding: 0px 10px;
  margin-bottom: 7px;
  border-radius: 5px;
  border: 1px solid #D4C7A6;
  background: #F9F9F9;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;
