export default {
  en: {
    cancel: 'Cancel',
    proceed: 'Proceed',
    from: 'from',
    getReceipt: 'Get Receipt',
    itineraryTitle: 'Itinerary Breakdown',
    makePayment: 'Make Payment',
    proceedText: 'Would you like to proceed?',
    confirmationText: 'Hello User, you are about to book a ticket of',
    confirmTitle: 'Kindly confirm this action',
    successText: 'You have successfully booked your trip',
    successTitle: 'Successful !!',
  },
  fr: {
    cancel: 'Annuler',
    proceed: 'Procédez à',
    from: 'Au départ de',
    getReceipt: 'Recevoir un reçu',
    itineraryTitle: 'Répartition des itinéraires',
    makePayment: 'Effectuer le paiement ',
    proceedText: 'Voulez-vous procéder ?',
    confirmationText: 'Bonjour Utilisateur, vous êtes sur le point de réserver un billet de',
    confirmTitle: 'Veuillez confirmer cette action',
    successText: 'Vous avez réservé votre voyage avec succès',
    successTitle: 'Réussi !!!',
  },
  sp: {
    cancel: 'Cancelar',
    proceed: 'Proceder',
    from: 'de',
    getReceipt: 'Obtener el recibo',
    itineraryTitle: 'Desglose del itinerario',
    makePayment: 'Hacer el pago',
    proceedText: '¿Desea proceder?',
    confirmationText: 'Hola usuario, está a punto de reservar un billete de',
    confirmTitle: 'Por favor, confirme esta acción',
    successText: 'Ha reservado correctamente su viaje',
    successTitle: 'Exitoso !!!',
  },
  sw: {
    cancel: 'Ghairi',
    proceed: 'Endelea',
    from: 'kutoka',
    getReceipt: 'Pata Risiti\n',
    itineraryTitle: 'Uchanganuzi wa Ratiba',
    makePayment: 'Fanya Malipo\n',
    proceedText: 'Je, ungependa kuendelea?',
    confirmationText: 'Hujambo Mtumiaji, unakaribia kukata tikiti ya',
    confirmTitle: 'Tafadhali thibitisha kitendo hiki',
    successText: 'Umehifadhi nafasi ya safari yako',
    successTitle: 'Imefanikiwa !!!',
  },
};
