import moment, { Moment } from 'moment';

export const titleOptions : SelectOption[] = [{ label: 'Mr', value: 'Mr' }, { label: 'Mrs', value: 'Mrs' }, { label: 'Miss', value: 'Miss' }];

export const genderOptions : SelectOption[] = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];

export const allowedAgedRange = (current: Moment, passengerType: string): boolean => {
  switch (passengerType) {
    case 'ADT':
      return current && current > moment().subtract('12', 'years').endOf('day');
    case 'CHD':
      return current && !current.isBetween(moment().subtract('11', 'years'), moment().subtract('2', 'years').endOf('day'));
    case 'INF':
      return current && !current.isBetween(moment().subtract('1', 'years'), moment().endOf('day'));
    default:
      return current && current > moment().subtract('12', 'years').endOf('day');
  }
};
