import { Moment } from 'moment';
import {
  FlightPassenger,
} from '../../store/flightSearch/types';
import {
  BusTravelRoute,
  RoadSearchPayload,
  RoadSearchStore,
} from '../../store/roadSearch/types';

type PaxCounter = {
  numberOfAdults: number;
  numberOfChildren?: number;
  numberOfInfants?: number;
}

type TravelRouteArg = {
  DepartureDate?: Moment,
  ArrivalDate?: Moment,
  ArrivalId?: number,
  DepartureId?: number
}
const generateFlightPassengersCount = ({
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
}: PaxCounter) :FlightPassenger[] => {
  const payload = [
    {
      Code: 'ADT',
      Quantity: numberOfAdults,
    },
  ];

  if (numberOfChildren) {
    payload.push({
      Code: 'CHD',
      Quantity: numberOfChildren,
    });
  }

  if (numberOfInfants) {
    payload.push({
      Code: 'INF',
      Quantity: numberOfInfants,
    });
  }

  return payload;
};

const generateTravelRoutes = (arg: TravelRouteArg) : BusTravelRoute => {
  const DepartureDate = arg.DepartureDate ? arg.DepartureDate.format('YYYY-MM-DD') : '';
  const ArrivalDate = arg.ArrivalDate ? arg.ArrivalDate.format('YYYY-MM-DD') : '';

  return {
    DepartureId: arg.DepartureId,
    ArrivalId: arg.ArrivalId,
    DepartureDate,
    ArrivalDate,
  };
};

export const buildSearchPayload = (data: RoadSearchStore): RoadSearchPayload => {
  const {
    Currency,
    From,
    PageSize,
    OrderbyDepartureTime,
    OrderByPrice,
    numberOfAdults,
    numberOfChildren,
    DepartureDate,
    ArrivalDate,
    DepartureId,
    ArrivalId,
    TripType,
  } = data;

  const payload = {
    Parameter: {
      TravelRoute: generateTravelRoutes({
        DepartureDate,
        DepartureId,
        ArrivalId,
        ArrivalDate,
      }),
      PassengerTypes: generateFlightPassengersCount({ numberOfAdults, numberOfChildren }),
    },
    IsRoundTrip: TripType === 'round_trip',
    Currency,
    OrderbyDepartureTime,
    OrderByPrice,
    PageSize,
    From,
  };

  return payload;
};
