import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoadSearchStore } from '../store/roadSearch/roadSearchStore';
import AuthenticatedRoute from './AuthenticatedRoute';

type Props = {
  children: ReactNode
}
function RoadDataFilled({ children }: Props) {
  const navigate = useNavigate();
  const {
    formData,
  } = useRoadSearchStore();

  useEffect(() => {
    if (!formData.DepartureId) {
      navigate('/search/road');
    }
  }, [formData, navigate]);
  return (
    <AuthenticatedRoute>
      {children}
    </AuthenticatedRoute>
  );
}

export default RoadDataFilled;
