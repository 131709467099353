import React, { useState, useEffect, useCallback } from 'react';
import {
  Col, Row, Card, Modal, Space, Spin,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import HeaderNavigation from '../../components/HeaderNavigation';
import {
  HeaderWrapper,
  TripSummaryWrapper,
  Wrapper,
} from './styles';
import {
  FareRuleTitleWrapper,
  FareRuleWrapper,
} from '../SearchListings/styles';
import AvailableBuses from '../../components/AvailableBuses';
import Header from '../../components/Header';
import {
  GET_ROAD_FARE_RULES,
  SEARCH_BUS_ROUTES,
} from '../../utils/constants';
import { postAPI } from '../../utils/api';
import { buildSearchPayload } from './helpers';
import OperatorBus from '../../components/AvailableBuses/OperatorBus';
import { isMobileScreen } from '../../utils/helpers';
import {
  StyledParagraph,
  StyledTitle,
} from '../../components/CustomAntdComponents/Typography';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import RoadTripSummary from '../../components/TripSummary/RoadTripSummary';
import StyledDrawer from '../../components/CustomAntdComponents/StyledDrawer';
import RoadListingFilter from '../../components/ListingFilter/RoadFilter';
import AlertMessage from '../../components/AlertMessage';
import {
  StyledButton,
} from '../../components/CustomAntdComponents/StyledButton';
import { useI18nContext } from '../../utils/I18n';
import translations from './translations';

function SearchRoadListings() {
  const {
    state: { t },
  } = useI18nContext();
  const navigate = useNavigate();
  const [busResults, setBusResults] = useState<RoadSearchResult>();
  const [filteredBusResults, setFilteredBusResults] = useState<RoadSearchResult>();
  const [selectedTransports, setSelectedTransports] = useState<number[]>([]);
  const [fareRules, setFareRules] = useState<RoadFareRule[] | null>(null);
  const [fareRulesError, setFareRulesError] = useState<string| null>(null);
  const [loadingFareData, setLoadingFareData] = useState<boolean>(false);

  const [error, setError] = useState<string | null>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const {
    formData,
    selectedResult,
    updateStoreData,
  } = useRoadSearchStore();

  const [modalVisible, setModalVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const isMobile = isMobileScreen();

  const getBusRules = useCallback(async (selectedBus: AvailableBus) => {
    setLoadingFareData(true);
    setFareRulesError(null);
    setFareRules(null);
    if (selectedResult) {
      const flightRules = await postAPI(GET_ROAD_FARE_RULES, {
        SessionId: selectedResult.TripsSessionId,
        CombinationID: selectedResult.CombinationId,
        RecommendationID: selectedBus.RecommendationId,
      });

      setLoadingFareData(false);

      if (flightRules.status === 200) {
        setFareRules(flightRules.data);
      } else {
        setFareRulesError(flightRules.data);
      }
    }
  }, [selectedResult]);

  const searchBuses = useCallback(async () => {
    setLoadingData(true);
    setError(null);
    const payload = buildSearchPayload(formData);
    const searchResults = await postAPI(SEARCH_BUS_ROUTES, payload);

    if (searchResults.status === 200) {
      setBusResults(searchResults.data);
      setFilteredBusResults(searchResults.data);
      setLoadingData(false);
    } else {
      setError(searchResults.data);
      setLoadingData(false);
    }
  }, [formData]);

  useEffect(() => {
    searchBuses();
  }, [searchBuses]);

  const handleNavigation = () => {
    if (selectedResult) {
      updateStoreData({ selectedResult: null });
    } else {
      navigate(-1);
    }
  };

  const handleBookingRedirect = (selectedBus: AvailableBus) => {
    updateStoreData({ selectedBus });
    getBusRules(selectedBus);

    if (isMobile) {
      setDialogVisible(true);
    } else {
      setModalVisible(true);
    }
  };

  const handleAccept = () => {
    setDialogVisible(false);
    setModalVisible(false);

    navigate('/seat-selection');
  };

  const renderAcceptButton = () => (
    <StyledButton mode="primary" size="large" block onClick={handleAccept} disabled={!fareRules}>
      {t(translations).fareRule.acceptButton}
    </StyledButton>
  );

  const handleTransportFiltering = (isChecked: boolean, transportId: number, singleMode?: boolean) => {
    if (transportId === -1) {
      setSelectedTransports([]);
      return;
    }

    if (singleMode) {
      setSelectedTransports([transportId]);
      return;
    }

    let newSelections = [...selectedTransports];

    if (isChecked) {
      newSelections.push(transportId);
    } else {
      newSelections = newSelections.filter((code) => transportId !== code);
    }
    setSelectedTransports(newSelections);
  };

  const renderRulesContent = () => (
    <>
      <FareRuleTitleWrapper>
        <StyledTitle level={3} color="primary" align="center">
          {t(translations).fareRule.title}
        </StyledTitle>
      </FareRuleTitleWrapper>
      <StyledParagraph align="center" strong>
        {t(translations).fareRule.text}
      </StyledParagraph>
      <FareRuleWrapper>
        <Spin spinning={loadingFareData}>
          {fareRulesError && <AlertMessage message={fareRulesError} />}
          <Space direction="vertical" size="small">
            {fareRules && fareRules.map((fareRule) => (
              <div key={fareRule.Code}>
                <StyledTitle level={5}>
                  {fareRule.Code}
                </StyledTitle>
                <StyledParagraph>
                  {fareRule.Text}
                </StyledParagraph>
              </div>
            ))}
          </Space>
        </Spin>
      </FareRuleWrapper>
    </>
  );

  useEffect(() => {
    if (busResults) {
      if (selectedTransports.length === 0) {
        setFilteredBusResults(busResults);
      } else {
        const filtered = busResults.ResultList.filter((b) => selectedTransports.includes(b.AgentId));
        setFilteredBusResults({
          ...busResults,
          ResultList: filtered,
        });
      }
    }
  }, [busResults, selectedTransports]);

  const handleSortByPrice = (order: string| null) => {
    if (busResults) {
      const sortedResultList = order === 'asc' ? busResults.ResultList.sort(
        (a: BusResultItem, b: BusResultItem) => a.StartingPrice - b.StartingPrice,
      ) : busResults.ResultList.sort(
        (a: BusResultItem, b: BusResultItem) => b.StartingPrice - a.StartingPrice,
      ) || [];

      setBusResults({
        ...busResults,
        ResultList: sortedResultList,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>

      <div className="container">
        <Wrapper>
          {/* Small Devices */}
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name={t(translations).title} navigateHandler={handleNavigation} />
            </Col>

            <Col xs={24} lg={0}>
              <Card size="small">
                <TripSummaryWrapper>
                  <RoadTripSummary />
                </TripSummaryWrapper>
              </Card>
            </Col>
          </Row>

          {/*  >= LG Devices */}
          <Row>
            <Col xs={0} lg={24}>
              <HeaderWrapper>
                <Row gutter={32}>
                  <Col lg={8}>
                    <HeaderNavigation navigateHandler={handleNavigation} />
                  </Col>
                  <Col lg={16}>
                    <Card size="default">
                      <TripSummaryWrapper>
                        <RoadTripSummary />
                      </TripSummaryWrapper>
                    </Card>
                  </Col>
                </Row>
              </HeaderWrapper>

              <div style={{ marginTop: '30px' }}>
                <Row gutter={32}>
                  <Col lg={8}>
                    <Card>
                      <RoadListingFilter
                        busResults={busResults}
                        handleTransportFiltering={handleTransportFiltering}
                        handleSortByPrice={handleSortByPrice}
                      />
                    </Card>
                  </Col>
                  <Col lg={16}>
                    {selectedResult
                      ? (
                        <OperatorBus
                          onBookingRedirect={handleBookingRedirect}
                          listings={selectedResult}
                        />
                      )
                      : (
                        <AvailableBuses
                          error={error}
                          results={filteredBusResults}
                          loading={loadingData}
                          onSetActiveBus={(resultItem) => updateStoreData({ selectedResult: resultItem })}
                        />
                      )}
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>

          {/*  <= XS Devices */}
          <Row>
            <Col xs={24} lg={0}>
              {selectedResult
                ? (
                  <OperatorBus
                    onBookingRedirect={handleBookingRedirect}
                    listings={selectedResult}
                  />
                )
                : (
                  <>
                    <RoadListingFilter
                      busResults={busResults}
                      handleTransportFiltering={handleTransportFiltering}
                      handleSortByPrice={handleSortByPrice}
                    />
                    <AvailableBuses
                      error={error}
                      results={filteredBusResults}
                      loading={loadingData}
                      onSetActiveBus={(busResult) => updateStoreData({ selectedResult: busResult })}
                    />
                  </>
                )}
            </Col>
          </Row>

          <Modal
            centered
            visible={modalVisible}
            footer={null}
            onCancel={() => {
              setModalVisible(false);
              setFareRules(null);
            }}
            width={400}
            getContainer={document.getElementById('app-container') as HTMLElement}
          >
            {renderRulesContent()}
            {renderAcceptButton()}
          </Modal>

          <StyledDrawer visibility={dialogVisible} onClose={() => setDialogVisible(false)} footer={renderAcceptButton()} height={200}>
            {renderRulesContent()}
          </StyledDrawer>

        </Wrapper>
      </div>
    </>

  );
}

export default SearchRoadListings;
