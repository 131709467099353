import React from 'react';
import {
  IndexScreen,
  HomeScreen,
  SearchScreen,
  SearchListingsScreen,
  ItineraryScreen,
  PassengerDetailsScreen,
  PaymentScreen,
  PaymentReceiptScreen,
  BookingSearchScreen,
  SearchRoadListingsScreen,
  SearchWaterListingsScreen,
  SeatSelectionScreen, SearchRailListingsScreen, TrainSeatSelectionScreen,
} from '../screens';
import { Route } from './type';
import DataFilled from '../Layouts/DataFilled';
import RoadDataFilled from '../Layouts/RoadDataFilled';
import FlightDataFilled from '../Layouts/FlightDataFilled';
import AuthenticatedRoute from '../Layouts/AuthenticatedRoute';
import WaterDataFilled from '../Layouts/WaterDataFilled';
import RailDataFilled from '../Layouts/RailDataFilled';

const routes: Route[] = [
  {
    id: 1,
    path: '/',
    element: <IndexScreen />,
  },
  {
    id: 1,
    path: '/home',
    element: <AuthenticatedRoute><HomeScreen /></AuthenticatedRoute>,
  },
  {
    id: 3,
    path: '/search/:tripType',
    element: <AuthenticatedRoute><SearchScreen /></AuthenticatedRoute>,
  },
  {
    id: 4,
    path: '/search-listings',
    element: <FlightDataFilled><SearchListingsScreen /></FlightDataFilled>,
  },
  {
    id: 5,
    path: '/itinerary',
    element: <FlightDataFilled><ItineraryScreen /></FlightDataFilled>,
  },
  {
    id: 6,
    path: '/passenger-details/:tripType',
    element: <DataFilled><PassengerDetailsScreen /></DataFilled>,
  },
  {
    id: 7,
    path: '/payment/:tripType',
    element: <DataFilled><PaymentScreen /></DataFilled>,
  },
  {
    id: 8,
    path: '/payment-receipt/:tripType',
    element: <DataFilled><PaymentReceiptScreen /></DataFilled>,
  },
  {
    id: 9,
    path: '/my-booking/:tripType',
    element: <AuthenticatedRoute><BookingSearchScreen /></AuthenticatedRoute>,
  },
  {
    id: 10,
    path: '/search-road-listings',
    element: <RoadDataFilled><SearchRoadListingsScreen /></RoadDataFilled>,
  },
  {
    id: 11,
    path: '/search-water-listings',
    element: <WaterDataFilled><SearchWaterListingsScreen /></WaterDataFilled>,
  },
  {
    id: 11,
    path: '/search-rail-listings',
    element: <RailDataFilled><SearchRailListingsScreen /></RailDataFilled>,
  },
  {
    id: 12,
    path: '/seat-selection',
    element: <RoadDataFilled><SeatSelectionScreen /></RoadDataFilled>,
  },
  {
    id: 13,
    path: '/seat-selection/train',
    element: <RailDataFilled><TrainSeatSelectionScreen /></RailDataFilled>,
  },
];

export default routes;
