import React, { useCallback, useEffect, useState } from 'react';
import {
  Card, Typography, Checkbox, Button, Spin, Space,
} from 'antd';
import { Content, LinkText, TextWrapper } from './styles';
import StyledDrawer from '../CustomAntdComponents/StyledDrawer';
import { postAPI } from '../../utils/api';
import { GET_FLIGHT_FARE_RULES } from '../../utils/constants';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { FareRuleWrapper } from '../../screens/SearchListings/styles';
import AlertMessage from '../AlertMessage';
import {
  StyledParagraph,
  StyledTitle,
} from '../CustomAntdComponents/Typography';

const { Title, Text } = Typography;

type Props = {
  onAccept: (arg: boolean) => void
  isChecked:boolean
}
function FareRule({ onAccept, isChecked }: Props) {
  const {
    selectedFlight,
  } = useFlightSearchStore();

  const [visible, setVisible] = useState(false);
  const [loadingFareData, setLoadingFareData] = useState<boolean>(true);
  const [fareRulesError, setFareRulesError] = useState<string| null>(null);
  const [fareRules, setFareRules] = useState<FlightFareRule[] | null>(null);

  const getFlightRues = useCallback(async () => {
    setLoadingFareData(true);
    setFareRulesError(null);

    if (selectedFlight) {
      const activeFlight = selectedFlight[0];
      const flightRules = await postAPI(GET_FLIGHT_FARE_RULES, {
        AgentId: activeFlight.AgentId,
        GDSId: activeFlight.GDSId,
        CombinationID: activeFlight.Properties.CombinationID,
        RecommendationID: activeFlight.Properties.RecommendationID,
        PassengerTypeCode: 'ADT',
        SessionId: activeFlight.Properties.TripsSessionId,
      });

      setLoadingFareData(false);

      if (flightRules.status === 200) {
        setFareRules(flightRules.data.FlightRules);
      } else {
        setFareRulesError(flightRules.data);
      }
    }
  }, [selectedFlight]);

  useEffect(() => {
    getFlightRues();
  }, [getFlightRues]);

  const AcceptButton = (<Button block type="primary" onClick={() => { onAccept(true); setVisible(false); }}> I Accept </Button>);

  return (
    <Card>
      <Title level={5}>Fare Rules</Title>

      <Content>
        <Checkbox onChange={(e) => onAccept(e.target.checked)} checked={isChecked} />
        <TextWrapper>
          <Text>
            I hereby agree that i acknowledge the policies within these fare rules, which include that once flight
            ticket fee has been paid, it is not refundable for any reasons.
          </Text>
          <LinkText onClick={() => setVisible(true)}>
            Please read our fare rules here.
          </LinkText>
        </TextWrapper>
      </Content>

      <StyledDrawer visibility={visible} onClose={() => setVisible(false)} footer={AcceptButton} height={600}>
        <Title level={5}>Fare Rules</Title>

        <FareRuleWrapper>
          <Spin spinning={loadingFareData}>
            {fareRulesError && <AlertMessage message={fareRulesError} />}

            <Space direction="vertical" size="small">
              {fareRules && fareRules.map((fareRule) => (
                <div key={fareRule.Title}>
                  <StyledTitle level={5}>
                    {fareRule.Title}
                  </StyledTitle>
                  <StyledParagraph>
                    {fareRule.Text}
                  </StyledParagraph>
                </div>
              ))}
            </Space>
          </Spin>

        </FareRuleWrapper>
      </StyledDrawer>
    </Card>
  );
}

export default FareRule;
