export default {
  en: {
    summary: 'Summary',
    ticketAmount: 'Ticket Amount',
    totalAmount: 'Total Amount',
    convenience: 'Convenience Fee',
  },
  fr: {
    summary: 'Résumé',
    ticketAmount: 'Montant du ticket',
    totalAmount: 'Montant total',
    convenience: 'Frais de commodité',
  },
  sp: {
    summary: 'Resumen',
    ticketAmount: 'Importe del billete',
    totalAmount: 'Importe total',
    convenience: 'Tarifa de conveniencia',
  },
  sw: {
    summary: 'Muhtasari',
    ticketAmount: 'Kiasi cha Tiketi',
    totalAmount: 'Jumla',
    convenience: 'Ada ya Urahisi',
  },
};
