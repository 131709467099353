/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Col, Row, Card, Modal, Space, Spin,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import HeaderNavigation from '../../components/HeaderNavigation';
import TripSummary from '../../components/TripSummary';
import {
  FareRuleTitleWrapper,
  FareRuleWrapper,
  HeaderWrapper,
  TripSummaryWrapper,
  Wrapper,
} from './styles';
import ListingFilter from '../../components/ListingFilter';
import AvailableFlights from '../../components/AvailableFlights';
import Header from '../../components/Header';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import {
  GET_FLIGHT_FARE_RULES,
  SEARCH_DOMESTIC_FLIGHTS_URL, SEARCH_INTERNATIONAL_FLIGHTS_URL,
} from '../../utils/constants';
import { postAPI } from '../../utils/api';
import { flightTypes } from '../Home/constants';
import { buildFlightSearchPayload } from './helpers';
import AirlineFlights from '../../components/AvailableFlights/AirlineFlights';
import AvailableInternationalFlights
  from '../../components/AvailableFlights/InternationalFlights';
import { isMobileScreen } from '../../utils/helpers';
import {
  StyledParagraph,
  StyledTitle,
} from '../../components/CustomAntdComponents/Typography';
import AlertMessage from '../../components/AlertMessage';
import {
  StyledButton,
} from '../../components/CustomAntdComponents/StyledButton';
import { useI18nContext } from '../../utils/I18n';
import translations from './translations';

function SearchListings() {
  const {
    state: { t },
  } = useI18nContext();
  const navigate = useNavigate();
  const [domesticFlights, setDomesticFlights] = useState<FlightAggregateResultItem>();
  const [internationalFlights, setInternationalFlights] = useState<InternationalFlightResultItem>();
  const [filteredInternationalFlights, setFilteredInternationalFlights] = useState<InternationalFlightResultItem>();
  const [filteredDomesticFlights, setFilteredDomesticFlights] = useState<FlightAggregateResultItem>();
  const [error, setError] = useState<string | null>();
  const [activeAirlineHit, setActiveAirlineHit] = useState<FlightResultItem | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [fareRules, setFareRules] = useState<FlightFareRule[] | null>(null);
  const [fareRulesError, setFareRulesError] = useState<string| null>(null);
  const [loadingFareData, setLoadingFareData] = useState<boolean>(true);
  const [selectedAirlines, setSelectedAirlines] = useState<string[]>([]);
  const [selectedStopOver, setSelectedStopOver] = useState<number | null>(null);
  const {
    formData, updateStoreData,
  } = useFlightSearchStore();

  const isDomestic = formData.flightType === flightTypes[1].value;
  const [modalVisible, setModalVisible] = useState(false);

  const isMobile = isMobileScreen();
  const isMultiCity = formData.tripType === 'multi_city';

  const searchFlights = useCallback(async () => {
    setLoadingData(true);
    setError(null);
    const url = isDomestic ? SEARCH_DOMESTIC_FLIGHTS_URL : SEARCH_INTERNATIONAL_FLIGHTS_URL;

    const payload = buildFlightSearchPayload(formData);

    const searchResults = await postAPI(url, payload);

    if (searchResults.status === 200) {
      if (isDomestic) {
        setDomesticFlights(searchResults.data);
        setFilteredDomesticFlights(searchResults.data);
      } else {
        setInternationalFlights(searchResults.data);
        setFilteredInternationalFlights(searchResults.data);
      }
      setLoadingData(false);
    } else {
      setError(searchResults.data);
      setLoadingData(false);
    }
  }, [formData, isDomestic]);

  const getFlightRules = useCallback(async (selectedFlight: AirlineResultItem[]) => {
    setLoadingFareData(true);
    setFareRules(null);
    setFareRulesError(null);
    const activeFlight = selectedFlight[0];

    const flightRules = await postAPI(GET_FLIGHT_FARE_RULES, {
      AgentId: activeFlight.AgentId,
      GDSId: activeFlight.GDSId,
      CombinationID: activeFlight.Properties.CombinationID,
      RecommendationID: activeFlight.Properties.RecommendationID,
      PassengerTypeCode: 'ADT',
      SessionId: activeFlight.Properties.TripsSessionId,
    });

    setLoadingFareData(false);

    if (flightRules.status === 200) {
      setFareRules(flightRules.data.FlightRules);
    } else {
      setFareRulesError(flightRules.data);
    }
  }, []);

  useEffect(() => {
    searchFlights();
  }, [searchFlights]);

  const handleNavigation = () => {
    if (activeAirlineHit) {
      setActiveAirlineHit(null);
    } else {
      navigate(-1);
    }
  };

  const handleBookingRedirect = (selectedFlight: AirlineResultItem[]) => {
    updateStoreData({ selectedFlight });
    if (isMobile) {
      navigate('/itinerary');
    } else {
      setModalVisible(true);
      getFlightRules(selectedFlight);
    }
  };

  const renderAvailableFlights = () => (isDomestic ? (
    <AvailableFlights
      error={error}
      results={filteredDomesticFlights}
      loading={loadingData}
      isRoundTrip={formData.tripType === 'round_trip'}
      onBookingRedirect={handleBookingRedirect}
    />
  ) : (
    <AvailableInternationalFlights
      error={error}
      onBookingRedirect={handleBookingRedirect}
      results={filteredInternationalFlights}
      loading={loadingData}
    />
  ));

  const handleAirlineFiltering = (isChecked: boolean, airline: string, singleMode?: boolean) => {
    if (airline === 'all') {
      setSelectedAirlines([]);
      return;
    }

    if (singleMode) {
      setSelectedAirlines([airline]);
      return;
    }

    let newSelections = [...selectedAirlines];
    if (isChecked) {
      newSelections.push(airline);
    } else {
      newSelections = newSelections.filter((code) => airline !== code);
    }
    setSelectedAirlines(newSelections);
  };

  const handleStopoverFiltering = (arg: number | null) => {
    if (arg === -1) {
      setSelectedStopOver(null);
      return;
    }
    setSelectedStopOver(arg);
  };
  const handleFilterInternationalFlights = useCallback((records: AirlineResultItem[]) => {
    let filtered: AirlineResultItem[];
    if (selectedStopOver !== null && selectedAirlines.length > 0) {
      filtered = records.filter((f) => selectedAirlines.includes(f.FlightDetails[0].OperatingAirlineCode)
        && selectedStopOver === f.FlightDetails[0].StopOvers);
    } else if (selectedAirlines.length > 0) {
      filtered = records.filter((f) => selectedAirlines.includes(f.FlightDetails[0].OperatingAirlineCode));
    } else if (selectedStopOver !== null) {
      filtered = records.filter((f) => selectedStopOver === f.FlightDetails[0].StopOvers);
    } else {
      filtered = records;
    }

    return filtered;
  }, [selectedAirlines, selectedStopOver]);

  useEffect(() => {
    if (domesticFlights) {
      if (selectedAirlines.length === 0) {
        setFilteredDomesticFlights(domesticFlights);
      } else {
        const filtered = domesticFlights.ResultList.filter((f) => selectedAirlines.includes(f.ResultList[0].FlightDetails[0].OperatingAirlineCode));
        setFilteredDomesticFlights({
          ...domesticFlights,
          ResultList: filtered,
        });
      }
    }
  }, [domesticFlights, selectedAirlines]);

  useEffect(() => {
    if (internationalFlights) {
      const filtered = handleFilterInternationalFlights(internationalFlights.ResultList);
      setFilteredInternationalFlights({
        ...internationalFlights,
        ResultList: filtered,
      });
    }
  }, [handleFilterInternationalFlights, selectedStopOver, internationalFlights, selectedAirlines]);

  const handleSortByPrice = (order: string| null) => {
    if (domesticFlights) {
      const sortedRecords = order === 'asc' ? domesticFlights.ResultList[0].ResultList.sort(
        (a: AirlineResultItem, b: AirlineResultItem) => a.TotalFare - b.TotalFare,
      ) : domesticFlights.ResultList[0].ResultList.sort(
        (a: AirlineResultItem, b: AirlineResultItem) => b.TotalFare - a.TotalFare,
      ) || [];

      const sortedResultList = domesticFlights.ResultList;
      sortedResultList[0] = { TotalCount: domesticFlights.ResultList[0].TotalCount, ResultList: sortedRecords };

      setDomesticFlights({
        ...domesticFlights,
        ResultList: sortedResultList,
      });
      setFilteredDomesticFlights(filteredDomesticFlights);
    } else if (internationalFlights) {
      const sortedRecords = order === 'asc' ? internationalFlights.ResultList.sort(
        (a: AirlineResultItem, b: AirlineResultItem) => a.TotalFare - b.TotalFare,
      ) : internationalFlights.ResultList.sort(
        (a: AirlineResultItem, b: AirlineResultItem) => b.TotalFare - a.TotalFare,
      ) || [];
      setInternationalFlights({
        ...internationalFlights,
        ResultList: sortedRecords,
      });
    }
  };

  const renderTripSummaryMultiCity = (item, index, size) => (
    <Card size={size} key={`TripSummmaryMultiCity${index}`} style={{ marginBottom: '10px' }}>
      <TripSummaryWrapper>
        <TripSummary
          DestinationLocationCode={item.DestinationLocationCode}
          OriginLocationCode={item.OriginLocationCode}
          DepartureDate={item.DepartureDate}
        />
      </TripSummaryWrapper>
    </Card>
  );

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>

      <div className="container">
        <Wrapper>
          {/* Small Devices */}
          <Row>
            <Col xs={24} lg={0}>

              <HeaderNavigation name={t(translations).title} navigateHandler={handleNavigation} />
            </Col>

            <Col xs={24} lg={0}>
              {isMultiCity && formData?.multiCity?.length > 0
                ? formData?.multiCity?.map((item, index) => renderTripSummaryMultiCity(item, index, 'small'))
                : (
                  <Card size="small">
                    <TripSummaryWrapper>
                      <TripSummary
                        DestinationLocationCode={formData.DestinationLocationCode}
                        OriginLocationCode={formData.OriginLocationCode}
                        DepartureDate={formData.DepartureDate}
                        ReturnDate={formData.ReturnDate}
                      />

                    </TripSummaryWrapper>
                  </Card>
                ) }
            </Col>
          </Row>

          {/*  >= LG Devices */}
          <Row>
            <Col xs={0} lg={24}>
              <HeaderWrapper>
                <Row gutter={32}>

                  <Col lg={8}>
                    <HeaderNavigation navigateHandler={handleNavigation} />
                  </Col>
                  <Col lg={16}>
                    {isMultiCity && formData?.multiCity?.length > 0
                      ? formData?.multiCity?.map((item, index) => renderTripSummaryMultiCity(item, index, 'default'))
                      : (
                        <Card size="default">
                          <TripSummaryWrapper>
                            <TripSummary
                              DestinationLocationCode={formData.DestinationLocationCode}
                              OriginLocationCode={formData.OriginLocationCode}
                              DepartureDate={formData.DepartureDate}
                              ReturnDate={formData.ReturnDate}
                            />

                          </TripSummaryWrapper>
                        </Card>
                      )}
                  </Col>
                </Row>
              </HeaderWrapper>

              <div style={{ marginTop: '30px' }}>
                <Row gutter={32}>
                  <Col lg={8}>
                    <Card>
                      <ListingFilter
                        isDomestic={isDomestic}
                        handleStopoverFiltering={handleStopoverFiltering}
                        handleAirlineFiltering={handleAirlineFiltering}
                        internationalFlights={internationalFlights}
                        domesticFlights={domesticFlights}
                        handleSortByPrice={handleSortByPrice}
                      />
                    </Card>
                  </Col>
                  <Col lg={16}>
                    {activeAirlineHit
                      ? (
                        <AirlineFlights
                          onBookingRedirect={handleBookingRedirect}
                          listings={activeAirlineHit}
                        />
                      )
                      : renderAvailableFlights()}
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>

          {/*  <= XS Devices */}
          <Row>
            <Col xs={24} lg={0}>
              <ListingFilter
                isDomestic={isDomestic}
                handleStopoverFiltering={handleStopoverFiltering}
                handleAirlineFiltering={handleAirlineFiltering}
                internationalFlights={internationalFlights}
                domesticFlights={domesticFlights}
                handleSortByPrice={handleSortByPrice}
              />

              {activeAirlineHit
                ? (
                  <AirlineFlights
                    onBookingRedirect={handleBookingRedirect}
                    listings={activeAirlineHit}
                  />
                )
                : renderAvailableFlights()}
            </Col>
          </Row>

          <Modal
            centered
            visible={modalVisible}
            footer={null}
            onCancel={() => {
              setModalVisible(false);
              setFareRulesError(null);
            }}
            width={400}
            getContainer={document.getElementById('app-container') as HTMLElement}
          >
            <FareRuleTitleWrapper>
              <StyledTitle level={3} color="primary" align="center">
                {t(translations).fareRule.title}
              </StyledTitle>
            </FareRuleTitleWrapper>
            <StyledParagraph align="center" strong>
              {t(translations).fareRule.text}
            </StyledParagraph>
            <FareRuleWrapper>
              <Spin spinning={loadingFareData}>
                {fareRulesError && <AlertMessage closable={false} message={fareRulesError} />}

                <Space direction="vertical" size="small">
                  {fareRules && fareRules.map((fareRule) => (
                    <div key={fareRule.Title}>
                      <StyledTitle level={5}>
                        {fareRule.Title}
                      </StyledTitle>
                      <StyledParagraph>
                        {fareRule.Text}
                      </StyledParagraph>
                    </div>
                  ))}
                </Space>
              </Spin>

            </FareRuleWrapper>

            <Link to="/passenger-details/flight">
              <StyledButton mode="primary" size="large" block disabled={!fareRules}>
                {t(translations).fareRule.acceptButton}
              </StyledButton>
            </Link>
          </Modal>
        </Wrapper>
      </div>
    </>

  );
}

export default SearchListings;
