import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 20px 270px;
  overflow: visible;
`;

export const ActionWrapper = styled.div`
  margin: 200px 0 0;
  position: fixed;
  padding-bottom: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #F5F5F5;
`;

export const ActionContentWrapper = styled.div`
  background: #F5F5F5;
  padding: 0 20px;

  button {
    margin-top: 15px;
  }
`;
