import React from 'react';
import {
  Row, Col, Form,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { ButtonWrapper, Wrapper } from './styles';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import StyledInput from '../CustomAntdComponents/StyledInput';
import StyledDatePicker from '../CustomAntdComponents/StyledDatePicker';
import { allowedAgedRange, titleOptions } from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';

type Props = {
  onNextPassenger: () => void,
  field: any,
  showNextTrigger?: boolean
  passengerType: string
}
function RoadPassengerForm({
  onNextPassenger, field, showNextTrigger, passengerType,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const isAdult = passengerType === 'ADT';
  const allowedDOBDates: RangePickerProps['disabledDate'] = (current) => allowedAgedRange(current, passengerType);

  return (
    <Wrapper>
      <Row gutter={16}>
        <Col xs={24} lg={6}>
          <Form.Item
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'namePrefix']}
            fieldKey={[field.key, 'namePrefix']}
            rules={[{ required: true, message: t(translations).validations.title }]}
          >
            <StyledSelect options={titleOptions} placeholder={t(translations).input.title} />
          </Form.Item>
        </Col>

        <Col xs={0} lg={12}>
          <div>&nbsp;</div>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
              /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'firstName']}
            fieldKey={[field.key, 'firstName']}
            rules={[{ required: true, message: t(translations).validations.firstName }]}
          >
            <StyledInput placeholder={t(translations).input.firstName} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
              /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'lastName']}
            fieldKey={[field.key, 'lastName']}
            rules={[{ required: true, message: t(translations).validations.lastName }]}
          >
            <StyledInput placeholder={t(translations).input.lastName} />
          </Form.Item>
        </Col>

        {
          isAdult && (
            <>
              <Col xs={24} lg={12}>
                <Form.Item
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...field}
                  name={[field.name, 'email']}
                  fieldKey={[field.key, 'email']}
                  rules={[{ required: true, message: t(translations).validations.email },
                    { type: 'email', message: t(translations).validations.emailInvalid }]}
                >
                  <StyledInput placeholder={t(translations).input.email} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...field}
                  name={[field.name, 'phoneNumber']}
                  fieldKey={[field.key, 'phoneNumber']}
                  rules={[{ required: true, message: t(translations).validations.phone }]}
                >
                  <StyledInput placeholder={t(translations).validations.phone} type="tel" />
                </Form.Item>
              </Col>
            </>
          )
        }

        <Col xs={24} lg={12}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'dob']}
            fieldKey={[field.key, 'dob']}
            rules={[{ required: true, message: t(translations).validations.dob }]}
          >
            <StyledDatePicker placeholder={t(translations).input.dob} disabledDate={allowedDOBDates} />
          </Form.Item>
        </Col>

        {
          isAdult && (
            <>
              <Col xs={24} lg={12}>
                <Form.Item
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...field}
                  name={[field.name, 'nextOfKinName']}
                  fieldKey={[field.key, 'nextOfKinName']}
                  rules={[{ required: true, message: t(translations).validations.nok }]}
                >
                  <StyledInput placeholder={t(translations).input.nok} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...field}
                  name={[field.name, 'nextOfKinEmail']}
                  fieldKey={[field.key, 'nextOfKinEmail']}
                  rules={[{ required: true, message: t(translations).validations.email },
                    { type: 'email', message: t(translations).validations.emailInvalid }]}
                >
                  <StyledInput placeholder={t(translations).input.nokEmail} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...field}
                  name={[field.name, 'nextOfKinPhoneNumber']}
                  fieldKey={[field.key, 'nextOfKinPhoneNumber']}
                  rules={[{ required: true, message: t(translations).validations.phone }]}
                >
                  <StyledInput placeholder={t(translations).input.nokPhone} type="tel" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...field}
                  name={[field.name, 'address']}
                  fieldKey={[field.key, 'address']}
                  rules={[{ required: true, message: t(translations).validations.address }]}
                >
                  <StyledInput placeholder={t(translations).input.address} />
                </Form.Item>
              </Col>
            </>
          )
        }

        {showNextTrigger && (
        <Col xs={{ span: 24 }} lg={{ span: 6, offset: 18 }}>
          <ButtonWrapper>
            <StyledButton mode="secondary" size="large" onClick={onNextPassenger}>Next</StyledButton>
          </ButtonWrapper>
        </Col>
        )}
      </Row>
    </Wrapper>
  );
}

RoadPassengerForm.defaultProps = {
  showNextTrigger: true,
};
export default RoadPassengerForm;
