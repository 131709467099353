import styled from 'styled-components';
import { GREY_10, PRIMARY } from '../../utils/colors';

export const Wrapper = styled.section`
  padding: 0 20px;

  .ant-space {
    display: flex;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  margin: 0 auto;
  border-radius: 50%;
  background: ${GREY_10};
  border: solid 4px ${PRIMARY};
`;

export const NavImageIcon = styled.div`
  padding: 10px 10px 5px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
 display: inline-block;
`;

export const NavWrapper = styled.section`
  text-align: right;
  margin: 14px 0 28px;
`;

export const ContentWrapper = styled.section``;

export const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;
  & * {
    padding: 10px 0;
  }
`;

export const ListWrapper = styled.div`
 ${ListItemWrapper} {
   &:last-of-type {
     border-bottom: none;
   }
 }
`;

export const TextWrapper = styled.div`
  margin: 30px auto;
  width: 60%;
`;
