import styled from 'styled-components';
import { GREY_10, PRIMARY, FONT_SIZE } from '../../../utils/colors';

export const Wrapper = styled.div`
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    font-size: calc(13px *  ${FONT_SIZE});
    pointer-events: none;
    left: 12px;
    top: 16px;
    transition: 0.2s ease all;
    line-height: 13px;
  }

  .as-placeholder {
    color: gray;
    font-size: ${FONT_SIZE > 1 ? '14px' : '11px'}!important;
  }

  .as-label {
    top:  calc(-8px * ${FONT_SIZE});
    font-size: ${FONT_SIZE > 1 ? '14px' : '11px'}!important;
    background: ${GREY_10};
    padding: 0 4px;
    margin-left: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
  }
  
  .ant-picker {
    width: 100%;
    border: none !important;
  }

  .ant-picker-focused {
    box-shadow: none !important;
    border: none !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
  {
    background: ${PRIMARY};
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: solid 1px ${PRIMARY};
  }
`;
