import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useStore } from '../../store/global/appStore';
import { generateMultiCityFlightRoutes, getAirportOptions } from './helper';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { flightTypes, getDometicAirporUrl } from '../../screens/Home/constants';
import { getAPI } from '../../utils/api';
import {
  GET_INTERNATIONAL_AIRPORTS_API_URL,
} from '../../utils/constants';
import { tripModes, tripModesMultiCity } from '../../screens/Search/constant';
import translations from '../../screens/Search/translations';
import { useI18nContext } from '../../utils/I18n';
import { getDisabledDateRange, getExtraData, getStartOfDay } from '../../utils/helpers';
import FlightSearchMobileView from './FlighSearchMobileView';
import FlightSearchWebView from './FlightSearchWebView';

function FlightSearch() {
  const {
    state: { t },
  } = useI18nContext();
  const [checkReturnDate, setCheckReturnDate] = useState(false);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { domesticAirports, internationalAirports, setAirports } = useStore();
  const {
    formData, updateFormData,
  } = useFlightSearchStore();

  // const { tripType, flightType } = formData;
  const [form] = Form.useForm();

  const isDomestic = formData.flightType === flightTypes[1].value;
  const extraData = getExtraData();
  const MultiCityMaxRoute = extraData.MultiCityMaxRoute ? extraData.MultiCityMaxRoute : 5;
  const airportOptions = getAirportOptions(isDomestic ? domesticAirports : internationalAirports);
  const isRoundTrip = formData.tripType === 'round_trip';
  const isMultiCity = formData.tripType === 'multi_city';

  const getInternationalAirports = useCallback(async () => {
    const airportsApi = await getAPI(GET_INTERNATIONAL_AIRPORTS_API_URL);
    setAirports(airportsApi.data, 'internationalAirports');
  }, [setAirports]);

  const getDomesticAirports = useCallback(async () => {
    const airportsApi = await getAPI(getDometicAirporUrl());
    setAirports(airportsApi.data, 'domesticAirports');
  }, [setAirports]);

  const onFinish = (values) => {
    if (isMultiCity) {
      const multiCityData = generateMultiCityFlightRoutes(values.items);
      // const multiCityData = value.items;
      updateFormData({ multiCity: multiCityData });
    }

    navigate('/search-listings');
  };

  useEffect(() => {
    if ((isDomestic && domesticAirports.length === 0)) {
      getDomesticAirports();
    }

    if ((!isDomestic && internationalAirports.length === 0)) {
      getInternationalAirports();
    }
  }, [domesticAirports, internationalAirports, isDomestic, getDomesticAirports, getInternationalAirports]);

  const onFormInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const onDateInputChange = (value: any, name: string) => {
    updateFormData({ [name]: value });
  };

  const checkReturningDate = (_: any, value: any) => {
    if (!isRoundTrip || !formData.DepartureDate) {
      return Promise.resolve();
    }

    if (!value) {
      return Promise.reject(new Error(t(translations).validations.destinationDate));
    }

    if (value.isBefore(formData.DepartureDate)) {
      return Promise.reject(new Error(t(translations).validations.invalidDestinationDate));
    }
    return Promise.resolve();
  };

  const validateDestination = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error(t(translations).validations.destination));
    }

    if (formData.OriginLocationCode === value) {
      return Promise.reject(new Error(t(translations).validations.invalidDestination));
    }

    return Promise.resolve();
  };

  // const formInitialValues = {
  //   FlightClass,
  //   DepartureDate,
  //   ReturnDate,
  //   tripType,
  //   flightType,
  //   numberOfAdults,
  //   DestinationLocationCode,
  //   OriginLocationCode,
  //   numberOfInfants,
  //   numberOfChildren,
  //   sameAirline,
  // };

  const disabledReturnDate: RangePickerProps['disabledDate'] = (current) => getDisabledDateRange(current, getStartOfDay(formData.DepartureDate));

  useEffect(() => {
    setCheckReturnDate(isRoundTrip);
  }, [isRoundTrip, setCheckReturnDate]);

  useEffect(() => {
    form.validateFields(['ReturnDate']);
  }, [checkReturnDate, form]);

  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [browserWidth]);

  const flightModes = formData.flightType === flightTypes[1].value ? tripModes(t(translations)) : tripModesMultiCity(t(translations));

  return (
    <div>
      {browserWidth < 992 && (
      <FlightSearchMobileView
        flightModes={flightModes}
        onFinish={onFinish}
        onFormInputChange={onFormInputChange}
        isMultiCity={isMultiCity}
        isDomestic={isDomestic}
        isRoundTrip={isRoundTrip}
        airportOptions={airportOptions}
        checkReturningDate={checkReturningDate}
        disabledReturnDate={disabledReturnDate}
        validateDestination={validateDestination}
        onDateInputChange={onDateInputChange}
        formData={formData}
        MultiCityMaxRoute={MultiCityMaxRoute}
      />
      )}
      {' '}
      {browserWidth > 992 && (
      <FlightSearchWebView
        flightModes={flightModes}
        onFinish={onFinish}
        onFormInputChange={onFormInputChange}
        isMultiCity={isMultiCity}
        isDomestic={isDomestic}
        isRoundTrip={isRoundTrip}
        airportOptions={airportOptions}
        checkReturningDate={checkReturningDate}
        disabledReturnDate={disabledReturnDate}
        validateDestination={validateDestination}
        onDateInputChange={onDateInputChange}
        formData={formData}
        MultiCityMaxRoute={MultiCityMaxRoute}
      />
      )}
    </div>
  );
}

export default FlightSearch;
