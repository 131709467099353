export const generateOptions = (pluralText: string, singularText: string, startingIndex = 0): SelectOption[] => {
  const options: SelectOption[] = [];
  let pointer = startingIndex;

  while (pointer <= 9) {
    options.push({
      label: pointer === 1 ? `1 ${singularText}` : `${pointer} ${pluralText}`,
      value: pointer,
    });
    pointer += 1;
  }

  return options;
};
export const getAdultsOptions = (pluralText: string, singularText: string): SelectOption[] => generateOptions(pluralText, singularText, 1);
export const getChildrenOptions = (pluralText: string, singularText: string): SelectOption[] => generateOptions(pluralText, singularText);
export const getInfantOptions = (pluralText: string, singularText: string): SelectOption[] => generateOptions(pluralText, singularText);

export const getSeatName = (code: string): string => {
  switch (code) {
    case 'ADT':
      return 'Adult';
    case 'INF':
      return 'Infant';
    case 'CHD':
      return 'Child';
    default:
      return code;
  }
};
