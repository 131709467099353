import React from 'react';
import {
  PillItemWrapper,
  PillWrapper,
} from '../../screens/Search/styles';
import { flightClassOptions } from './constant';
import { useI18nContext } from '../../utils/I18n';
import searchTranslations from '../../screens/Search/translations';

type Props = {
  flightClassOption: string,
  options?: DataOption[],
  filled?: boolean,
  itemFilled?: boolean,
  setFlightClassOption: (arg: string) => void
}

function SeatType({
  flightClassOption, setFlightClassOption, filled, itemFilled, options,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();

  const filterOptions = options || flightClassOptions(t(searchTranslations));
  return (
    <PillWrapper filled={filled}>
      {filterOptions && filterOptions.map((option) => (
        <PillItemWrapper
          filled={itemFilled}
          active={flightClassOption === option.value}
          onClick={() => setFlightClassOption(option.value)}
          key={option.id}
        >
          {option.name}
        </PillItemWrapper>
      ))}
    </PillWrapper>
  );
}

SeatType.defaultProps = {
  filled: false,
  itemFilled: false,
  options: null,
};

export default SeatType;
