import React, { useState, useEffect } from 'react';
import {
  Row, Col, Form,
} from 'antd';
import { getCountries, getStates } from 'country-state-picker';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { ButtonWrapper, Wrapper } from './styles';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import StyledDatePicker from '../CustomAntdComponents/StyledDatePicker';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import StyledInput from '../CustomAntdComponents/StyledInput';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { flightTypes } from '../../screens/Home/constants';
import { allowedAgedRange, titleOptions } from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PassengerDetails/translations';

type Props = {
  onNextPassenger: () => void,
  field: any,
  showNextTrigger?: boolean,
  passengerType: string,
}
function PassengerForm({
  onNextPassenger, field, showNextTrigger, passengerType,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData,
  } = useFlightSearchStore();
  const isDomestic = formData.flightType === flightTypes[1].value;
  const [countryStates, setCountryStates] = useState<SelectOption[]>([]);
  const countryOptions = getCountries().map(({ name, code }: any) => ({
    label: name,
    value: code,
  }));

  const handleChangeCountry = (value: string | number) => {
    // eslint-disable-next-line no-console
    const sts = getStates(String(value)).map((s: string) => ({
      label: s,
      value: s,
    }));

    setCountryStates(sts);
  };

  useEffect(() => {
    const states = getStates('ng').map((s: string) => ({
      label: s,
      value: s,
    }));

    setCountryStates(states);
  }, []);

  const disablePastDates: RangePickerProps['disabledDate'] = (current) => current && current < moment().endOf('day');
  const allowedDOBDates: RangePickerProps['disabledDate'] = (current) => allowedAgedRange(current, passengerType);

  return (
    <Wrapper>
      <Row gutter={[16, 0]}>
        <Col xs={24} lg={6}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'title']}
            fieldKey={[field.key, 'title']}
            rules={[{ required: true, message: t(translations).validations.title }]}
          >
            <StyledSelect options={titleOptions} placeholder={t(translations).input.title} />
          </Form.Item>
        </Col>
        {/* <Col xs={12} lg={6}> */}
        {/*   <Form.Item */}
        {/*     {...field} */}
        {/*     name={[field.name, 'gender']} */}
        {/*     fieldKey={[field.key, 'gender']} */}
        {/*     rules={[{ required: true, message: 'Please select gender' }]} */}
        {/*   > */}
        {/*     <StyledSelect options={filteredGenderOptions} placeholder="Gender" /> */}
        {/*   </Form.Item> */}
        {/* </Col> */}

        {/* >= LG Devices only */}
        <Col xs={0} lg={9}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'country']}
            fieldKey={[field.key, 'country']}
            rules={[{ required: true, message: t(translations).validations.country }]}
          >
            <StyledSelect
              options={countryOptions}
              showSearch
              placeholder={t(translations).input.country}
              onChange={handleChangeCountry}
            />
          </Form.Item>
        </Col>
        <Col xs={0} lg={9}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'city']}
            fieldKey={[field.key, 'city']}
            rules={[{ required: true, message: t(translations).validations.city }]}
          >
            <StyledSelect options={countryStates} placeholder={t(translations).input.city} showSearch />
          </Form.Item>
        </Col>
        {/* End of >= LG Devices */}

        <Col xs={24} lg={12}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'firstName']}
            fieldKey={[field.key, 'firstName']}
            rules={[{ required: true, message: t(translations).validations.firstName }]}
          >
            <StyledInput placeholder={t(translations).input.firstName} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'lastName']}
            fieldKey={[field.key, 'lastName']}
            rules={[{ required: true, message: t(translations).validations.lastName }]}
          >
            <StyledInput placeholder={t(translations).input.lastName} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'email']}
            fieldKey={[field.key, 'email']}
            rules={[{ required: true, message: t(translations).validations.email },
              { type: 'email', message: t(translations).validations.emailInvalid }]}
          >
            <StyledInput placeholder={t(translations).input.email} />
          </Form.Item>
        </Col>
        <Col xs={12} lg={8}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'phoneNumber']}
            fieldKey={[field.key, 'phoneNumber']}
            rules={[{ required: true, message: t(translations).validations.phone }]}
          >
            <StyledInput placeholder={t(translations).input.phone} type="tel" />
          </Form.Item>
        </Col>
        <Col xs={12} lg={8}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'dob']}
            fieldKey={[field.key, 'dob']}
            rules={[{ required: true, message: t(translations).validations.dob }]}
          >
            <StyledDatePicker placeholder={t(translations).input.dob} disabledDate={allowedDOBDates} />
          </Form.Item>
        </Col>

        {/* small Devices */}
        <Col xs={12} lg={0}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'country']}
            fieldKey={[field.key, 'country']}
            rules={[{ required: true, message: t(translations).validations.country }]}
          >
            <StyledSelect showSearch options={countryOptions} placeholder={t(translations).input.country} onChange={handleChangeCountry} />
          </Form.Item>
        </Col>
        <Col xs={12} lg={0}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'city']}
            fieldKey={[field.key, 'city']}
            rules={[{ required: true, message: t(translations).validations.city }]}
          >
            <StyledSelect options={countryStates} placeholder={t(translations).input.city} showSearch />
          </Form.Item>
        </Col>
        {/*   END of small devices only */}

        {
          !isDomestic && (
          <>
            <Col xs={24} lg={8}>
              <Form.Item
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...field}
                name={[field.name, 'passportNumber']}
                fieldKey={[field.key, 'passportNumber']}
                rules={[{ required: true, message: t(translations).validations.passportNumber }]}
              >
                <StyledInput placeholder={t(translations).input.passportNumber} />
              </Form.Item>
            </Col>

            <Col xs={12} lg={8}>
              <Form.Item
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...field}
                name={[field.name, 'issuanceCountry']}
                fieldKey={[field.key, 'issuanceCountry']}
                rules={[{ required: true, message: t(translations).validations.issuanceCountry }]}
              >
                <StyledSelect
                  showSearch
                  options={countryOptions}
                  placeholder={t(translations).input.issuanceCountry}
                  onChange={handleChangeCountry}
                />
              </Form.Item>
            </Col>

            <Col xs={12} lg={8}>
              <Form.Item
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...field}
                name={[field.name, 'passportExpiration']}
                fieldKey={[field.key, 'passportExpiration']}
                rules={[{ required: true, message: t(translations).validations.passportExpiration }]}
              >
                <StyledDatePicker placeholder={t(translations).input.passportExpiration} disabledDate={disablePastDates} />
              </Form.Item>
            </Col>
          </>
          )
        }

        <Col xs={24} lg={12}>
          <Form.Item
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            name={[field.name, 'address']}
            fieldKey={[field.key, 'address']}
            rules={[{ required: true, message: t(translations).validations.address }]}
          >
            <StyledInput placeholder={t(translations).input.address} />
          </Form.Item>
        </Col>

        {showNextTrigger && (
          <Col xs={24} lg={12}>
            <ButtonWrapper>
              <StyledButton mode="secondary" size="large" onClick={onNextPassenger}>
                {t(translations).next}
              </StyledButton>
            </ButtonWrapper>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
}

PassengerForm.defaultProps = {
  showNextTrigger: true,
};
export default PassengerForm;
