import React from 'react';
import { Card, Typography, Space } from 'antd';
import { ItemsWrapper, Wrapper } from './styles';
import { StyledParagraph } from '../CustomAntdComponents/Typography';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { formatNumberAsCurrency } from '../../utils/helpers';
import { calculateFlightTotalFare } from '../AvailableFlights/helper';
import { GiveMeSpace } from '../CustomAntdComponents/Utitlities';
import { useI18nContext } from '../../utils/I18n';
import translations from './translations';

const { Paragraph } = Typography;

function PaymentSummary() {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData: { flightType },
    selectedFlight,
  } = useFlightSearchStore();

  const isDomestic = flightType === 'domestic';
  const { totalAmount, totalCharges, totalTicketAmount } = calculateFlightTotalFare(selectedFlight, isDomestic);

  return (
    selectedFlight ? (
      <Wrapper>
        <Space direction="vertical" size="small">
          <Paragraph strong>{t(translations).summary}</Paragraph>
          <GiveMeSpace size="small" />
          <Card>
            <ItemsWrapper>
              <StyledParagraph color="secondary" size="small" strong>
                {t(translations).ticketAmount}
              </StyledParagraph>

              <StyledParagraph strong>
                {formatNumberAsCurrency(totalTicketAmount, selectedFlight[0]?.Currency)}
              </StyledParagraph>
            </ItemsWrapper>

            {
              isDomestic && (
              <ItemsWrapper>
                <StyledParagraph color="secondary" size="small" strong>
                  {t(translations).convenience}
                </StyledParagraph>

                <StyledParagraph strong>
                  {formatNumberAsCurrency(totalCharges, selectedFlight[0]?.Currency)}
                </StyledParagraph>
              </ItemsWrapper>
              )
            }
          </Card>

          <Card size="small">
            <ItemsWrapper>
              <StyledParagraph color="primary" size="small" strong>
                {t(translations).totalAmount}
              </StyledParagraph>

              <StyledParagraph strong size="medium">
                {formatNumberAsCurrency(totalAmount, selectedFlight[0]?.Currency)}
              </StyledParagraph>
            </ItemsWrapper>
          </Card>
        </Space>

      </Wrapper>
    ) : <div>&nbsp;</div>
  );
}

export default PaymentSummary;
