import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Button, Card, Col, Modal, Row,
} from 'antd';
import HeaderNavigation from '../../components/HeaderNavigation';
import {
  ActionWrapper,
  ButtonsWrapper,
  ContentWrapper,
  Space,
  Wrapper,
} from './styles';
import PaymentSummary from '../../components/PaymentSummary';
// import AccountSelector from '../../components/AccountSelector';
import StyledDrawer from '../../components/CustomAntdComponents/StyledDrawer';
import ModalContent from '../../components/ModalContent';
import {
  StyledButton,
} from '../../components/CustomAntdComponents/StyledButton';

import { ReactComponent as PayIcon } from '../../assets/images/pay.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';
import Header from '../../components/Header';
import FlightItinerary from '../../components/FlightItinerary';
import TripSummary from '../../components/TripSummary';
import { StyledTitle } from '../../components/CustomAntdComponents/Typography';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import {
  formatNumberAsCurrency,
  isMobileScreen,
  postMessage,
} from '../../utils/helpers';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import BusItinerary from '../../components/BusItinerary';
import TrainItinerary from '../../components/TrainItinerary';
import RoadTripSummary from '../../components/TripSummary/RoadTripSummary';
import RoadPaymentSummary
  from '../../components/PaymentSummary/RoadPaymentSummary';
import { calculateTotalFare } from '../../components/AvailableBuses/helper';
import { calculateTotalFare as calculateWaterTotalFare } from '../../components/AvailableBoats/helper';
import { calculateTotalFare as calculateTrainTotalFare } from '../../components/AvailableTrains/helper';
import {
  calculateFlightTotalFare,
} from '../../components/AvailableFlights/helper';
import { useBookingStore } from '../../store/booking/bookingStore';
import BoatItinerary from '../../components/BoatItinerary';
import WaterTripSummary from '../../components/TripSummary/WaterTripSummary';
import WaterPaymentSummary
  from '../../components/PaymentSummary/WaterPaymentSummary';
import { useWaterSearchStore } from '../../store/waterSearch/waterSearchStore';
import RailTripSummary from '../../components/TripSummary/RailTripSummary';
import { useRailSearchStore } from '../../store/railSearch/railSearchStore';
import RailPaymentSummary
  from '../../components/PaymentSummary/RailPaymentSummary';
import { useI18nContext } from '../../utils/I18n';
import translations from './translations';
import { EXTERNAL_PAYMENT_CHANNEL } from '../../utils/constants';

function Payment() {
  const {
    state: { t },
  } = useI18nContext();
  const { tripType } = useParams();
  const {
    selectedFlight,
    formData,
  } = useFlightSearchStore();
  const {
    bookingPayload,
  } = useBookingStore();
  const {
    selectedResult,
    selectedBus,
  } = useRoadSearchStore();
  const {
    selectedBoat,
  } = useWaterSearchStore();
  const {
    selectedTrain,
  } = useRailSearchStore();

  const [confirmationVisibility, setConfirmationVisibility] = useState(false);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const [successModalVisibility, setSuccessModalVisibility] = useState(false);
  const isDomestic = formData.flightType === 'domestic';
  const isMultiCity = formData.tripType === 'multi_city';

  const flightConfirmationText = () => (selectedFlight ? (
    <>
      {' '}
      {t(translations).confirmationText}
      &nbsp;
      <mark>{formatNumberAsCurrency(calculateFlightTotalFare(selectedFlight, isDomestic).totalAmount, selectedFlight[0]?.Currency)}</mark>
      {' '}
      from&nbsp;
      <mark>
        {formData.OriginLocationCode}
        {' '}
        -
        {' '}
        {formData.DestinationLocationCode}
      </mark>
    </>
  ) : '');

  const roadConfirmationText = () => {
    const totalPrice = calculateTotalFare(selectedBus ? selectedBus.PassengerFares : []);

    return (selectedResult) ? (
      <>
        {t(translations).confirmationText}
        &nbsp;
        <mark>{formatNumberAsCurrency(totalPrice.totalAmount, selectedResult.Currency)}</mark>
        {' '}
        {t(translations).from}
        &nbsp;
        <mark>
          {selectedResult.DepartureTerminal}
          {' '}
          -
          {' '}
          {selectedResult.ArrivalTerminal}
        </mark>
      </>
    ) : '';
  };

  const waterConfirmationText = () => {
    const totalPrice = calculateWaterTotalFare(selectedBoat ? selectedBoat.PassengerFares : []);

    return (selectedBoat) ? (
      <>
        {t(translations).confirmationText}
        &nbsp;
        <mark>{formatNumberAsCurrency(totalPrice.totalAmount)}</mark>
        {' '}
        {t(translations).from}
        &nbsp;
        <mark>
          V/ISL
          {' '}
          -
          {' '}
          EKO ATL
        </mark>
      </>
    ) : '';
  };

  const railConfirmationText = () => {
    const totalPrice = calculateTrainTotalFare(selectedTrain ? selectedTrain.PassengerFares : []);

    return (selectedTrain) ? (
      <>
        {t(translations).confirmationText}
        &nbsp;
        <mark>{formatNumberAsCurrency(totalPrice.totalAmount)}</mark>
        {' '}
        {t(translations).from}
        &nbsp;
        <mark>
          LOS
          {' '}
          -
          {' '}
          ABJ
        </mark>
      </>
    ) : '';
  };

  const confirmationModalText = () => {
    const textSegments = [];
    if (tripType === 'road') {
      textSegments.push(roadConfirmationText());
    }
    if (tripType === 'flight') {
      textSegments.push(flightConfirmationText());
    }
    if (tripType === 'water') {
      textSegments.push(waterConfirmationText());
    }
    if (tripType === 'rail') {
      textSegments.push(railConfirmationText());
    }

    const p2 = t(translations).proceedText;
    textSegments.push(p2);

    return textSegments.map((textSegment, key) => ({
      key, node: textSegment,
    }));
  };

  const handleTriggerPayment = () => {
    if (isMobileScreen()) {
      setConfirmationVisibility(true);
    } else {
      setConfirmModalVisibility(true);
    }
  };

  const hideConfirmations = () => {
    setConfirmationVisibility(false);
    setConfirmModalVisibility(false);
  };

  const proceedPayment = () => {
    // Notify App
    // Check payment channel
    const auth: UserAuth = JSON.parse(localStorage.getItem('trips_auth') || '{}');
    if (auth.ExtraData?.PaymentChannel === EXTERNAL_PAYMENT_CHANNEL) {
      postMessage(bookingPayload);
      hideConfirmations();
    }
  };

  const ConfirmationModalButton = (
    <ButtonsWrapper>
      <StyledButton block mode="secondary" onClick={hideConfirmations}>
        {t(translations).cancel}
      </StyledButton>
      <Button block type="primary" onClick={proceedPayment}>
        {t(translations).proceed}
      </Button>
    </ButtonsWrapper>
  );

  const SuccessModalButton = (
    <Link to={`/payment-receipt/${tripType}`}>
      <ButtonsWrapper>
        <Button block type="primary" size="large">
          {t(translations).getReceipt}
        </Button>
      </ButtonsWrapper>
    </Link>
  );

  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>

      <div className="container">
        <Wrapper>
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name={t(translations).makePayment} />
            </Col>
            <Col xs={0} lg={24}>
              <HeaderNavigation />
            </Col>
          </Row>

          <Row gutter={[32, 16]}>
            <Col xs={0} lg={8}>
              <StyledTitle level={4}>
                {t(translations).itineraryTitle}
              </StyledTitle>
            </Col>

            <Col xs={0} lg={16}>
              <StyledTitle level={4}>
                {t(translations).makePayment}
              </StyledTitle>
            </Col>

            <Col span={24}>
              <Row gutter={32}>
                <Col xs={tripType === 'flight' ? 24 : 0} lg={8}>
                  {tripType === 'flight' && <FlightItinerary showSubmitButton={false} showTotalAmount={false} /> }
                  {tripType === 'road' && <BusItinerary showSubmitButton={false} /> }
                  {tripType === 'water' && <BoatItinerary showSubmitButton={false} /> }
                  {tripType === 'rail' && <TrainItinerary showSubmitButton={false} /> }
                </Col>

                <Col xs={24} lg={16}>
                  <ContentWrapper>
                    <Row>
                      <Col xs={tripType === 'flight' ? 0 : 24} lg={0}>
                        <Card style={{ marginBottom: '16x' }}>
                          {/* {tripType === 'flight' && (

                            isMultiCity && formData?.multiCity?.length > 0 ? formData?.multiCity?.map((item) => (
                              <TripSummary
                                DestinationLocationCode={item.DestinationLocationCode}
                                OriginLocationCode={item.OriginLocationCode}
                                DepartureDate={item.DepartureDate}
                              />
                            ))

                              : (
                                <TripSummary
                                  DestinationLocationCode={formData.DestinationLocationCode}
                                  OriginLocationCode={formData.OriginLocationCode}
                                  DepartureDate={formData.DepartureDate}
                                  ReturnDate={formData.ReturnDate}
                                />
                              )
                          ) } */}
                          {tripType === 'road' && <RoadTripSummary /> }
                          {tripType === 'water' && <WaterTripSummary /> }
                          {tripType === 'rail' && <RailTripSummary /> }
                        </Card>
                      </Col>

                      <Col span={24}>

                        {tripType === 'road' && <RoadPaymentSummary />}
                        {tripType === 'flight' && <PaymentSummary />}
                        {tripType === 'water' && <WaterPaymentSummary />}
                        {tripType === 'rail' && <RailPaymentSummary />}

                        <Space />

                        {/* <AccountSelector /> */}

                        <ActionWrapper>
                          <Row>
                            <Col lg={{ span: 8, offset: 16 }} xs={{ span: 24, offset: 0 }}>
                              <Button block type="primary" size="large" onClick={handleTriggerPayment}>
                                {t(translations).makePayment}
                              </Button>
                            </Col>
                          </Row>

                        </ActionWrapper>
                      </Col>
                    </Row>
                  </ContentWrapper>
                </Col>
              </Row>
            </Col>
          </Row>

          <StyledDrawer
            visibility={confirmationVisibility}
            onClose={() => setConfirmationVisibility(false)}
            footer={ConfirmationModalButton}
          >
            <ModalContent Icon={<PayIcon />} title={t(translations).confirmTitle} messages={confirmationModalText()} />
          </StyledDrawer>

          <Modal
            centered
            closable={false}
            maskClosable={false}
            visible={confirmModalVisibility}
            footer={null}
            onCancel={() => setConfirmModalVisibility(false)}
            width={400}
            getContainer={document.getElementById('app-container') as HTMLElement}
          >
            <ModalContent Icon={<PayIcon />} title={t(translations).confirmTitle} messages={confirmationModalText()}>
              {ConfirmationModalButton}
            </ModalContent>
          </Modal>

          <StyledDrawer
            visibility={successVisibility}
            onClose={() => setSuccessVisibility(false)}
            footer={SuccessModalButton}
          >
            <ModalContent
              bordered
              Icon={<CheckIcon />}
              title="Successful !!"
              messages={[{ key: 1, node: t(translations).successText }]}
            />
          </StyledDrawer>

          <Modal
            centered
            closable={false}
            maskClosable={false}
            visible={successModalVisibility}
            footer={null}
            onCancel={() => setSuccessModalVisibility(false)}
            width={400}
            getContainer={document.getElementById('app-container') as HTMLElement}
          >
            <ModalContent Icon={<PayIcon />} title={t(translations).successTitle} messages={[{ key: 1, node: t(translations).successText }]}>
              {SuccessModalButton}
            </ModalContent>
          </Modal>
        </Wrapper>
      </div>
    </>
  );
}

export default Payment;
