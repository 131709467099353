import React, { useState, ChangeEvent } from 'react';
import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { Wrapper } from './styles';

type Props = {
  placeholder?: string,
  onChange?: (value: string) => void;
  filled?: boolean;
  size?: SizeType,
  type?: string,
}
function StyledInput({
  placeholder, size, filled, onChange, type,
}: Props) {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const hasValue = focus || (value && value.length !== 0);
  const labelClass = hasValue ? 'label as-label' : 'label as-placeholder';

  const handleChange = (input: ChangeEvent<HTMLInputElement>) => {
    input.preventDefault();
    setValue(input.target.value);
    if (onChange) { onChange(input.target.value); }
  };

  return (
    <Wrapper
      filled={filled}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input size={size} onChange={handleChange} defaultValue={value} type={type} />
      <span className={labelClass}>
        {hasValue ? placeholder : placeholder}
      </span>
    </Wrapper>
  );
}

StyledInput.defaultProps = {
  size: 'large',
  placeholder: undefined,
  onChange: undefined,
  filled: undefined,
  type: 'text',
};
export default StyledInput;
