import create from 'zustand';
import { RoadSearchStore } from './types';

interface RoadSearchStoreState {
  formData: RoadSearchStore;
  selectedBus: AvailableBus | null,
  selectedResult: BusResultItem | null,
  loading: boolean;
  updateFormData: (data: any) => void,
  updateStoreData: (data: any) => void,
  resetFormData: () => void,
}

export const initialFormData: RoadSearchStore = {
  TripType: 'one_way',
  numberOfAdults: 1,
  numberOfChildren: 0,
  numberOfInfants: 0,
  OrderbyDepartureTime: 'ASC',
  OrderByPrice: 'ASC',
  Currency: 'NGN',
  PageSize: 5000,
  From: 0,
  selectedSeats: [],
};

export const useRoadSearchStore = create<RoadSearchStoreState>((set) => ({
  // initial state
  formData: initialFormData,
  loading: false,
  selectedBus: null,
  selectedResult: null,
  updateFormData: (data: any) => {
    set((state) => ({
      ...state,
      formData: {
        ...state.formData,
        ...data,
      },
    }));
  },
  updateStoreData: (data: any) => {
    set((state) => ({
      ...state,
      ...data,
    }));
  },
  resetFormData: () => {
    set((state) => ({
      ...state,
      formData: {
        ...initialFormData,
      },
    }));
  },
}));
