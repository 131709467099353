/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography, Row, Col, Space, Button, Empty, Card,
} from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import {
  ColumnItem,
  HeaderAirlineDetails,
  TextWrapper,
  RowWrapper,
  DetailEntryWrapper, DetailTitleWrapper, FlightDetailsWrapper,
} from './styles';
import {
  StyledParagraph,
} from '../CustomAntdComponents/Typography';

import clockIcon from '../../assets/images/clock.svg';
import {
  CentralizedContent, GiveMeSpace,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { getAirlineLogo } from './helper';
import SkeletonLayout from './SkeletonLayout';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { ReactComponent as FlightIcon } from '../../assets/images/flight.svg';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import TripSummaryMoreDetails from '../TripSummary/TripSummaryMoreDetails';

type RenderFlightProps ={
    result: AirlineResultItem
    handleSelectFlight: (arg: AirlineResultItem) => void
    isReturning: boolean
    selectedFlights: AirlineResultItem[]
}

function RenderFlightTripDetailsDomestic({
  result, isReturning, handleSelectFlight, selectedFlights,
}: RenderFlightProps) {
  const flight = result.FlightDetails[0];
  const [viewDetails, setViewDetails] = useState(false);
  const {
    state: { t },
  } = useI18nContext();

  const {
    formData: { tripType },
  } = useFlightSearchStore();
  const isRoundTrip = tripType === 'round_trip';

  const renderEntryDetails = (flightDetails: FlightDetails, index) => (
    <Col lg={12} key={`${flightDetails.ArrivalAirportCode}${index}`} style={{ marginBottom: '10px' }}>
      <Card size="small">
        <TripSummaryMoreDetails
          showChangeButton={false}
          itinerary
          DestinationLocationCode={flightDetails.ArrivalAirportCode}
          OriginLocationCode={flightDetails.DepartureAirportCode}
          DepartureDate={flightDetails.DepartureDate}
          ReturnDate={flightDetails.ArrivalDate}
          flightDetails={flightDetails}
        />

      </Card>
    </Col>
  );

  const displatFlightRouteIcons = (flightDetail:FlightDetails) => (
    <Row align="middle" justify="center" gutter={4} style={{ padding: '15px  10px' }}>

      <Col>
        <StyledParagraph>
          {flight.DepartureAirportCode}
        </StyledParagraph>
      </Col>
      <Col>
        <FlightIcon />
      </Col>
      <Col>
        <StyledParagraph>
          {flightDetail.ArrivalAirportCode}
        </StyledParagraph>
      </Col>
    </Row>
  );

  return (
    <RowWrapper key={result.Properties.RecommendationID}>
      <Row gutter={4}>
        <Col xs={24} lg={6} style={{ display: 'flex' }}>
          <ColumnItem index={1}>
            <Row justify="space-between">
              <Col xs={12} lg={24}>
                <HeaderAirlineDetails>
                  <img
                    src={getAirlineLogo(flight.OperatingAirlineCode)}
                    alt={flight.OperatingAirlineCode}
                  />
                  <StyledParagraph size="small">
                    {flight.OperatingAirlineName}
                  </StyledParagraph>
                </HeaderAirlineDetails>
              </Col>
              <Col lg={0}>
                {displatFlightRouteIcons(result.FlightDetails[0])}
              </Col>
            </Row>
          </ColumnItem>
        </Col>
        <Col xs={24} lg={12} style={{ display: 'flex' }}>
          <ColumnItem index={2}>
            <Row align="middle">
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {t(translations).departure}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph align="right" size="small">
                    {moment(flight.DepartureDate)
                      .format('Do MMMM, h:mma')}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {t(translations).arrival}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph align="right" size="small">
                    {moment(flight.ArrivalDate)
                      .format('Do MMMM, h:mma')}
                  </StyledParagraph>
                </TextWrapper>
              </Col>

              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {t(translations).duration}
                  </StyledParagraph>
                </TextWrapper>
              </Col>
              <Col span={12}>
                <TextWrapper>
                  <StyledParagraph size="small" align="right">
                    <CentralizedContent style={{ justifyContent: 'flex-end' }}>
                      <img src={clockIcon} alt="" />
                          &nbsp;
                      {flight.Duration}
                    </CentralizedContent>
                  </StyledParagraph>
                </TextWrapper>
              </Col>

              {/* <Col lg={12} xs={0}>
                <TextWrapper>
                  <StyledParagraph type="secondary">
                    {pluralizeText(flight.StopOvers, 'Stop', 'Stops')}
                  </StyledParagraph>
                </TextWrapper>
              </Col> */}
              {/* <Col lg={12} xs={0}>
                <TextWrapper>
                  <StyledParagraph size="small" align="right">
                    <CentralizedContent
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <img src={clockIcon} alt="" />
                          &nbsp;
                      {flight.Duration}
                    </CentralizedContent>
                  </StyledParagraph>
                </TextWrapper>
              </Col> */}
            </Row>
          </ColumnItem>
        </Col>
        <Col xs={24} lg={6} style={{ display: 'flex' }}>
          <ColumnItem index={3}>
            <Row>
              {/* >= LG devices */}
              <Col xs={0} lg={24}>
                <Space direction="vertical" size="small">
                  <StyledParagraph strong align="center">
                    {formatNumberAsCurrency((result.TotalFare - result.TotalServiceCharge), result.Currency)}
                  </StyledParagraph>
                  <CentralizedContent>
                    {
                          !viewDetails
                            ? (
                              <Button type="primary" onClick={() => setViewDetails(true)}>
                                {t(translations).view}
                              </Button>
                            ) : isRoundTrip && selectedFlights.length < 1 ? (
                              <Button type="primary" onClick={() => handleSelectFlight(result)}>
                                {t(translations).selectReturn}
                              </Button>
                            )
                              : (
                                <Button type="primary" onClick={() => handleSelectFlight(result)}>
                                  {t(translations).bookFlight}
                                </Button>
                              )
                        }
                    {/* <Button
                            type="primary"
                            onClick={() => handleSelectFlight(result)}
                          >
                            { t(translations).bookFlight}
                          </Button> */}
                  </CentralizedContent>
                </Space>
              </Col>

              {/* <Col xs={12} lg={0}>
                <TextWrapper>
                  <StyledParagraph strong size="small">
                    <VerticalAlignContent>
                      <img src={clockIcon} alt="" />
                          &nbsp;
                      {flight.Duration}
                    </VerticalAlignContent>
                  </StyledParagraph>
                </TextWrapper>
              </Col> */}

              <Col xs={24} lg={0}>
                <TextWrapper style={{ marginBottom: '20px' }}>
                  <StyledParagraph strong align="right" size="large">
                    {formatNumberAsCurrency((result.TotalFare - result.TotalServiceCharge), result.Currency)}
                  </StyledParagraph>
                </TextWrapper>
              </Col>

              <Col xs={24} lg={0}>
                <Button
                  type="primary"
                  onClick={() => handleSelectFlight(result)}
                  block
                >
                  {(!isRoundTrip || (isRoundTrip && selectedFlights.length > 0)) ? t(translations).view : t(translations).selectReturn}
                </Button>

                <GiveMeSpace />
              </Col>

            </Row>
          </ColumnItem>
        </Col>
      </Row>

      { viewDetails && (
      <FlightDetailsWrapper>
        <DetailTitleWrapper>
          <Row justify="space-between">
            <Col>
              <StyledParagraph>
                {isReturning ? t(translations).return : t(translations).departure}
              </StyledParagraph>

            </Col>

            <Col>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setViewDetails(false);
                }}
              />
            </Col>
          </Row>
        </DetailTitleWrapper>

        <DetailEntryWrapper>
          <Row justify="space-between" gutter={8}>
            {
                    renderEntryDetails(flight, 0)
                  }
          </Row>
        </DetailEntryWrapper>
      </FlightDetailsWrapper>
      )}
    </RowWrapper>
  );
}

export default RenderFlightTripDetailsDomestic;
