import React from 'react';
import {
  Card, Col, Row, Space,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  ContentWrapper,
  IconWrapper, ListItemWrapper, ListWrapper, TextWrapper,
} from '../../screens/PaymentReceipt/styles';
import { ReactComponent as PayIcon } from '../../assets/images/pay.svg';
import {
  StyledParagraph,
  StyledTitle,
} from '../CustomAntdComponents/Typography';
import { formatNumberAsCurrency } from '../../utils/helpers';
import { StyledButton } from '../CustomAntdComponents/StyledButton';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { calculateFlightTotalFare } from '../AvailableFlights/helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/PaymentReceipt/translations';

function FlightReceipt() {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData: { flightType },
    selectedFlight,
  } = useFlightSearchStore();

  const isDomestic = flightType === 'domestic';

  return selectedFlight ? (
    <Row>
      <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
        <Card>
          <ContentWrapper>
            <Space direction="vertical">
              <IconWrapper>
                <PayIcon />
              </IconWrapper>

              <StyledTitle level={3} color="primary" align="center">
                {t(translations).success}
              </StyledTitle>

              <ListWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    {t(translations).amount}
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    {formatNumberAsCurrency(calculateFlightTotalFare(selectedFlight, isDomestic).totalAmount, selectedFlight[0]?.Currency)}
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    {t(translations).ref}
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    GJSO10397
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    {t(translations).status}
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    {t(translations).successful}
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    {t(translations).date}
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    {moment().format('ll')}
                  </StyledTitle>
                </ListItemWrapper>
                <ListItemWrapper>
                  <StyledParagraph color="secondary">
                    Customer
                  </StyledParagraph>
                  <StyledTitle level={5} align="right">
                    Jordan Belson
                  </StyledTitle>
                </ListItemWrapper>
              </ListWrapper>
            </Space>
          </ContentWrapper>
        </Card>

        <TextWrapper>
          <StyledParagraph align="center">
            {t(translations).successText}
          </StyledParagraph>
        </TextWrapper>

        <Space direction="vertical" size="middle">
          <StyledButton block size="large" type="primary" ghost>
            {t(translations).downloadText}
          </StyledButton>

          <Link to="/">
            <StyledButton block type="primary" size="large">
              {t(translations).done}
            </StyledButton>
          </Link>
        </Space>
      </Col>
    </Row>
  ) : (
    <div>
      {t(translations).noData}
    </div>
  );
}

export default FlightReceipt;
