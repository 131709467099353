import styled from 'styled-components';

export const Wrapper = styled.section`
  margin-top: 5px;
  
  .ant-typography {
    margin-bottom: 0;
    line-height: 150%;
  }
  
  .ant-space {
    display: flex;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
