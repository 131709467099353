import { getFontSize } from './helpers';

const auth: UserAuth = JSON.parse(localStorage.getItem('trips_auth') || '{}');
export const PRIMARY = auth.ExtraData?.PrimaryColor || '002E63';
export const LIGHT_PRIMARY = auth.ExtraData?.LightPrimaryColor || '#e9faff';
export const FONT_SIZE = getFontSize(auth?.ExtraData?.FontSize) || 1;
// export const FONT_SIZE = 1;
export const WHITE = '#FFFFFF';
export const GREY_5 = '#BABABA';
export const GREY_10 = '#f6f5f5';
export const GREY_15 = '#F1F1F1';
export const GREY = '#949494';
export const BLACK = '#080403';
