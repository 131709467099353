import styled, { css } from 'styled-components';
import { FONT_SIZE, GREY_10 } from '../../../utils/colors';

export const Wrapper = styled.div<{ filled?: boolean}>`
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    font-size: calc(12px *  ${FONT_SIZE});
    pointer-events: none;
    left: 12px;
    top: 16px;
    transition: 0.2s ease all;
    line-height: 13px;
  }

  .as-placeholder {
    color: gray;
    font-size: ${FONT_SIZE > 1 ? '14px' : '11px'}!important;
  }

  .as-label {
    top:  calc(-8px * ${FONT_SIZE});
    font-size: ${FONT_SIZE > 1 ? '14px' : '11px'}!important;
    padding: 0 4px;
    margin-left: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
  }
  
  .ant-select {
    width: 100%;
    //border: none !important;
  }
   
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
  }
  
  .ant-select-selector {
    box-shadow: none !important;
    border: none !important;
  }
  
 .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
    //border: none !important;
 }
 

  ${({ filled }) => filled && css`
    .ant-select-selector {
      background: ${GREY_10} !important;
    }

    .as-label {
      
    }
  `}
`;
