/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Typography, Button,
} from 'antd';
import moment, { Moment } from 'moment';
import {
  Badge,
  BadgeWrapper,
  DetailItem,
  DetailItemIcon,
  DetailItemsWrapper,
  StepItemContent,
  StepItemWrapper,
  StepProgressLine,
  StepsWrapper,
  Wrapper,
} from './styles';
import { ReactComponent as PassengerIcon } from '../../assets/images/passenger-color.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-directions.svg';
import { ReactComponent as FlightIcon } from '../../assets/images/flight.svg';

import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { pluralizeText } from '../../utils/helpers';
import { useStore } from '../../store/global/appStore';
import { getAirportStateName } from './helper';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';
import globalTranslations from '../../utils/globalTranslation';

const { Title, Paragraph } = Typography;
type Props = {
  showChangeButton?: boolean,
  returning?: boolean
  itinerary?: boolean
  OriginLocationCode: string
  DestinationLocationCode: string
  DepartureDate: Moment
  ReturnDate?: Moment
}

function TripSummaryMoreDetails({
  showChangeButton, returning, itinerary, OriginLocationCode, DestinationLocationCode, DepartureDate, ReturnDate,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData,
  } = useFlightSearchStore();

  const { domesticAirports, internationalAirports } = useStore();

  const isRoundTrip = formData.tripType === 'round_trip';
  const airports = formData.flightType === 'domestic' ? domesticAirports : internationalAirports;

  const renderFlightTime = () => {
    if (itinerary) {
      return moment(returning ? ReturnDate : DepartureDate).format('ll');
    }

    if (isRoundTrip) {
      return `${moment(DepartureDate).format('ll')} - ${moment(ReturnDate).format('ll')}`;
    }

    return `${moment(DepartureDate).format('ll')}`;
  };

  return (
    <Wrapper>
      <Row gutter={40} align="middle">
        <Col lg={showChangeButton ? 12 : 24} xs={24}>
          <StepsWrapper>
            <StepProgressLine />
            <StepItemWrapper>
              <StepItemContent>
                <FlightIcon />
              </StepItemContent>
            </StepItemWrapper>

            <StepProgressLine />

          </StepsWrapper>
          <Row justify="space-between" align="middle">
            {
              returning ? (
                <>
                  <Col>
                    <Title level={2}>
                      {DestinationLocationCode}
                    </Title>
                    <Paragraph>
                      {getAirportStateName(airports, DestinationLocationCode)}
                    </Paragraph>
                  </Col>
                  <Col>
                    <BadgeWrapper>
                      <Badge>
                        <Paragraph style={{ textAlign: 'center' }}>
                          {formData.FlightClass}
                        </Paragraph>
                      </Badge>
                    </BadgeWrapper>
                  </Col>
                  <Col>
                    <Title level={2} style={{ textAlign: 'right' }}>
                      {OriginLocationCode}
                    </Title>
                    <Paragraph style={{ textAlign: 'right' }}>
                      {getAirportStateName(airports, OriginLocationCode)}
                    </Paragraph>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <Title level={2}>
                      {OriginLocationCode}
                    </Title>
                    <Paragraph>
                      {getAirportStateName(airports, OriginLocationCode)}
                    </Paragraph>
                  </Col>
                  <Col>
                    <BadgeWrapper>
                      <Badge>
                        <Paragraph style={{ textAlign: 'center' }}>
                          {formData.FlightClass}
                        </Paragraph>
                      </Badge>
                    </BadgeWrapper>
                  </Col>
                  <Col>
                    <Title level={2} style={{ textAlign: 'right' }}>
                      {DestinationLocationCode}
                    </Title>
                    <Paragraph style={{ textAlign: 'right' }}>
                      {getAirportStateName(airports, DestinationLocationCode)}
                    </Paragraph>
                  </Col>
                </>
              )
            }

          </Row>
        </Col>

        <Col lg={showChangeButton ? 8 : 24} xs={24}>
          <Row gutter={[8, 0]}>
            <Col lg={showChangeButton ? 24 : 12} xs={12}>
              <DetailItemsWrapper>
                <DetailItem>
                  <DetailItemIcon>
                    <ArrowIcon />
                  </DetailItemIcon>

                  <Paragraph strong>
                    {renderFlightTime()}
                  </Paragraph>
                </DetailItem>
              </DetailItemsWrapper>

            </Col>
            <Col lg={showChangeButton ? 24 : 12} xs={12}>
              <DetailItemsWrapper>
                <DetailItem>
                  <DetailItemIcon>
                    <PassengerIcon />
                  </DetailItemIcon>
                  <Paragraph style={{ textAlign: 'right' }} strong>
                    <span>
                      {pluralizeText(
                        formData.numberOfAdults,
                        t(globalTranslations).passengerSelector.adult,
                        t(globalTranslations).passengerSelector.adults,
                      )}
                      &nbsp;&nbsp;
                    </span>

                    {Boolean(formData.numberOfChildren) && (
                    <span>
                      {pluralizeText(
                        formData.numberOfChildren,
                        t(globalTranslations).passengerSelector.child,
                        t(globalTranslations).passengerSelector.children,
                      )}
                      &nbsp;&nbsp;
                    </span>
                    )}

                    {Boolean(formData.numberOfInfants) && (
                    <span>
                      {pluralizeText(
                        formData.numberOfInfants,
                        t(globalTranslations).passengerSelector.infant,
                        t(globalTranslations).passengerSelector.infants,
                      )}
                      &nbsp;&nbsp;
                    </span>
                    )}
                  </Paragraph>
                </DetailItem>
              </DetailItemsWrapper>
            </Col>
          </Row>
        </Col>

        <Col lg={showChangeButton ? 4 : 0} xs={0}>
          <div className="align-right">
            <Link to="/search/flight">
              <Button type="primary">
                {t(translations).changeButton}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

TripSummaryMoreDetails.defaultProps = {
  showChangeButton: true,
  returning: false,
  itinerary: false,
};

export default TripSummaryMoreDetails;
