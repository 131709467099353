import styled from 'styled-components';
import { GREY, GREY_5 } from '../../utils/colors';
import device from '../../utils/mediaQuery';

export const SelectWrapper = styled.div`
  .ant-select-selector {
    background: ${GREY_5} !important;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  .as-label {
   display: none;
  }

  @media ${device.large} {
    .as-label {
      display: block;
    }
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const SelectLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  background: #fff;
  padding-left: 20px;
  border-radius: 5px 0 0 5px;
  
  .ant-typography {
    //color: ${GREY};
  }
`;
