import rotateIcon from '../../assets/images/rotate.svg';
import rotate2Icon from '../../assets/images/rotate-2.svg';
import { getExtraData } from '../../utils/helpers';

export const flightTypes: FlightType[] = [
  {
    id: 1,
    name: 'International flight',
    value: 'international',
    icon: rotateIcon,
  },
  {
    id: 2,
    name: 'Domestic flight',
    value: 'domestic',
    icon: rotate2Icon,
  },
];

export const getTripModes = () => {
  const data = getExtraData();
  const transportModes = data && data?.TransportMode && data.TransportMode.filter((item) => item.Status).map((item) => ({
    id: item.TransportModeId,
    name: item?.TransportModeName === 'Air' ? 'flight' : item?.TransportModeName?.toLowerCase(),
  }));
  return transportModes || null;
};

export const getDometicAirporUrl = () => {
  const data = getExtraData();
  const countryCode = data.primaryCountryCode || 'NG';
  const url = `airport?countryCode=${countryCode}`;
  return url;
};

// {
//   id: '1',
//   name: 'flight',
// },
// {
//   id: '2',
//   name: 'road',
// },
// {
//   id: '3',
//   name: 'water',
// },
// {
//   id: '4',
//   name: 'rail',
// },
