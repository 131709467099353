import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography, Row, Col, Space, Button, Empty, Card,
} from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import {
  ColumnItem,
  HeaderAirlineDetails,
  TextWrapper,
  Wrapper,
  RowWrapper,
  FlightStepsWrapper,
  FlightItemWrapper,
  FlightProgressLine, FlightStepPointer, FlightStepHighlight, DetailEntryWrapper, DetailTitleWrapper, FlightDetailsWrapper, FlightCountText,
} from './styles';
import {
  StyledParagraph,
} from '../CustomAntdComponents/Typography';

import clockIcon from '../../assets/images/clock.svg';
import {
  CentralizedContent, GiveMeSpace,
  VerticalAlignContent,
} from '../CustomAntdComponents/Utitlities';
import { getAirlineLogo } from './helper';
import SkeletonLayout from './SkeletonLayout';
import { formatNumberAsCurrency, pluralizeText } from '../../utils/helpers';
import { useI18nContext } from '../../utils/I18n';
import translations from '../../screens/SearchListings/translations';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import TripSummaryMoreDetails from '../TripSummary/TripSummaryMoreDetails';
import RenderFlightTripDetailsDomestic from './RenderFlightTripDetailsDomestic';

const { Title } = Typography;

type Props = {
  results?: FlightAggregateResultItem,
  error?: string | null,
  loading?: boolean,
  isRoundTrip?: boolean,
  onBookingRedirect: (arg: AirlineResultItem[]) => void
}
function AvailableFlights({
  loading, results, error, onBookingRedirect, isRoundTrip,
}: Props) {
  const {
    state: { t },
  } = useI18nContext();
  const {
    formData,
  } = useFlightSearchStore();
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedFlights, setSelectedFlights] = useState<AirlineResultItem[]>([]);
  const resultLists = useMemo<FlightResultItem[]>(() => (results ? results.ResultList : []), [results]);
  const departureFlights: AirlineResultItem[] = resultLists[0]?.ResultList || [];
  const [returnFlights, setReturnFlights] = useState<AirlineResultItem[]>(resultLists[1]?.ResultList || []);

  const handleSameAirline = (result: AirlineResultItem) => {
    const airlineCode = result.FlightDetails[0]?.OperatingAirlineCode;

    const returnFlightResults = resultLists[1]?.ResultList || [];
    const filteredResults = returnFlightResults.filter((resultItem: AirlineResultItem) => (
      Boolean(resultItem.FlightDetails[0].OperatingAirlineCode === airlineCode)));

    setReturnFlights(filteredResults);
  };
  const handleSelectFlight = (result: AirlineResultItem) => {
    if (isRoundTrip) {
      if (selectedFlights.length === 0) {
        setSelectedFlights([result]);
        if (formData.sameAirline) {
          handleSameAirline(result);
        }
      } else {
        const selections = [...selectedFlights, result];
        setSelectedFlights(selections);
        onBookingRedirect(selections);
      }
    } else {
      onBookingRedirect([result]);
    }
  };
  const renderEntryDetails = (flightDetails: FlightDetails, index) => (
    <Col lg={12} key={`${flightDetails.ArrivalAirportCode}${index}`} style={{ marginBottom: '10px' }}>
      <Card size="small">
        <TripSummaryMoreDetails
          showChangeButton={false}
          itinerary
          DestinationLocationCode={flightDetails.ArrivalAirportCode}
          OriginLocationCode={flightDetails.DepartureAirportCode}
          DepartureDate={flightDetails.DepartureDate}
          ReturnDate={flightDetails.ArrivalDate}
          flightDetails={flightDetails}
        />

      </Card>
    </Col>
  );

  const renderFlightDetails = (result: AirlineResultItem, isReturning: boolean) => {
    const flight = result.FlightDetails[0];
    return (
      <RowWrapper key={result.Properties.RecommendationID}>
        <Row gutter={4}>
          <Col xs={24} lg={8} style={{ display: 'flex' }}>
            <ColumnItem index={1}>
              <Row align="middle">
                <Col xs={12} lg={24}>
                  <HeaderAirlineDetails>
                    <img
                      src={getAirlineLogo(flight.OperatingAirlineCode)}
                      alt={flight.OperatingAirlineCode}
                    />
                    <StyledParagraph size="small">
                      {flight.OperatingAirlineName}
                    </StyledParagraph>
                  </HeaderAirlineDetails>
                </Col>
              </Row>
            </ColumnItem>
          </Col>
          <Col xs={24} lg={8} style={{ display: 'flex' }}>
            <ColumnItem index={2}>
              <Row align="middle">
                <Col span={12}>
                  <TextWrapper>
                    <StyledParagraph type="secondary">
                      {t(translations).departure}
                    </StyledParagraph>
                  </TextWrapper>
                </Col>
                <Col span={12}>
                  <TextWrapper>
                    <StyledParagraph align="right" size="small">
                      {moment(flight.DepartureDate)
                        .format('hh:mm a')}
                    </StyledParagraph>
                  </TextWrapper>
                </Col>
                <Col span={12}>
                  <TextWrapper>
                    <StyledParagraph type="secondary">
                      {t(translations).arrival}
                    </StyledParagraph>
                  </TextWrapper>
                </Col>
                <Col span={12}>
                  <TextWrapper>
                    <StyledParagraph align="right" size="small">
                      {moment(flight.ArrivalDate)
                        .format('hh:mm a')}
                    </StyledParagraph>
                  </TextWrapper>
                </Col>

                <Col lg={12} xs={0}>
                  <TextWrapper>
                    <StyledParagraph type="secondary">
                      {pluralizeText(flight.StopOvers, 'Stop', 'Stops')}
                    </StyledParagraph>
                  </TextWrapper>
                </Col>
                <Col lg={12} xs={0}>
                  <TextWrapper>
                    <StyledParagraph size="small" align="right">
                      <CentralizedContent
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <img src={clockIcon} alt="" />
                      &nbsp;
                        {flight.Duration}
                      </CentralizedContent>
                    </StyledParagraph>
                  </TextWrapper>
                </Col>
              </Row>
            </ColumnItem>
          </Col>
          <Col xs={24} lg={8} style={{ display: 'flex' }}>
            <ColumnItem index={3}>
              <Row>
                {/* >= LG devices */}
                <Col xs={0} lg={24}>
                  <Space direction="vertical" size="small">
                    <StyledParagraph strong align="center">
                      {formatNumberAsCurrency((result.TotalFare - result.TotalServiceCharge), result.Currency)}
                    </StyledParagraph>
                    <CentralizedContent>
                      {
                      !viewDetails
                        ? (
                          <Button type="primary" onClick={() => setViewDetails(true)}>
                            {t(translations).view}
                          </Button>
                        )
                        : (
                          <Button type="primary" onClick={() => handleSelectFlight(result)}>
                            {t(translations).bookFlight}
                          </Button>
                        )
                    }
                      {/* <Button
                        type="primary"
                        onClick={() => handleSelectFlight(result)}
                      >
                        { t(translations).bookFlight}
                      </Button> */}
                    </CentralizedContent>
                  </Space>
                </Col>

                <Col xs={12} lg={0}>
                  <TextWrapper>
                    <StyledParagraph strong size="small">
                      <VerticalAlignContent>
                        <img src={clockIcon} alt="" />
                      &nbsp;
                        {flight.Duration}
                      </VerticalAlignContent>
                    </StyledParagraph>
                  </TextWrapper>
                </Col>

                <Col xs={12} lg={0}>
                  <TextWrapper style={{ marginBottom: '20px' }}>
                    <StyledParagraph strong align="right">
                      {formatNumberAsCurrency((result.TotalFare - result.TotalServiceCharge), result.Currency)}
                    </StyledParagraph>
                  </TextWrapper>
                </Col>

                <Col xs={24} lg={0}>
                  <Button
                    type="primary"
                    onClick={() => handleSelectFlight(result)}
                    block
                  >
                    {(!isRoundTrip || (isRoundTrip && selectedFlights.length > 0)) ? t(translations).view : t(translations).bookFlight}
                  </Button>

                  <GiveMeSpace />
                </Col>

              </Row>
            </ColumnItem>
          </Col>
        </Row>

        { viewDetails && (
        <FlightDetailsWrapper>
          <DetailTitleWrapper>
            <Row justify="space-between">
              <Col>
                <StyledParagraph>
                  {isReturning ? t(translations).return : t(translations).departure}
                </StyledParagraph>

              </Col>

              <Col>
                <CloseOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setViewDetails(false);
                  }}
                />
              </Col>
            </Row>
          </DetailTitleWrapper>

          <DetailEntryWrapper>
            <Row justify="space-between" gutter={8}>
              {
                renderEntryDetails(flight, 0)
              }
            </Row>
          </DetailEntryWrapper>
        </FlightDetailsWrapper>
        )}
      </RowWrapper>
    );
  };
  const flightCount = (flights) => {
    if (flights.length > 0) {
      if (flights.length === 1) {
        return `${flights?.length} Flight`;
      }
      return `${flights?.length} Flights`;
    }
    return null;
  };

  useEffect(() => {
    setReturnFlights(resultLists[1]?.ResultList || []);
  }, [resultLists]);

  return (
    <Wrapper>
      <Title level={5}>
        {t(translations).listingsTitle}
        <FlightCountText>
          {selectedFlights?.length === 0 && departureFlights?.length > 0 && flightCount(departureFlights) }
          {selectedFlights?.length > 0 && returnFlights?.length > 0 && flightCount(returnFlights) }
        </FlightCountText>
      </Title>

      {!loading && error && <Empty description={error} /> }
      {!loading && !error && results && results?.TotalCount === 0 && <Empty description="No flight matches your search" /> }

      {loading ? (
        <>
          <SkeletonLayout />
          <SkeletonLayout />
        </>
      )
        : (
          <>
            {
              results?.TotalCount !== 0 && isRoundTrip && !error && (
              <Card style={{ margin: '15px 0' }}>
                <FlightStepsWrapper>
                  <FlightItemWrapper>
                    <FlightStepPointer>
                      <FlightStepHighlight active />
                    </FlightStepPointer>
                    {t(translations).departure}
                  </FlightItemWrapper>

                  <FlightProgressLine />

                  <FlightItemWrapper>
                    <FlightStepPointer>
                      <FlightStepHighlight active={selectedFlights.length > 0} />
                    </FlightStepPointer>
                    {t(translations).return}
                  </FlightItemWrapper>
                </FlightStepsWrapper>
              </Card>
              )
            }

            {selectedFlights.length === 0 && departureFlights.map((result) => (
              // renderFlightDetails(result, false)
              <RenderFlightTripDetailsDomestic
                result={result}
                isReturning={false}
                handleSelectFlight={handleSelectFlight}
                selectedFlights={selectedFlights}
              />
            ))}

            {selectedFlights.length > 0 && returnFlights.map((result) => (
              // renderFlightDetails(result, true)
              <RenderFlightTripDetailsDomestic
                result={result}
                isReturning
                handleSelectFlight={handleSelectFlight}
                selectedFlights={selectedFlights}
              />
            ))}
          </>
        )}

    </Wrapper>

  );
}

AvailableFlights.defaultProps = {
  loading: false,
  error: undefined,
  results: undefined,
  isRoundTrip: false,
};
export default AvailableFlights;
