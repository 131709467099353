import styled, { css } from 'styled-components';
import {
  Divider, Card,
} from 'antd';
import device from '../../utils/mediaQuery';
import {
  FONT_SIZE,
  GREY_15, PRIMARY,
} from '../../utils/colors';

export const Wrapper = styled.div`
  margin-top: 15px;

  .ant-space {
    display: flex;
    margin-top: 15px;
  }
  
  .ant-typography {
    margin-bottom: 0;
  }

  @media ${device.large} {
    margin-top: 0;
  }
`;

export const HeaderWrapper = styled.div`
 margin-bottom: 10px;
`;

export const FlightCountText = styled.span`
  color: ${PRIMARY};
  margin-left:8px;
  font-weight: 400 !important;
  font-size: calc(12px *  ${FONT_SIZE});
`;
export const HeaderAirlineDetails = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 10px;

  img {
    margin-right: 5px;
  }

  @media ${device.large} {
    display: block;
    text-align: center;
    justify-content: center;
    
    .ant-typography {
      text-align: center;
      margin: 10px 0 !important;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const StyledCard = styled(Card)`
  @media ${device.large} {
    padding: 20px;
  }
`;

export const ColumnItem = styled.div<{ index?: number}>`
  background: #fff;
  flex: 1;
  padding: 0 20px;

  @media ${device.extraSmall} {
    background: none;
  }
  
  @media ${device.large} {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${({ index }) => index === 1 && css`
      border-radius: 5px 0 0 5px;
    `}
    
    ${({ index }) => index === 2 && css`
      border-radius: 0;
    `}
    
    ${({ index }) => index === 3 && css`
      border-radius: 0 5px 5px 0;
    `}
  }
`;
export const TripCountWrapper = styled.p`
    border-bottom: 1px solid  #eeeeee;
    padding: 0px;
    margin:0px;
`;

export const TextWrapper = styled.div`
  margin-bottom: 10px;
`;

export const SeeMoreWrapper = styled.div`
  margin: 10px 0 15px;
`;

export const RowWrapper = styled.section`
  background: #EEEDF9;
  margin-top: 15px;
  border-radius: 5px;

  @media ${device.extraSmall} {
    background: #fff;
  }

  @media ${device.large} {
    background: #EEEDF9;
  }
`;

export const AirlineDataWrapper = styled.section`
    margin: 15px 0 5px;
`;

export const FlightProgressLine = styled.div`
  border-bottom: 1px solid ${PRIMARY};
  flex: 1;
  margin: 0 10px;
`;

export const FlightItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const FlightStepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
    font-weight: bold;
    color: ${PRIMARY};
    
    width: 100%;
    margin: 0 auto;

  @media ${device.large} {
    width: 60%;
  }
`;

export const FlightStepPointer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${PRIMARY}; 
    margin: 0 10px;
    position: relative;

  @media ${device.large} {
    width: 40px;
    height: 40px;
  }
}
`;

export const FlightStepHighlight = styled.div<{ active?: boolean }>`
    width: 12px;
    height: 12px;
    flex: 1;
    border-radius: 50%;
    background: ${PRIMARY};
    transform: translate(27%, 23%);
    opacity: 0;
    transition: all 0.5s ease-out;
    
    ${({ active }) => active && css` opacity: 1;`}

    @media ${device.large} {
      transform: translate(14%, 14%);
      width: 30px;
      height: 30px;
    }
`;

export const FlightDetailsWrapper = styled.div`
  background: ${GREY_15};
  padding: 10px 0;
`;

export const DetailTitleWrapper = styled.div`
    padding: 10px 20px;
    border-bottom: solid 2px #fff;
    margin-bottom: 15px;
`;

export const DetailEntryWrapper = styled.div`
    padding: 10px 20px;
    margin-bottom: 15px;
`;
