import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Col, Row,
} from 'antd';
import HeaderNavigation from '../../components/HeaderNavigation';
import { ActionContentWrapper, ActionWrapper, Wrapper } from './styles';
import FareRule from '../../components/FareRule';
import Header from '../../components/Header';
import FlightItinerary from '../../components/FlightItinerary';

function Itinerary() {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <Row>
        <Col xs={0} lg={24}>
          <Header />
        </Col>
      </Row>

      <div className="container">
        <Wrapper>
          <Row>
            <Col xs={24} lg={0}>
              <HeaderNavigation name="Flight Itinerary" />
            </Col>

            <Col xs={0} lg={24}>
              <HeaderNavigation />
            </Col>
          </Row>

          <FlightItinerary showSubmitButton={false} />
        </Wrapper>

        <ActionWrapper>
          <div className="container">
            <ActionContentWrapper>
              <FareRule onAccept={(value) => setIsChecked(value)} isChecked={isChecked} />
              <Link to="/passenger-details/flight">
                <Button block size="large" type="primary" disabled={!isChecked}>
                  Next
                </Button>
              </Link>
            </ActionContentWrapper>
          </div>
        </ActionWrapper>
      </div>
    </>

  );
}

export default Itinerary;
