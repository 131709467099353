import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 5px;
  
  .ant-select {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: right;
  
  .ant-btn {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
