import React from 'react';
import {
  Button, Form, Row, Col, Checkbox,
} from 'antd';
import {
  FormWrapper,
} from './styles';
import PassengerCountSelector from '../PassegerSelector';
import FlightModes from './FlightModes';
import SeatType from './SeatType';
import StyledSelect from '../CustomAntdComponents/StyledSelect';
import StyledDatePicker from '../CustomAntdComponents/StyledDatePicker';
import translations from '../../screens/Search/translations';
import { useI18nContext } from '../../utils/I18n';
import { getDisabledDateRange } from '../../utils/helpers';
import { MulticityFlightSearchFormMobile } from './MultiCity';

function FlightSearchMobileView(props) {
  const {
    onFinish,
    onFormInputChange,
    isMultiCity,
    isDomestic,
    isRoundTrip,
    airportOptions,
    checkReturningDate,
    disabledReturnDate,
    validateDestination,
    onDateInputChange,
    formData,
    MultiCityMaxRoute,
  } = props;

  const {
    state: { t },
  } = useI18nContext();
  const {
    FlightClass,
    DepartureDate,
    ReturnDate,
    tripType,
    flightType,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    sameAirline,
  } = formData;

  const [form] = Form.useForm();

  const formInitialValues = {
    FlightClass,
    DepartureDate,
    ReturnDate,
    tripType,
    flightType,
    numberOfAdults,
    DestinationLocationCode,
    OriginLocationCode,
    numberOfInfants,
    numberOfChildren,
    sameAirline,
  };

  return (
    <div>
      <Form
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        initialValues={formInitialValues}
        form={form}
        onFinish={onFinish}
        size="large"
      >
        <FormWrapper>
          {/* will display only smaller screen */}
          <Row>
            <Col xs={24} lg={0}>
              <FlightModes flightMode={tripType} flightType={flightType} onTripTypeChange={(value) => onFormInputChange(value, 'tripType')} />
              <SeatType
                itemFilled
                flightClassOption={FlightClass}
                setFlightClassOption={(value) => onFormInputChange(value, 'FlightClass')}
              />
            </Col>
          </Row>

          {isMultiCity ? (
            <MulticityFlightSearchFormMobile
              airportOptions={airportOptions}
              checkReturningDate={checkReturningDate}
              disabledReturnDate={disabledReturnDate}
              isRoundTrip={isRoundTrip}
              ReturnDate={ReturnDate}
              DepartureDate={DepartureDate}
              onFormInputChange={onFormInputChange}
              validateDestination={validateDestination}
              sameAirline={sameAirline}
              onDateInputChange={onDateInputChange}
              MultiCityMaxRoute={MultiCityMaxRoute}
            />
          ) : (
            <Row gutter={[0, 16]}>
              <Col xs={24} lg={0}>
                <Row gutter={8}>
                  <Col xs={24} lg={0}>
                    <Form.Item
                      name="OriginLocationCode"
                      rules={[{ required: true, message: t(translations).validations.departure }]}
                    >
                      <StyledSelect
                        placeholder={t(translations).inputs.from}
                        showSearch
                        options={airportOptions}
                        onChange={(value) => onFormInputChange(value, 'OriginLocationCode')}
                      />
                    </Form.Item>

                    <Form.Item
                      name="DestinationLocationCode"
                      rules={[
                        {
                          validator: validateDestination,
                        },
                      ]}
                    >
                      <StyledSelect
                        placeholder={t(translations).inputs.to}
                        showSearch
                        options={airportOptions}
                        onChange={(value) => onFormInputChange(value, 'DestinationLocationCode')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={isRoundTrip ? 12 : 24}>
                    <Form.Item
                      name="DepartureDate"
                      rules={[{ required: true, message: t(translations).validations.departureDate }]}
                    >
                      <StyledDatePicker
                        placeholder={t(translations).inputs.departureDate}
                        value={DepartureDate}
                        disabledDate={getDisabledDateRange}
                        onChange={(value) => onFormInputChange(value, 'DepartureDate')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={isRoundTrip ? 12 : 0}>
                    <Form.Item
                      name="ReturnDate"
                      rules={[
                        {
                          validator: checkReturningDate,
                        },
                      ]}
                    >
                      <StyledDatePicker
                        placeholder={t(translations).inputs.arrivalDate}
                        value={ReturnDate}
                        disabledDate={disabledReturnDate}
                        onChange={(value) => onFormInputChange(value, 'ReturnDate')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} lg={0}>
                <PassengerCountSelector />
                {
                (isRoundTrip && isDomestic) && (
                <Checkbox
                  key="airline"
                  checked={Boolean(sameAirline)}
                  onChange={(e) => onFormInputChange(e.target.checked, 'sameAirline')}
                >
                  {t(translations).inputs.sameAirlineText}
                </Checkbox>
                )
              }
              </Col>

              <Col xs={24} lg={0}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block>
                    {t(translations).searchButton}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}

        </FormWrapper>

      </Form>
    </div>
  );
}

export default FlightSearchMobileView;
