import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Typography, Row, Col, Card, Avatar, Modal, Button,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';

import rotateIcon from '../../assets/images/rotate.svg';
import rotate2Icon from '../../assets/images/rotate-2.svg';
import {
  ButtonWrapper, Content, ContentHeader, IconWrapper, TripModesWrapper, Wrapper,
} from './styles';
import Header from '../../components/Header';
import arrowRightIcon from '../../assets/images/arrow-right.svg';
import StyledDrawer from '../../components/CustomAntdComponents/StyledDrawer';
import { AvatarWrapper, UserDetails } from '../../components/Header/styles';
import sunlightIcon from '../../assets/images/sunlight.svg';
import {
  useFlightSearchStore,
} from '../../store/flightSearch/flightSearchStore';
import { getExtraData, getUser, isMobileScreen } from '../../utils/helpers';
import { getAPI } from '../../utils/api';
import {
  GET_INTERNATIONAL_AIRPORTS_API_URL,
} from '../../utils/constants';
import { useStore } from '../../store/global/appStore';
import { useRoadSearchStore } from '../../store/roadSearch/roadSearchStore';
import { ReactComponent as FlightTripIcon } from '../../assets/images/flight-trip.svg';
import { ReactComponent as RailTripIcon } from '../../assets/images/rail-trip.svg';
import { ReactComponent as RoadTripIcon } from '../../assets/images/bus-trip.svg';
import { ReactComponent as WaterTripIcon } from '../../assets/images/water-trip.svg';
import { useWaterSearchStore } from '../../store/waterSearch/waterSearchStore';
import { useRailSearchStore } from '../../store/railSearch/railSearchStore';
import { useI18nContext } from '../../utils/I18n';
import translations from './translate';
import globalTranslations from '../../utils/globalTranslation';
import { getDometicAirporUrl } from './constants';
import { SpaceBetweenAlignContent } from '../../components/CustomAntdComponents/Utitlities';
import HeaderNavigation from '../../components/HeaderNavigation';
import { NavIconWrapper } from '../../components/HeaderNavigation/styles';

const { Title, Text } = Typography;

function Home(): React.ReactElement {
  const {
    state: { t },
  } = useI18nContext();
  const { updateFormData, resetFormData } = useFlightSearchStore();
  const { resetFormData: resetRoadFormData } = useRoadSearchStore();
  const { resetFormData: resetWaterFormData } = useWaterSearchStore();
  const { resetFormData: resetRailFormData } = useRailSearchStore();
  const navigate = useNavigate();
  const { domesticAirports, internationalAirports, setAirports } = useStore();
  const user = getUser();
  const extraData = getExtraData();

  const [visibleTripMode, setVisibleTripsMode] = useState({
    isAirVisible: false,
    isRoadVisible: false,
    isRailVisible: false,
    isWaterVisible: false,
  });

  const getVisibleTransportModes = useCallback(() => {
    const isAirVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Air')?.Status;
    const isRoadVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Road')?.Status;
    const isRailVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Rail')?.Status;
    const isWaterVisible = extraData && extraData?.TransportMode?.find((item: TransportMode) => item.TransportModeName === 'Water')?.Status;
    setVisibleTripsMode({
      ...visibleTripMode,
      isAirVisible,
      isRoadVisible,
      isRailVisible,
      isWaterVisible,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisibleTripsMode]);

  const getInternationalAirports = useCallback(async () => {
    const airportsApi = await getAPI(GET_INTERNATIONAL_AIRPORTS_API_URL);
    setAirports(airportsApi.data, 'internationalAirports');
  }, [setAirports]);

  const getDomesticAirports = useCallback(async () => {
    const airportsApi = await getAPI(getDometicAirporUrl());
    setAirports(airportsApi.data, 'domesticAirports');
  }, [setAirports]);

  useEffect(() => {
    if ((domesticAirports.length === 0)) {
      getDomesticAirports();
    }
  }, [domesticAirports, getDomesticAirports]);

  useEffect(() => {
    if ((internationalAirports.length === 0)) {
      getInternationalAirports();
    }
  }, [internationalAirports, getInternationalAirports]);

  const [activeTripMode, setActiveTripMode] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const isMobile = isMobileScreen();

  const modalVisibilityControl = () => {
    if (isMobile) {
      setVisible(true);
    } else {
      setModalVisible(true);
    }
  };
  const handleNavigate = () => {
    navigate('/');
  };
  const handleOpenTrip = (trip: string) => {
    if (trip === 'flight') {
      setActiveTripMode(trip);
      modalVisibilityControl();
    } else {
      setActiveTripMode(trip);
      navigate(`/search/${trip}`);
      // modalVisibilityControl();
    }
  };

  const handleFlightType = (flightType: string) => {
    updateFormData({ flightType });
    navigate(`/search/${activeTripMode}`);
  };

  useEffect(() => {
    resetFormData();
    resetRoadFormData();
    resetRailFormData();
    resetWaterFormData();
  }, [resetFormData, resetRailFormData, resetRoadFormData, resetWaterFormData]);
  useEffect(() => {
    getVisibleTransportModes();
  }, [getVisibleTransportModes]);

  return (
    <Wrapper>
      <Header />

      <Content>
        <div className="container">
          <ContentHeader>
            <Row>
              <Col xs={0} lg={24}>
                <UserDetails>
                  <AvatarWrapper>
                    <Avatar size={26} icon={<UserOutlined />} />
                  </AvatarWrapper>

                  <Title level={4}>{`${t(translations).salutation} ${user?.CustomerName || ''},`}</Title>
                  <img src={sunlightIcon} alt="day-icon" />
                </UserDetails>
              </Col>
            </Row>

            {/* <Title level={3}>{t(translations).title}</Title> */}
            <Row justify="space-between" align="middle">

              <Col span={24} lg={0}>
                <NavIconWrapper onClick={handleNavigate}>
                  <ArrowLeftIcon />
                </NavIconWrapper>
              </Col>
              <Col span={24} lg={0}>
                <Title level={3}> Book your flight  </Title>

              </Col>

            </Row>
            {/* <SpaceBetweenAlignContent>

              <HeaderNavigation />

            </SpaceBetweenAlignContent> */}

          </ContentHeader>

          <TripModesWrapper>
            <Row
              className="justify-center"
              gutter={{
                xs: 24, sm: 24, md: 24, lg: 40,
              }}
            >
              {visibleTripMode.isAirVisible && (
              <Col xs={24} lg={6}>
                <Card onClick={() => handleOpenTrip('flight')} style={{ marginBottom: '24px' }}>
                  <Title level={5}>{t(translations).tripFlight}</Title>
                  <FlightTripIcon />

                  <Button block size="large" type="primary">
                    Book a Flight
                  </Button>

                </Card>
              </Col>
              )}

              {visibleTripMode.isRoadVisible && (
              <Col xs={24} lg={6}>
                <Card onClick={() => handleOpenTrip('road')} style={{ marginBottom: '24px' }}>
                  <Title level={5}>{t(translations).tripRoad}</Title>
                  <RoadTripIcon />
                  <Button block size="large" type="primary">
                    Book a Trip
                  </Button>
                </Card>
              </Col>
              )}

              {visibleTripMode.isWaterVisible && (
              <Col xs={12} lg={6}>
                <Card onClick={() => handleOpenTrip('water')} style={{ marginBottom: '24px' }}>
                  <Title level={5}>{t(translations).tripWater}</Title>
                  <WaterTripIcon />
                </Card>
              </Col>
              )}
              {visibleTripMode.isRailVisible
              && (
              <Col xs={12} lg={6}>
                <Card onClick={() => handleOpenTrip('rail')} style={{ marginBottom: '24px' }}>
                  <Title level={5}>{t(translations).tripRail}</Title>
                  <RailTripIcon />
                </Card>
              </Col>
              ) }
            </Row>
          </TripModesWrapper>

          <Row>
            <Col xs={0} lg={0}>
              <Link to="/my-booking">
                <ButtonWrapper justify>
                  <Text>
                    Manage Booking
                  </Text>
                  <IconWrapper>
                    <img src={arrowRightIcon} alt="right" />
                  </IconWrapper>
                </ButtonWrapper>
              </Link>

            </Col>
          </Row>
        </div>

        <StyledDrawer visibility={visible} onClose={() => setVisible(false)}>

          {
            activeTripMode ? (
              <>
                <Title level={4}>{t(translations).tripType}</Title>
                <ButtonWrapper onClick={() => handleFlightType('international')}>
                  <IconWrapper>
                    <img src={rotateIcon} alt="flight" />
                  </IconWrapper>
                  <Text>
                    {t(translations).internationalTrip}
                  </Text>
                </ButtonWrapper>
                <ButtonWrapper onClick={() => handleFlightType('domestic')}>
                  <IconWrapper>
                    <img src={rotate2Icon} alt="flight" />
                  </IconWrapper>
                  <Text>
                    {t(translations).domesticTrip}
                  </Text>
                </ButtonWrapper>
              </>
            ) : <Title level={4}>{`${t(globalTranslations).readiness}...`}</Title>
          }
        </StyledDrawer>

        <Modal
          centered
          closable={false}
          visible={modalVisible}
          footer={null}
          onCancel={() => setModalVisible(false)}
          width={400}
          getContainer={document.getElementById('app-container') as HTMLElement}
        >
          {
            activeTripMode ? (
              <>
                <Title level={4}>{t(translations).tripType}</Title>
                <ButtonWrapper onClick={() => handleFlightType('international')}>
                  <IconWrapper>
                    <img src={rotateIcon} alt="flight" />
                  </IconWrapper>
                  <Text>
                    {t(translations).internationalTrip}
                  </Text>
                </ButtonWrapper>
                <ButtonWrapper onClick={() => handleFlightType('domestic')}>
                  <IconWrapper>
                    <img src={rotate2Icon} alt="flight" />
                  </IconWrapper>
                  <Text>
                    {t(translations).domesticTrip}
                  </Text>
                </ButtonWrapper>
              </>
            ) : <Title level={4}>{`${t(globalTranslations).readiness}...`}</Title>
          }
        </Modal>

      </Content>

    </Wrapper>
  );
}

export default Home;
